@import "../../../../shared/assets/scss/variables.scss";

.navigator-form-container {
  .form-row {
    display: flex;
    justify-content: space-between;
    .form-col {
      width: 33%;
      .status-switch {
        margin-top: 2rem;
        margin-left: -1.2rem;
      }
      .status-switch-label {
        color: #333;
        display: block;
        font-family: Mon-medium;
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin: 0.6rem 0;
        text-transform: capitalize;
      }
    }
    .phone-col .field-wrap {
      flex-direction: row;
    }
    .field-wrap,
    .navigator-dropdown-container {
      width: 42rem;
    }

    .field-wrap {
      height: 100%;
      flex-direction: column;
      align-items: start;
      background-color: #fafafa;
      width: 70%;
    }
    .navigator-dropdown-container {
      width: 70%;
    }
    .type-input {
      margin-bottom: 2.9rem;
      .option-box-wrapper {
        width: 100%;
        overflow-y: scroll;
      }
    }
    .account-type-disabled input {
      opacity: 0.5;
      background-color: #f0f0f0;
    }

    .field-temporary-disabled {
      opacity: 0.4;
      cursor: initial;
    }

    .phone-container {
      width: 100% !important;

      .error-wrap {
        color: $red-notification-color;
        font-size: 1.2rem;
        font-family: "Mon-medium";
        margin-top: 0.65rem;
        display: flex;
        align-items: center;

        .error-message-icon {
          margin-right: 0.65rem;
        }
      }

      .phone-container-content {
        margin-bottom: 0;

        .field-wrap {
          display: flex;
          background: none;
        }
      }
    }

    .created-disabled,
    .updated-disabled {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      opacity: 0.8;
      background-color: #f0f0f0;
    }
  }
  .form-row-nav {
    display: flex;
    justify-content: left;
    .is-manager-switch {
      width: 16%;
    }
  }
}

.type-input .error-wrap {
  color: $red-notification-color;
  font-size: 1.2rem;
  font-family: "Mon-medium";
  margin-top: 0.65rem;
  display: flex;
  align-items: center;

  .error-message-icon {
    margin-right: 0.65rem;
  }
}
