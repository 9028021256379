.radio-input-group-section {
  display: flex;
  flex-direction: column;
  .radio-group-label {
    font-family: "Mon-medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    .red-color-asterisk {
      color: red;
    }
  }
  .input-section {
    display: flex;
    flex-direction: row;
    margin-top: 2px;
    .single-radio {
      margin-right: 29px;
      .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        margin-bottom: 12px;
        cursor: pointer;
        color: #7e7e7e;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        input {
          cursor: pointer;
          appearance: none;
          height: 18px;
          width: 18px;
          border: 2px solid #656565;
          border-radius: 50%;
          margin: 0px 10px 0px 0px;
          &:checked {
            background-image: url("../../../../../shared/assets/images/radio-input-checked.svg");
            background-repeat: no-repeat;
            background-position: center center;
          }&:disabled{
            opacity: 0.4
          }
        }
        .disable-radio-button{
          cursor: not-allowed;
        }
      }
      .disable-radio-button{
        cursor: not-allowed;
      }
    }

  }
  .error-wrap {
    color: #ff5757;
    font-family: "Mon-medium";
    font-size: 12px;
    margin: 0px 0px;
  }
}
