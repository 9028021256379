.table-loader{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: .3rem 0;
	.loading-text{
		margin-left: 1.4rem;
		font-family: 'Mon-medium', sans-serif;
		font-size: 1.4rem;
		color: #868686;
	}
}
