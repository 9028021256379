.infinite-scroll-component__outerdiv {
  height: 100% !important;
  overflow: auto;

  .infinite-scroll-component  {
    height: 100% !important;
    overflow-x: hidden !important;
  }

  .end-message {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    font-family: "Mon-medium", sans-serif;
  }
}