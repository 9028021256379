@import "shared/assets/scss/variables.scss";

#episode-details-container {
  height: 100%;
  .loading-container {
    width: 100%;
    height: 100%;
  }

  .episode-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0;
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .right-section {
      display: flex;
      align-items: end;
      justify-content: end;
      flex-direction: column;
      row-gap: 0.8rem;
    }
    .error-wrap {
      color: #ff5757;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-family: "Mon-medium";
      .error-message-icon {
        margin-right: 0.65rem;
      }
      .error-message-text {
        span {
          text-decoration: underline;
          font-weight: bold;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .all-filters-section {
    &.all-filters-section-with-patient-declined-alert {
      margin-top: 0 !important;
    }
    margin-top: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.4rem;
    .navigatorSection > .navigatorTabs > .tabs {
      padding: 0.8rem 2rem;
      cursor: not-allowed;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 13.9rem;
      height: 4rem;
      box-sizing: border-box;

      &:nth-child(1),
      &:nth-child(4) {
        cursor: pointer;
      }
      &.active {
        cursor: auto;
      }
    }
    .right-section {
      display: flex;
      align-items: center;
      .filter-icon-container {
        position: relative;
        margin-right: 0.2rem;
        .filtered {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #cb2020;
          position: absolute;
          right: -0.25rem;
          top: -0.25rem;
        }
        .filter-container {
          width: 4rem;
          height: 4rem;
          background: #ffffff;
          border: 0.1rem solid rgba(51, 51, 51, 0.14);
          border-radius: 0.8rem;
          order: 0;
          flex-grow: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }

      .add-task-button,
      .add-service-button {
        height: 4rem;
        width: 14.6rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-left: 1.6rem;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        &.enabled {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }
  .episode-patient-details {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    min-height: 16rem;
    padding: 2rem 0;
    border-radius: 0.8rem;
    border: 1px solid #bcdfef;
    background-color: #f7fcfb;
    &-left {
      height: 100%;
      width: 20rem;
      border-right: 1px solid #bcdfef;
      padding: 0 0.5rem;
      .phone-numbers-container.down {
        left: 0px;
      }
      &-name-container {
        display: flex;
        gap: 0.4rem;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      &-name {
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: center;
      }
      .episode-patient-details-avatar {
        margin: auto;
        position: relative;
        width: fit-content;
        height: fit-content;
        & > .avatar-container {
          border: 1px solid #bcdfef;
          background-color: #b8e1d833;
          width: 6.4rem;
          height: 6.4rem;
          &:first-child {
            font-family: "Mon-bold", sans-serif;
            font-size: 2.4rem;
            color: #667a78;
          }
        }
        .call-icon {
          left: 0rem;
          position: absolute;
          bottom: -1.2rem;
          & > .episode-patient-detail-icon {
            position: relative;
            bottom: unset;
            top: unset;
            left: unset;
          }
        }
        .message-icon {
          align-items: center;
          background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          height: 3.2rem;
          border: 1px solid #bcdfef;
          justify-content: center;
          width: 3.2rem;
          right: 0rem;
          position: absolute;
          bottom: -1.2rem;
          & > .episode-patient-detail-icon {
            position: relative;
            bottom: unset;
            top: unset;
            left: unset;
          }
        }
        .episode-patient-detail-icon {
          position: absolute;
          height: 3.2rem;
          width: 3.2rem;
          background-color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid #bcdfef;
          bottom: -1.2rem;

          &.message-icon {
            right: 0rem;
          }
        }
      }
    }
    &-right {
      width: 100%;
      padding-left: 1rem;
      height: 100%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      &-columns {
        padding: 0 1rem;
        width: 32%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-around;
        &:nth-child(2) {
          margin: 0 1rem;
        }
      }
      &-content {
        display: flex;
        align-items: baseline;
        line-height: 1.8rem;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        .name {
          font-family: "Mon-semi-bold", sans-serif;
          color: #858c9d;
          font-size: 1.4rem;
          width: 50%;
          margin-right: 1.5rem;
          text-align: right;
          word-break: auto;
        }
        .value {
          font-family: "Mon-semi-bold", sans-serif;
          color: #333333;
          width: 50%;
          font-size: 1.4rem;
          word-break: break-word;
          text-transform: capitalize;
          &.red-text {
            color: #b91d1d;
          }
        }
      }
    }
  }
  .episode-task-coming-soon {
    width: 100%;
    height: calc(100vh - 35rem);
    &-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-text {
      height: 6.3rem;
      width: 42.3rem;
      position: absolute;
      font-family: "Mon-bold", sans-serif;
      &-large {
        font-size: 52px;
        font-weight: 900;
        color: rgba(26, 169, 156, 0.04);
      }
      &-small {
        position: absolute;
        bottom: 0.8rem;
        left: 50%;
        transform: translateX(-50%);
        font-family: "Mon-bold", sans-serif;
        font-size: 2.2rem;
        text-shadow: -1px 0px 0px #ffffff, 1px 0px 0px #ffffff, 0px -1px 0px #ffffff, 0px 1px 0px #ffffff;
        color: #1aa99c;
      }
    }
    & img {
      width: 11.915rem;
      height: 11.915rem;
    }
  }
  .no-episode-details-found.no-data-found-container {
    height: calc(100vh - 5rem);
    .no-data-found {
      width: 30rem;
      & > *:not(:first-child) {
        margin-top: 1.6rem;
      }
      .text {
        margin-top: 0.7rem !important;
      }
    }
  }
  .episode-details-patient-decline-alert {
    width: fit-content;
    margin: 1.6rem 0;
  }
}
