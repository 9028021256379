.risk-assessment-result {
  #header {
    margin-bottom: 0;
  }
  &-header {
    min-height: 10rem;
    position: relative;

    .patient-details {
      justify-content: space-between;
    }
    .task-management-header-title {
      position: relative;
      top: -12px;
    }

    .left-section {
      max-width: 60%;
      overflow-wrap: break-word;
      display: flex;
      align-items: center;
    }

    .right-section {
      display: flex;
      align-items: center;
      .back-button-container {
        display: flex;
        align-items: flex-start;
        .back-button {
          cursor: pointer;
          font-family: "Mon-medium", sans-serif;
          margin: 0.3rem 2rem;
          font-size: 1.6rem;
          border-radius: 0.8rem;
          padding: 0.9rem 0;
          transition: all 0.2s;
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          &:hover {
            font-weight: bold;
          }
        }
      }
      .los-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .spacer {
    transform: translateY(7.5rem);
    overflow-y: auto;
    height: 72vh;
    padding: 1px;

    .risk-assessment-result-section-container:last-child {
      margin-bottom: 4rem;
    }
  }
  &-section-container {
    &:not(:first-child) {
      margin-top: 3rem;
    }
    .conclusion-questions {
      .conclusion-line {
        opacity: 0.1;
        background-color: #000;
        height: 0.1rem;
        width: 98%;
        margin: 3.6rem 0 3.6rem 0;
      }
      .conclusion-question-container {
        display: flex;
        flex-direction: column;
        width: 98.5%;
        .conclusion-title {
          font-family: "Mon-bold", sans-serif;
          font-size: 1.6rem;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #00887c;
          background: rgb(241, 250, 249);
          box-shadow: 0 0 0 0.1rem #bcdfef;
          border-radius: 0.8rem 0.8rem 0 0;
          padding: 1.2rem 0rem 1rem 2rem;
          word-break: break-word;
        }
        .conclusion-answer {
          padding: 1.7rem 2.4rem 1.4rem 2.4rem;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          line-height: 15px;
          letter-spacing: 0rem;
          text-align: left;
          color: #333333;
          border: 0.1rem solid rgba(51, 51, 51, 0.05);
          background: #fff;
          flex-grow: 1;
          color: #333;
          font-family: "Mon-medium", sans-serif;
          word-break: break-word;
        }
      }
    }
    .preview-section-header {
      .section-title {
        font-family: "Mon-bold", sans-serif;
        font-size: 1.6rem;
        color: #00887c;
      }
    }
    .risk-assessment-result-footer {
      display: flex;
      justify-content: flex-end;
      .total-score-container {
        display: inline-flex;
        position: relative;
        margin-top: 1.8rem;
        margin-right: 2rem;
        column-gap: 5.2rem;
        .total-heading {
          font-family: "Mon-bold", sans-serif;
          font-size: 1.6rem;
          line-height: 2rem;
          color: #00887c;
        }
        .total-score {
          font-family: "Mon-bold", sans-serif;
          font-size: 1.6rem;
          line-height: 2rem;
          color: #333333;
          padding-right: 2rem;
        }
      }
    }
  }

  .standard {
    background: #b7e2de;
  }
  .high {
    background: #ffe0e0;
  }

  .status-bar-container {
    width: 100%;
    height: 5.5rem;

    box-shadow: 0 -0.7rem 1.2rem rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
    left: 0;
    .left-text {
      font-family: "Mon-medium", sans-serif;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-right: 1rem;
    }

    .right-text {
      font-family: "Mon-bold", sans-serif;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #333333;
    }
  }

  .los-days-container {
    padding: 0 0.45rem 0 1.6rem;
    background: #f5faf9;
    border-radius: 0.8rem;
    display: flex;
    width: fit-content;
    position: relative;
    &.los-no-error {
      border: 0.1rem solid #bcdfef;
    }
    &.los-error {
      border: 0.1rem solid #ff5757;
    }
    &-title {
      padding: 1rem 1.6rem 1.3rem 0;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #00887c;
      border-right: 0.1rem solid #bcdfef;
    }
    &-los-input {
      display: flex;
      .number-input {
        width: 4rem;
        border: none;
        padding-left: 2rem;
      }
      .icon-container,
      .icon-container-disabled {
        background: #1aa99c;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.3rem;
        height: 3.1rem;
        margin: 0.45rem 0 0.45rem 0;
        cursor: pointer;
      }
      .icon-container-disabled {
        opacity: 0.5;
        cursor: text;
      }
      .icon-container-edit {
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.3rem;
        height: 3.1rem;
        margin: 0.45rem 0 0.45rem 0;
        cursor: pointer;
      }
    }
  }
}

.los-warning-empty {
  height: 1.4rem;
  margin-top: 0.4rem;
}
.los-warning {
  width: 14.2rem;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.2rem;
  color: white;
  margin-top: 0.7rem;
  position: absolute;
  top: 4rem;
  right: -0.3rem;
  z-index: 4;
  border-radius: 0.8rem;
  background-color: #e17271;
  padding: 1rem;
  text-align: center;
}

@media (max-width: 1600px) {
  .risk-assessment-result {
    .spacer {
      height: 55vh;
    }
  }
}
