.plan-of-care-tab-container {
  .plan-of-care-not-found-right-panel {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .plan-of-care-not-found-content {
      width: 26rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .plan-of-care-not-found-heading {
        margin-bottom: 0;
        font-family: "Mon-bold", sans-serif;
        line-height: 1.7rem;
        font-size: 1.4rem;
      }
      img {
        width: 16rem;
        height: 16rem;
        margin-bottom: 2rem;
      }
    }
  }
  &.footer-not-present {
    height: calc(100vh - 24rem);
  }
  &.footer-present {
    height: calc(100vh - 35rem);
  }
  .plan-of-care-tab-logs {
    height: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.6rem;
      .upcoming-task-title {
        overflow: hidden;
        color: #333;
        text-overflow: ellipsis;
        font-family: "Mon-bold", sans-serif;
        font-size: 1.6rem;
      }
      .add-task-cta {
        display: flex;
        align-items: center;
        font-family: "Mon-bold", sans-serif;
        font-size: 1.4rem;
        letter-spacing: 0px;
        color: #1aa99c;
        cursor: pointer;
        .plus-add-icon {
          margin-right: 0.5rem;
        }
      }
    }
    .plan-of-care-tab-log {
      width: 100%;
      font-family: "Mon-medium", sans-serif;
      padding: 1rem 0;
      font-size: 1.2rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      column-gap: 1rem;
      & > :not(:first-child, :last-child) {
        margin-bottom: 0.6rem;
      }
      & > :first-child,
      & > div:nth-child(2) {
        margin-bottom: 0.4rem;
      }
      .service-name {
        color: #00887c;
        font-size: 1.4rem;
        font-family: "Mon-semi-bold", sans-serif;
        line-height: 130%;
        margin-bottom: 0.4rem;
        word-break: break-word;
      }
      .service-provider,
      .service-los {
        color: #333;
        line-height: 130%;
        margin-bottom: 0.4rem;
        span {
          color: #667a78;
          margin-right: 0.4rem;
          font-family: "Mon-bold", sans-serif;
          word-break: break-word;
        }
      }

      .date-time {
        color: #999;
        margin: 0.5rem 0;
        line-height: 130%;
        color: #667a78;
        font-family: "Mon-bold", sans-serif;
        word-break: break-word;
      }
      .left-section {
        min-width: 85%;
        width: 85%;
      }
      .right-section {
        display: flex;
        width: 4rem;
        justify-content: flex-end;
        .buttons {
          display: flex;
          flex-direction: column;
          row-gap: 0.8rem;
          .icon {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: #ffffff;
          }
        }
      }
      .plan-of-care-tab-right-panel {
        display: block;
        font-size: 1.2rem;
        width: 100%;
        display: block;
        margin-bottom: 0.4rem;
        .name,
        .value {
          display: inline;
          width: unset !important;
          min-width: unset !important;
          word-break: break-word;
          line-height: 130%;
        }
        .name {
          margin-right: 0.4rem;
          color: #667a78;
          font-family: "Mon-bold", sans-serif;
        }
        .value {
          width: unset;
        }
      }
    }
  }
}
