@mixin dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.online {
  @include dot;
  background-color: #008f12;
}

.offline {
  @include dot;
  background-color: #cb2020;
}

.forwarded {
  @include dot;
  background-color: #ffa723;
}

.filtered {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #cb2020;
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
}

.filter-status-mobile {
  right: -0.25rem;
  top: -0.25rem;
}