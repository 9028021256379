@import "../../shared/assets/scss/variables.scss";

#user-management-container {
  .user-management-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4.55rem;
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .right-section {
      display: flex;
      align-items: center;
      position: relative;
      .add-user-dropdown {
        position: absolute;
        top: 4rem;
        right: 0px;
        width: 14.6rem;
        z-index: 3;
        border: 1px solid #cccccc;
        border-radius: 8px;
        .add-user-dropdown-item {
          display: flex;
          align-items: center;
          padding: 6px 4px 6px 16px;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 15px;
          background: #ffffff;
          height: 28px;
          color: #111111;
          border-radius: 8px;
          &:hover {
            cursor: pointer;
            background: #f1faf9;
            border-radius: 8px;
          }
        }
      }
      .search-icon-container,
      .filter-icon-container {
        width: 4rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .filter-status-container {
        position: relative;
        .filtered {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #cb2020;
          position: absolute;
          right: -0.25rem;
          top: -0.25rem;
        }
      }
      .filter-icon-container {
        margin-left: 1.6rem;
      }
      .add-user-button {
        height: 4rem;
        width: 14.6rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-left: 1.6rem;
        cursor: pointer;
      }
    }
  }
  .filter-applied-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .filter-text {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #687170;
    }
    .filters-applied {
      display: flex;
      margin: 0 1rem;
    }
    .clear-filter-button {
      color: #1aa99c;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      cursor: pointer;
    }
  }
  .user-management-listing-container {
    .user-management-listing-table {
      display: flex;
      flex-direction: column;
      .user-management-listing-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: #f1faf9;
        border-radius: 0.8rem 0.8rem 0 0;
        box-shadow: 0 0 0 0.1rem #bcdfef;
        color: #333;
        font-weight: 700;
        z-index: 2;
        .column {
          display: flex;
          width: 100%;
          padding: 1.7rem 4.6rem 1.8rem 2.2rem;
          align-items: center;
          .column-content {
            display: flex;
            align-items: center;
            cursor: pointer;
            .content {
              margin-right: 8px;
              font-family: Mon-bold, sans-serif;
              font-size: 1.4rem;
            }
            .sort-icon {
              &:hover {
                cursor: pointer;
                background: #f1faf9;
                border-radius: 8px;
              }
            }
            &-disable-cursor {
              &:hover {
                cursor: not-allowed;
              }
            }
          }
        }
      }
      .user-management-listing-content {
        &.filter-applied-content {
          height: calc(100vh - 26rem);
        }
        background-color: #fff;
        box-sizing: border-box;
        display: block;
        height: calc(100vh - 20rem);
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        border: 1px solid #eef0f6;
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        & > .infinite-scroll-component__outerdiv > .infinite-scroll-component {
          overflow: overlay !important;
        }
        .user-management-listing-row {
          display: flex;
          flex-direction: row;
          box-shadow: 0 0.1rem 0 0 #eef0f6;
          .column {
            width: 100%;
            word-break: break-word;
            padding: 1.7rem 0 1.8rem 2.2rem;
            .content {
              display: flex;
              align-items: center;
              font-family: Mon-medium, sans-serif;
              height: 32px;
              font-size: 1.4rem;
              line-height: 1.6rem;
            }
            &:first-child {
              color: #00887c;
            }
          }
          &:hover {
            background: #eeeeee;
            cursor: pointer;
          }
        }
      }
      .no-message-container {
        display: flex;
        height: 5rem;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        padding: 1.7rem 0 1.8rem 0;
        padding-left: 2.4rem;
        &:hover {
          background: #fff !important;
          cursor: default !important;
        }
      }
    }
  }
}

.status-filter {
  margin-top: 4rem;
}

.toggleContainer {
  display: flex;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem !important;
  line-height: 1.7rem !important;
  color: #333333 !important;

  .toggleTitle {
    margin: 0 1.2rem;
  }

  .active {
    color: #1aa99c;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3.6rem;
    height: 1.6rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.1rem solid #cccccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    left: 0.2rem;
    bottom: 0.1rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: grey;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
    background-color: #1aa99c;
  }

  .slider.round {
    border-radius: 3.4rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.green-button-container {
  height: 4rem;
  width: 14.6rem;
  background: #1aa99c;
  margin-left: 1.6rem;
  border-radius: 0.8rem;
  justify-content: center;
  cursor: pointer;
  .green-button-input {
    background: #1aa99c;
    color: #fff;
    outline: none;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    border: none;
    padding: 0;
    margin-right: 1.6rem;
    cursor: pointer;
  }
  .up-arrow-icon,
  .down-arrow-icon {
    color: #fff;
  }
}
