.toggleContainer {
  display: flex;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.6rem;
  line-height: 1.7rem;

  .toggle-input:disabled,
  .toggle-disabled,
  .slider-disabled {
    opacity: 0.4;
  }

  .slider-disabled {
    cursor: not-allowed !important;
  }

  .toggleTitle {
    margin: 0 1.2rem 0 0 !important;
  }
  .toogle-title-right {
    margin: 0 0 0 1.2rem !important;
  }

  .active {
    color: #1aa99c;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3.6rem;
    height: 1.6rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.1rem solid #cccccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    left: 0.2rem;
    bottom: 0.1rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #1aa99c;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
  }

  .slider.round {
    border-radius: 3.4rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
