.task-info-toc-tasks-details {
  display: flex;
  justify-content: space-between;
  padding: 4px 8px 4px 8px;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.2rem;
  border-radius: 0.4rem;
  color: rgba(51, 51, 51, 0.6);
  border: 0.2rem solid #667a781a;
  & .custom-tooltip {
    max-width: 38rem;
    white-space: normal;
    word-break: break-word;
  }
  &.obsolete-task {
    .details-left {
      max-width: 30rem;
    }
    .details-right {
      color: #f76659;
      font-family: "mon-semi-bold", sans-serif;
    }
  }
  .details-left {
    max-width: 100%;
    width: fit-content;
    word-break: break-word;
    line-height: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
