@import "../../shared/assets/scss/variables.scss";

.phone-floating-modal {
  position: absolute !important;
  right: inherit !important;
  bottom: inherit !important;
  background: #ffffff !important;

  &-content {
    background: #ffffff;
    border-radius: 0.8rem;
    padding: 3.4rem 4.1rem 4.6rem 4.1rem;
    height: 31.5rem;
    width: 22.5rem;
    cursor: move;
    border: 0.2rem solid rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8.15485rem);
    display: flex;
    flex-direction: column;

    .modal-body {
      flex: 1;
    }

    .modal-header {
      margin-bottom: 4.5rem;
      font-family: "Mon-semi-bold";
      font-size: 2.2rem;
      line-height: 2.7rem;
      text-align: center;
      color: #333333;
      .close-icon {
        display: none !important;
      }
    }

    &-container.incoming-call-modal-container {
      height: 100%;
      display: flex;
      flex-direction: column !important;
      position: relative;
      .timer {
        margin-bottom: 0;
      }
      .call-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        font-family: "Mon-medium", sans-serif;
        line-height: 1.5rem;
        font-size: 3rem;
        color: #85878d;
        margin-top: 1rem;
      }

      .loading-container {
        height: 100%;
      }

      .call-data-grid {
        display: grid;
        margin-top: 5rem;
        grid-template-columns: 30% 1fr;
        row-gap: 1.5rem;
        column-gap: 2rem;
        .item-name {
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 1.6rem;
          line-height: 2rem;
          color: #333333;
          display: flex;
          align-items: baseline;
          justify-content: flex-end;
          word-break: normal;
        }
        a.item-value {
          text-decoration-line: underline;
          color: #1aa99c;
        }
        .item-value {
          font-family: "Mon-medium", sans-serif;
          word-break: break-word;
          font-size: 1.5rem;
          line-height: 2rem;
          color: #333333;
          &-one-row,
          &-two-row,
          &-three-row {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          &-one-row {
            -webkit-line-clamp: 1;
          }
          &-two-row {
            -webkit-line-clamp: 2;
          }
          &-three-row {
            -webkit-line-clamp: 3;
          }
        }
      }
      .resize {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .minimize {
        cursor: pointer;
      }
      .minimized-buttons {
        .answer-call {
          width: 4.2rem !important;
          height: 4.2rem !important;
        }
      }
      .buttons {
        position: absolute;
        align-items: center;
        justify-self: flex-end;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        bottom: 0;
        margin-top: 3rem;
        .maximize {
          padding: 1.2rem;
          border: 1px solid gray;
          border-radius: 100%;
        }
        .answer-call {
          background-color: #4cbf07;
          color: #ffffff;
          width: 67px;
          height: 67px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        & > * {
          cursor: pointer;
        }
        .swap-position-icon-container {
          width: 4.8rem;
          height: 4.8rem;
          border: 0.1rem solid #85878d;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .swap-position-icon {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.calling-container {
  &.minimize-incoming-call .Toastify__toast-container {
    animation: scaleUp 0.5s both;
    height: fit-content !important;
    width: fit-content !important;
    max-width: 52rem !important;
    z-index: 10 !important;
    & #call {
      border-radius: 8px;
    }
    @media (max-width: $tablet) {
      height: 100%;
      width: 100%;
    }
    [data-tooltip]:before {
      font-size: 1.2rem;
      max-width: 20rem;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0.5rem;
    }
    [data-tooltip]:hover:before {
      /* needed - do not touch */
      opacity: 1;

      /* customizable */
      background: #1aa99c;
      margin-top: -1rem;
      margin-left: -5rem;
    }

    .Toastify__toast-body {
      margin: 0 !important;
    }
    .outgoing-call-modal-container {
      flex-direction: column !important;
      .incoming-call-details {
        .call-title {
          &--text {
            font-family: "Mon-bold", sans-serif;
            font-size: 2.2rem;
            color: $grey-text-color;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 23rem;
            white-space: nowrap;
            line-height: 2.9rem;
            text-align: left;
          }
        }
        .particiapants-title {
          width: fit-content;
          max-width: 15rem;
          white-space: nowrap;
          overflow: hidden;
          font-size: 1.4rem;
          text-overflow: ellipsis;
        }
      }
      .buttons {
        width: fit-content;
        .buttons-desktop,
        .buttons-mobile {
          width: fit-content;
          & > * {
            margin: 0 0.5rem !important;
          }
        }
      }
    }
    .phone-floating-modal-content-container {
      flex-direction: row !important;

      .loading-container {
        height: 1rem;
        width: 100%;
        margin-top: 1rem;
        .loader {
          & img {
            width: 5rem !important;
          }
        }
      }
      .incoming-call-details {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.incoming-call-details-minimized {
          align-items: flex-start;
          .item-value {
            width: fit-content;
            max-width: 13rem;
            display: block;
            -webkit-line-clamp: unset;
            -webkit-box-orient: unset;
          }
        }

        .call-title {
          font-size: 2.4rem;
          display: flex;
          a.caller-name {
            text-decoration-line: underline;
            color: #1aa99c;
          }
          .caller-name {
            font-family: "Mon-bold", sans-serif;
            font-size: 2.4rem;
            color: $grey-text-color;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 19rem;
            white-space: nowrap;
            line-height: 2.9rem;
            text-align: left;
            // a {
            //   text-decoration-line: underline;
            //   color: #1aa99c;
            // }
          }
        }
        .call-data-grid {
          margin-top: 1rem;
          column-gap: 3rem;
          width: 20rem;
          .item-name {
            font-size: 1.4rem;
            width: fit-content;
          }
          .item-value {
            font-size: 1.2rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .buttons {
        position: relative;
        flex-direction: row !important;
        margin-top: 0;
        &.minimized-buttons {
          & > * {
            margin: 0 0.5rem;
          }
        }

        .swap-position-icon-container {
          width: 3.8rem;
          height: 3.8rem;
        }
      }
    }
  }
  .Toastify__toast-container {
    animation: scaleDown 0.4s both;
    z-index: 10 !important;
    &:has(.no-incoming-call-details) {
      height: 40rem;
    }
    height: 65rem;
    width: 30.9rem;
    @media (max-width: $tablet) {
      height: 100%;
      width: 100%;
    }

    .Toastify__toast {
      height: 100%;

      .Toastify__close-button {
        display: none !important;
      }

      .Toastify__toast-body {
        margin: 1rem 2rem;

        .Toastify__toast-icon {
          display: none !important;
        }

        > div {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    transition: all 0.15s ease;
    padding: 1rem;
    color: white;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.1rem silver;
  }

  [data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: #1aa99c;
    margin-top: -5rem;
    margin-left: -3rem;
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
  }
}
@keyframes scaleUp {
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scaleDown {
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
