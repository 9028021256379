.tooltipSingleLine-container {
  .tooltip {
    position: relative;
  }

  .tooltip:hover {
    background-color: #eeeeee;
    border-radius: 50%;
  }

  .tooltiptext-center {
    display: block;
    visibility: hidden;
    width: 24.4rem;
    padding: .5rem 1rem;
    background: #ffffff;
    border: .1rem solid #e6e6e6;
    box-shadow: 0 .4rem 1.2rem rgba(0, 0, 0, 0.15);
    border-radius: .8rem;

    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: 0;
    margin-top: 1rem;

    font-family: "Mon-medium", sans-serif;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #333333;
    strong {
      color: black;
      font-family: "Mon-semi-bold", sans-serif;
    }
  }

  .tooltipHovered .tooltiptext-end {
    visibility: visible;
  }
  .tooltipHovered .tooltiptext-center {
    visibility: visible;
  }
}
