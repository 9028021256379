.providers-container {
  .provider-table {
    height: calc(100vh - 20rem);
    .provider-table-container {
      min-width: 90rem;

      .table-with-pagination {
        &-header .column,
        &-body .column {
          padding: 1.1rem 1.6rem;
          box-sizing: border-box;
          &:first-child {
            // width: 26rem;
            // min-width: 26rem;
            width: 20%;
            min-width: 20rem;
          }
          &:nth-child(2) {
            // width: 26rem;
            // min-width: 22rem;
            width: 16%;
            min-width: 16rem;
          }
          &:nth-child(3) {
            width: 16%;
            min-width: 16rem;
            // width: 22rem;
            // min-width: 18rem;
          }
          &:nth-child(4) {
            // width: 100%;
            // min-width: 26rem;
            width: 44%;
            min-width: 24rem;
          }
          &:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            // max-width: 10rem;
            // width: 10rem;
            min-width: 4rem;
            width: 4%;
            .edit-button {
              height: 3.2rem;
              width: 3.2rem;
              background-color: white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
        }
        &-header {
          .column {
            min-height: 5.2rem;
          }
        }
        &-body {
          .column {
            min-height: 6.4rem;
          }
        }
      }
    }
    .no-admin-providers-provider-found-container {
      height: 100%;
    }
  }
}
