.task-management-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;

  .task-render-container {
    display: flex;
    justify-content: space-between;
    .task-from-id {
      padding: 2.5rem 3.2rem 2.5rem 3.2rem;
      width: 100%;
      position: relative;
    }
  }
  & .task-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 94%;
    row-gap: 0.7rem;
    margin-top: 2.4rem;
    .no-task-found {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0rem;
      color: #333333;
    }
    .error-sub-title {
      font-family: "Mon-light", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0rem;
      color: #333333;
      width: 32rem;
      text-align: center;
      word-break: break-word;
    }
  }

  .dropdown-container.toc-tasks-dropdown-container {
    display: flex;
    flex-direction: column;
    .no-pointer-events {
      opacity: 1;
      cursor: not-allowed;
      pointer-events: unset;
      background: #f0f0f0;
      .icon-container {
        svg.select-box-down-arrow {
          color: #c1c9d2;
          cursor: not-allowed;
        }
      }
    }
  }

  .task-not-selected-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.expand {
      left: 10%;
    }
    &.collapse {
      left: 0;
    }
  }

  .expand-section-arrow,
  .collapse-section-arrow {
    position: absolute;
    top: 10%;
    background: transparent;
    cursor: pointer;
    z-index: 4;
  }

  .expand-section-arrow {
    right: -16px;
  }
  .collapse-section-arrow {
    right: -13px;
  }

  .task-management {
    box-shadow: 0.4rem 0 1rem rgba(0, 0, 0, 0.1);
    position: absolute;
    height: 100%;
    z-index: 3;
    background: #ffffff;
    .middle-section {
      .navigatorSection {
        display: block;
        .navigatorTabs {
          .tabs {
            width: 182px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .search-sort-section {
        margin: 1rem 0;
        display: flex;
        align-items: center;
        & .search-box.icon-button {
          width: 100%;
          height: 4rem;
          margin-right: 0;
        }
      }
    }
    .expanded {
      width: 45.639rem;
      padding: 3rem 0 0 1.2rem;
      height: 100%;
      transition: width 0.1s ease-in-out, opacity 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      opacity: 1;
    }
    .collapsed {
      width: 0px;
      padding: 3rem 0 0 1.2rem;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      transition: width 0.3s ease-in-out, opacity 0.1s ease-in-out;
      opacity: 0;
    }
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 24px;
      line-height: 29px;
      color: #64666a;
    }

    .middle-section {
      margin-bottom: 0;
      position: relative;
      padding-right: 2.4rem;
      .tags {
        display: flex;
        align-items: center;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        column-gap: 1rem;

        .tag-search-dropdown-container {
          width: 100% !important;
          .search-box-wrapper {
            min-width: 23rem;
            .search-box.icon-button {
              min-width: 23rem;
            }
          }
          .bold-text {
            font-weight: bold;
            color: #1aa99c;
          }
        }
        .select-span {
          padding: 0;
        }
      }
    }

    .no-task-found-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      margin-bottom: 7.5rem;
    }

    .task-list {
      max-height: calc(100vh - 27rem);
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 2.2rem;
      & > .infinite-scroll-component__outerdiv {
        .infinite-scroll-component {
          display: flex;
          flex-direction: column;
          gap: 8px;
          overflow-y: auto;
        }
      }
      &-no-record-found {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
        font-family: "Mon-medium", sans-serif;
      }
    }
  }
}
.search-task-not-found {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-container {
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 7.5rem;
    justify-content: center;
  }

  &-heading,
  &-text {
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-size: 1.4rem;
    font-family: "Mon-medium", sans-serif;
    margin-top: 0.7rem;
  }
  &-heading {
    font-family: "Mon-bold", sans-serif;
  }
  img {
    width: 18rem;
  }
}
