@import "shared/assets/scss/variables.scss";

.toc-change-view-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) !important;

  .modal-header {
    margin-bottom: 2.5rem;
  }

  .toc-change-view-main {
    width: 78rem;
    background: #ffffff;
    border-radius: 1.2rem;
    padding: 2rem;
    .cross-container {
      width: 2.8rem;
      height: 2.8rem;
      background: #f4f6fa;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-icon {
        width: 1.2rem !important;
        height: 1.2rem !important;
      }
    }
  }

  .toc-change-view-content {
    width: 100%;
    height: 100%;
    .toc-change-table {
      height: 100%;
      width: 100%;
      border: 1px solid #c3e3f0;
      border-radius: 1.2rem;
      .header {
        background-color: #f7fcfb;
        color: #333333;
        font-family: Mon-bold, sans-serif;
        display: flex;
        height: 5.2rem;
        font-size: 1.4rem;
        border-bottom: 1px solid #bcdfef;
        border-top-left-radius: 1.2rem;
        border-top-right-radius: 1.2rem;
        .location-cell {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 2rem;
          width: 16%;
        }
        .proposed-cell {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 2rem;
          width: 42%;
        }

        .approved-cell {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 2rem;
          width: 42%;
        }
      }

      .body {
        .row {
          display: flex;
          flex-direction: row;
          height: 6.4rem;
          border-bottom: 1px solid #ececec;
          .location-cell {
            width: 16%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            align-items: flex-start;
            padding-left: 2rem;
            .text {
              font-family: Mon-bold, sans-serif;
              font-size: 1.4rem;
              color: #636d70;
            }
            .sub-text {
              font-family: Mon-medium, sans-serif;
              font-size: 1.2rem;
              color: #333333;
              margin-top: 1rem;
              opacity: 0.6;
            }
          }
          .cell {
            width: 42%;
            font-family: Mon-medium, sans-serif;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            padding-left: 2rem;
            .text {
              font-size: 1.4rem;
              color: #333333;
            }
            .sub-text {
              font-size: 1.2rem;
              margin-top: 1rem;
              color: #333333;
              opacity: 0.6;
            }
          }

          &:last-child {
            border-bottom: none;
            border-bottom-left-radius: 1.2rem;
            border-bottom-right-radius: 1.2rem;
          }
        }

        .change {
          background-color: #f5f5f5;
        }
      }
    }
  }
}
