.preview-section {
  margin-right: 1rem;
  &-table {
    thead {
      height: 5.2rem;
      background: rgba(241, 250, 249, 1);
      box-shadow: 0 0 0 .1rem #bcdfef;
      border-radius: .8rem .8rem 0 0;
      th {
        text-align: left;
        font-family: "Mon-bold", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #333333;
        &:first-child {
          border-radius: .8rem 0 0 .8rem;
          padding-left: 2rem;
        }
        &:last-child {
          border-radius: 0 .8rem .8rem 0;
          text-align: right;
          padding-right: 3rem;
        }
      }
    }
    tbody {
      tr {
        background: #ffffff;
        &.sub-section-tr {
          background: transparent;
        }
        &.extra-info-tr {
          background: rgba(193, 201, 210, 0.15);
          border: .1rem solid rgba(51, 51, 51, 0.05);
          border-radius: 0;
        }
        td {
          text-align: left;
          padding: 1.5rem 3rem;
          .question {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: rgba(126, 126, 126, 1);
          }
          .answer {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            line-height: 2rem;
            color: #333333;
            margin-top: 1rem;
            font-weight: bold;
          }
          &.sub-section-title-td {
            border: none;
            .sub-section-title {
              font-family: "Mon-bold", sans-serif;
              font-size: 1.6rem;
              line-height: 2rem;
              color: #00887c;
              background: transparent;
            }
          }
          &.score {
            text-align: right;
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 1.6rem;
            line-height: 2rem;
            color: #333333;
            padding-right: 3rem;
          }
          .extra-info {
            display: flex;
            column-gap: 1rem;
            &-title {
              font-family: "Mon-medium", sans-serif;
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #333333;
              min-width: 12rem;
            }
            &-values {
              display: flex;
              flex-direction: column;
              row-gap: 1rem;
              font-family: "Montserrat";
              font-family: "Mon-medium", sans-serif;
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
