@import "shared/assets/scss/variables.scss";

#contact-container {
  .contact-desktop-header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
    .contact-dropdown-container {
      .no-pointer-events {
        cursor: not-allowed !important;
        opacity: 0.4;
        pointer-events: auto;
      }

      .dropdown-label {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #333333;
        text-transform: capitalize;
        margin-bottom: 0.6rem;
      }
      .contact-dropdown {
        margin: 0;
      }
    }
    .right-section {
      gap: 2.6rem;
      align-items: end;
      .search-icon-container,
      .search-box.icon-button {
        height: 4rem;
        margin: 0rem !important;
      }
    }
  }
  .header-title {
    font-family: "Mon-bold", sans-serif;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: $grey-header-color;
    .right-section {
      display: flex;
      align-items: end;
      .toggleContainer {
        margin-top: 0;
        .toggleTitle {
          margin: 0;
        }
        .switch {
          margin: 0 1.2rem;
        }
      }
      .dropdown-container {
        width: auto;
        margin: 0 2.2rem 0 0;
        .select-span {
          margin-left: 0;
          margin-right: 0;
          &:not(:empty) {
            min-width: 22rem;
          }
        }
        .name-container {
          margin-right: 1rem;
        }
        .option-box-wrapper {
          margin-left: 0;
          margin-right: 0;
        }
      }
      .search-icon-container {
        margin: 0rem;
      }
      .search-icon-container,
      .filter-icon-container {
        width: 4rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .filter-icon-container {
        margin: 0 2.6rem;
      }
    }
  }
}

.contact-mobile-header {
  width: 100%;
  margin-top: 3.2rem;
  .title-with-toggle {
    display: flex;
    justify-content: space-between;
  }
  .dropdown-container {
    .select-span {
      margin: 0;
      margin-top: 2.2rem;
    }
    .option-box-wrapper {
      width: 100%;
      margin: 0;
    }
  }
  .search-box {
    width: 100%;
    margin-top: 2.2rem;
    min-height: 4rem;
  }
  .toggleContainer {
    font-size: 1.2rem;
    align-items: center;
    margin-top: 0;
    .toggleTitle:last-child {
      margin-right: 0;
    }
    .switch {
      width: 4rem;
      height: 2rem;
    }
    .slider:before {
      height: 1.6rem;
      width: 1.6rem;
    }
  }
}

.navigator-dropdown-container {
  display: flex;
  flex-direction: column;
  .navigator-contact-selection {
    width: auto;
    height: 4.2rem !important;
    border: 0.1rem solid #a6d9ce !important;
    margin: 0 3.2rem 0 2.8rem;
    &.navigator-selection {
      min-width: 22rem;
      max-width: 22rem;
    }
    &.navigator-contact-type-selection {
      min-width: 16rem !important;
      max-width: 16rem;
    }
  }
  .navigator-contact-dropdown {
    margin: -1rem 3.2rem 0 2.8rem;
    overflow-y: auto;
    top: calc(100% + 1.2rem) !important;
  }
}

.mobileView {
  #contact-container {
    .contact-header {
      flex-direction: column;
      align-items: end;
      margin-bottom: 1.6rem;
      .header-title {
        font-size: 1.6rem;
        line-height: 2rem;
      }
      .header-title-mobile {
        font-family: "Mon-bold", sans-serif;
        position: absolute;
        top: 2.4rem;
        font-size: 2.4rem;
      }
    }
  }
}
