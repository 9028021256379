@import "../../shared/assets/scss/variables.scss";

#dashboard-container {
  .loading-container {
    height: calc(100vh - 31rem);
  }
  .dashboard-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .status-and-date-picker {
      display: flex;
      column-gap: 16px;
      align-items: center;
      .export-to-csv-button {
        width: 13.5rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid #acc0bb;
        border-radius: 0.8rem;
        cursor: pointer;
        font-family: "Mon-semi-bold", sans-serif;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  .statistic-box-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 3rem;
  }
  .all-filters-section {
    margin-top: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.4rem;
    .right-section {
      display: flex;
      align-items: center;
      .search-icon-container {
        margin: 0 1.6rem 0 3.2rem;
      }
      .search-icon-container,
      .filter-icon-container {
        width: 4rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.mobileView {
  #dashboard-container {
    .dashboard-header {
      .header-title {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
