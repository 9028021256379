.notification {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-size: 1.1rem;
  font-family: "Mon-medium", sans-serif;
  padding: 1rem 1.6rem;
  cursor: pointer;
  column-gap: 1rem;

  .new-message-img-container,
  .new-toc-img-container,
  .missed-call-img-container {
    display: flex;
    width: 3.6rem;
    height: 3.6rem;
    align-items: center;
    justify-content: center;
    margin-left: 0.4rem;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.missed-call-img-container {
  background: #fd9788;
  border-radius: 50%;
}

.new-message-img,
.new-toc-img {
  scale: 1.1;
}

.missed-call-img {
  scale: 0.6;
}
.notification-title {
  width: 21rem;
  line-height: 14px;
  word-break: break-word;
  text-align: left;
}

.notification-timeStamp {
  color: #667a78;
  font-size: 1rem;
  font-family: "Mon-medium", sans-serif;
  width: 8rem;
  text-align: left;
}

.mobileView {
  .notification-content {
    display: flex;
    align-items: center;
    width: 70%;
  }
  .notification-timeStamp {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .notification-right-caret {
    z-index: 4;
    scale: 0.8;
    rotate: 180deg;
  }
}
