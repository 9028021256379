@import "../../../../shared/assets/scss/variables.scss";

.patient-form-container {
  .name {
    margin-top: 3rem;
  }

  .form-row {
    display: flex;
    justify-content: space-between;

    .form-col {
      width: 33%;

      .field-wrap {
        display: block;
        align-items: flex-start;
        background: none !important;
      }

      .field-temporary-disabled {
        opacity: 0.6;
        cursor: initial;
      }

      .phone-container {
        width: 100% !important;

        .error-wrap {
          color: $red-notification-color;
          font-size: 1.2rem;
          font-family: "Mon-medium";

          .error-message-icon {
            margin-right: 0.65rem;
          }
        }

        .phone-container-content {
          margin-bottom: 0;

          .field-wrap {
            display: flex;
            background: none;
          }
        }
      }

      .search-box-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .error-wrap {
        color: $red-notification-color;
        font-size: 1.2rem;
        font-family: "Mon-medium";
        display: flex;
        align-items: center;
        margin-top: 0.65rem;

        .error-message-icon {
          margin-right: 0.65rem;
        }
      }

      .patient-list {
        z-index: 1;
        width: 100%;
        max-height: 20rem;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border: 1px solid #cccccc;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
        border-radius: 8px;
        overflow-y: auto;
        overflow-x: hidden;

        .no-search-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 6px 4px 6px 16px;
          gap: 1rem;
          min-height: 4rem;
          width: 98%;
          background: #ffffff;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.5rem;
          text-align: center;
          color: #111111;
        }

        .patient-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 6px 4px 6px 16px;
          gap: 1rem;
          min-height: 4rem;
          width: 98%;
          background: #ffffff;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.5rem;
          text-align: center;
          color: #111111;

          &:hover {
            cursor: pointer;
            background: #f1faf9;
          }
        }
      }

      .patient-searchbox {
        width: 100%;
        margin-bottom: 3rem;
      }

      .status-switch {
        margin-top: 2rem;
        margin-left: -1.2rem;
      }

      .status-switch-label {
        color: #333;
        display: block;
        font-family: Mon-medium;
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin: 0.6rem 0;
        text-transform: capitalize;
      }
    }

    .patient-select {
      position: relative;
    }

    .phone-col .field-wrap {
      flex-direction: row;
    }

    .field-wrap {
      height: 100%;
      flex-direction: column;
      align-items: start;
      background-color: #fafafa;
      width: 70%;
    }

    .type-input {
      margin-bottom: 2.9rem;

      .option-box-wrapper {
        width: 100%;
        overflow-y: scroll;
      }
    }

    .phone-container {
      width: 100% !important;

      .error-wrap {
        color: $red-notification-color;
        font-size: 1.2rem;
        font-family: "Mon-medium";
        margin-top: 0.65rem;
        display: flex;
        align-items: center;

        .error-message-icon {
          margin-right: 0.65rem;
        }
      }

      .phone-container-content {
        margin-bottom: 0;

        .field-wrap {
          display: flex;
          background: none;
        }
      }
    }

    .account-type-disabled input,
    .created-disabled,
    .updated-disabled {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      opacity: 0.8;
      background-color: #f0f0f0;
    }
  }

  .form-row-patient-search {
    align-items: end;
  }

  .loader {
    margin: 0 !important;

    img {
      width: 4rem !important;
    }
  }

  .form-row-nav {
    display: flex;
    justify-content: left;

    .is-manager-switch {
      width: 16%;
    }
  }
}

.type-input .error-wrap {
  color: $red-notification-color;
  font-size: 1.2rem;
  font-family: "Mon-medium";
  margin-top: 0.65rem;
  display: flex;
  align-items: center;

  .error-message-icon {
    margin-right: 0.65rem;
  }
}
