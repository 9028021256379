.result-modal-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) !important;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem;
  color: #333333;
  #modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6rem;
    .standard-text {
      color: rgba(5, 120, 87, 1);
    }
    .high-text {
      color: rgba(255, 87, 87, 1);
    }
    header {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.4rem;
      color: #333333;
    }
    .close-icon-container {
      font-size: 0;
    }
    .close-icon {
      cursor: pointer;
    }
  }
  .result-modal-main {
    width: 43.3rem;
    background: #ffffff;
    border-radius: 1.2rem;
    padding: 1.8rem 3rem;
    .cross-container {
      width: 2.8rem;
      height: 2.8rem;
      background: #f4f6fa;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-icon {
        width: 1.2rem !important;
        height: 1.2rem !important;
      }
    }
  }
  .result-modal-content {
    display: flex;
    flex-direction: column;
    .result-modal-text {
      margin-bottom: 0.8rem;
    }
    .content {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      color: #333333;
    }

    .result-list-container {
      display: flex;
      justify-content: left;
      padding-left: 1.5rem;
      .result-list {
        list-style-type: disc;
      }
    }

    .button-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 1.6rem;
      .cancel-button {
        font-family: "Mon-medium", sans-serif;
        color: #333333;
        padding: 1rem;
        width: 12.2rem;
        height: 4rem;
        font-size: 1.6rem;
        cursor: pointer;
        margin-right: 0.8rem;
      }
      .reload-button {
        font-size: 1.6rem;
        width: 12.2rem;
        height: 4rem;
        cursor: pointer;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
