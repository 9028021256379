.refresh-component-container {
  display: flex;

  .reload-link {
    font-family: "Mon-bold", sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #f76659;
    cursor: pointer;
  }
}
