#episodes-container {
  .episodes-listing-table {
    min-width: 60rem;
    display: flex;
    flex-direction: column;
    .search-episodes-not-found {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-container {
        height: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        justify-content: center;
      }

      &-heading,
      &-text {
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-size: 1.4rem;
        font-family: "Mon-medium", sans-serif;
        margin-top: 0.7rem;
      }
      &-heading {
        font-family: "Mon-bold", sans-serif;
      }
      img {
        width: 18rem;
      }
    }

    .episodes-listing-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      background: #f1faf9;
      border-radius: 0.8rem 0.8rem 0 0;
      box-shadow: 0 0 0 0.1rem #bcdfef;
      color: #333;
      z-index: 2;
      &-scrollbar-visible {
        .column:nth-child(9) {
          width: 12.3% !important;
        }
      }
      .column {
        display: flex;
        box-sizing: border-box;
        padding: 1.7rem 0 1.8rem 2.2rem;
        align-items: center;
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(7) {
          width: 12%;
        }
        &:nth-child(2),
        &:nth-child(6) {
          width: 10%;
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(9) {
          width: 11%;
        }
        &:nth-child(9) {
          padding-right: 1rem;
        }
        .column-content {
          display: flex;
          align-items: center;
          cursor: pointer;

          .content {
            margin-right: 0.8rem;
            font-family: Mon-bold, sans-serif;
            font-size: 1.4rem;
          }
          .sort-icon {
            &:hover {
              cursor: pointer;
              background: #f1faf9;
              border-radius: 0.8rem;
            }
          }
          &-disable-cursor {
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
    }
    .episodes-listing-content {
      background-color: #fff;
      box-sizing: border-box;
      display: block;
      height: calc(100vh - 19rem);
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      border: 0.1rem solid #eef0f6;
      border-radius: 0.8rem;
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
      &-filter-applied {
        height: calc(100vh - 24rem);
      }
      & > .infinite-scroll-component__outerdiv > .infinite-scroll-component {
        overflow: overlay !important;
      }
      .episodes-listing-row {
        display: flex;
        flex-direction: row;
        box-shadow: 0 0.1rem 0 0 #eef0f6;
        .column {
          box-sizing: border-box;
          word-break: break-word;
          padding: 1.7rem 0 1.8rem 2.2rem;
          display: flex;
          align-items: center;
          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(7) {
            width: 12%;
          }
          &:nth-child(2),
          &:nth-child(6) {
            width: 10%;
          }
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(8),
          &:nth-child(9) {
            width: 11%;
          }
          &:nth-child(9) {
            padding-right: 2rem;
          }
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9) {
            .content {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .content {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
          }
          &.dob {
            .content {
              flex-direction: column;
              align-items: unset;
              justify-content: center;
              .age {
                width: fit-content;
                background-color: #ebebeb;
                padding: 0.2rem 0.4rem;
                border-radius: 0.4rem;
                font-size: 1rem;
                margin-top: 0.5rem;
              }
            }
          }
        }
        &:hover {
          background: #eeeeee;
          cursor: pointer;
        }
      }
    }
    .no-message-container {
      display: flex;
      height: 5rem;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      padding: 1.7rem 0 1.8rem 0;
      padding-left: 2.4rem;
      &:hover {
        background: #fff !important;
        cursor: default !important;
      }
    }
  }
}
