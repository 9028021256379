@import "shared/assets/scss/variables.scss";

.episode-details-container-with-patient-declined-alert {
  #episode-details-patient-demographic {
    height: calc(100vh - 46rem);
  }
}
#episode-details-patient-demographic {
  height: calc(100vh - 43rem);
  overflow-y: auto;
  .demographic-loader {
    height: 100%;
  }
  .patient-demographic-container {
    max-width: 111.6rem;
    .toggle-wrapper {
      margin-top: 2.4rem;
      margin-bottom: 3.4rem;
    }
    .title {
      color: $shade-teal-green;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      margin-top: 4.4rem;
      margin-bottom: 2rem;
      font-weight: 700;
    }
    .title-margin {
      margin-top: 2.4rem;
    }
    .coach-details {
      margin-top: 1.4rem;
    }
    .margin-top-row {
      margin-top: 3rem;
    }
    .patient-demographic-row-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 8rem;
    }
    .consent-coach {
      display: flex;
      align-items: center;
    }
    .patient-demographic-wrapper {
      box-sizing: border-box;
      .label {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #333333;
        margin: 0.8rem 0rem;
        .red-color-asterisk {
          color: red;
        }
      }
      .phone-container {
        width: 30%;
        .error-wrap {
          color: $red-notification-color;
          margin-top: 0.6rem;
          font-size: 1.2rem;
          font-family: "Mon-medium", sans-serif;
        }
      }
      #single-date-picker-selector {
        width: 30rem !important;
        .react-datepicker-wrapper {
          width: 100%;
        }
        .date-picker-input {
          width: 100%;
          box-sizing: border-box;
        }
      }
      .dropdown-container {
        width: 30rem;
      }
    }
  }
  .text-input-field-container .field-wrap{
    background: none;
  }
}
