.file-downloading-popup-modal-container {
  .modal-main {
    border-radius: 0.8rem;
  }
  .file-downloading-popup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background: #ffffff;
    border: 0.1rem solid rgba(51, 51, 51, 0.2);
    border-radius: 0.8rem;
    box-sizing: border-box;
    max-width: 35rem;
    padding: 2.4rem 4.5rem;
    min-width: 30.9rem;
    & > *:not(:last-child) {
      margin-bottom: 1.8rem;
    }
    & > .image {
      margin-bottom: 1.5rem;
      img {
        width: 5.8rem;
        height: 5.8rem;
      }
    }
    .content {
      &,
      &-heading {
        color: #333;
        text-align: center;
      }
      & {
        font-size: 1.4rem;
        font-family: "Mon-medium", sans-serif;
      }
      &-heading {
        font-size: 1.6rem;
        font-family: "Mon-bold", sans-serif;
      }
    }
    .button-section {
      display: flex;
      flex-direction: row;
      & > * {
        padding: 1rem 3rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        cursor: pointer;
      }
      .cancel-button {
        color: #333333;
        padding: 1rem;
      }
    }
  }
}
