@import "shared/assets/scss/variables.scss";

#navigator-dashboard-container {
  height: 100%;
  .navigator-dashboard-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }

    .status-and-date-picker {
      display: flex;
      column-gap: 16px;
    }
  }
  .all-filters-section {
    margin-top: 4.2rem;
    margin-bottom: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right-section {
      display: flex;
      align-items: center;
      .search-icon-container {
        margin: 0 1.6rem 0 3.2rem;
      }
      .search-icon-container,
      .filter-icon-container {
        width: 4rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .make-a-call-button {
        height: 4rem;
        width: 14.6rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-left: 1.6rem;
        cursor: pointer;
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
          cursor: not-allowed;
        }
        &.enabled {
          opacity: 1;
          pointer-events: all;
          cursor: pointer;
        }
      }
      .make-a-call-action {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.6rem;
        height: 5.6rem;
        border-radius: 100%;
        position: fixed;
        bottom: 9.8rem;
        right: 1.5rem;
        z-index: 3;
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
          cursor: not-allowed;
        }
        &.enabled {
          opacity: 1;
          pointer-events: all;
          cursor: pointer;
        }
      }
    }
  }
  .filters-applied-container {
    .filters-applied-label-container {
      display: flex;
      .filters-applied-label {
        font-family: "Mon-bold";
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        opacity: 0.6;
      }
      .clear-filters-cta {
        font-family: "Mon-medium", sans-serif;
        margin-left: 16px;
        font-weight: 500;
        font-size: 14px;
        color: #1aa99c;
        cursor: pointer;
      }
    }
    .filters-applied-pill-container {
      margin-top: 8px;
      margin-bottom: 24px;
      display: flex;

      .filter-pill {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 8px 8px 12px;
        margin-right: 16px;
        background: #eeeeee;
        border-radius: 8px;
        .filter-pill-content {
          margin-right: 21px;
          .filter-pill-label {
            margin-bottom: 4px;
            font-family: "Mon-bold";
            color: rgba(0, 0, 0, 0.4);
            font-size: 10px;
          }
          .filter-pill-value {
            font-family: "Mon-medium", sans-serif;
            font-size: 12px;
            color: #000000;
          }
        }
        .remove-filter-pill-cta {
          cursor: pointer;
        }
      }
    }
  }
  .table-container {
    @media (max-width: $tablet) {
      padding: 0;
      margin-top: 3.1rem;
      height: calc(100% - 20rem);
      &::-webkit-scrollbar-thumb {
        background-color: $white;
      }
    }

    table {
      margin: 0;
    }
  }
}

#modal {
  .side-popup-content {
    width: 50rem;
  }
  .chat-side-popup {
    .modal-header {
      .close-icon-container {
        .close-icon {
          width: 2rem !important;
          height: 2rem !important;
        }
      }
    }
    .two-liner-heading {
      display: flex;
      flex-direction: column;

      .participants {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 2.4rem;
        line-height: 2.9rem;
        color: #333333;
        flex: 1;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 2rem;
      }
      .subject {
        color: #636d70;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
      }
    }

    .modal-header {
      height: 10% !important;
    }
    .modal-body {
      height: 89% !important;
    }
    .chat-content {
      position: relative;
      height: 92%;
      .chat-messages {
        &.height {
          height: 88%;
        }
        &.reduce-height {
          height: 76%;
        }
      }
      #chat-messages {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .preview-section {
        &.hidden {
          display: none;
          opacity: 0;
        }
        &.show {
          display: flex;
          opacity: 1;
          width: 100%;
          bottom: 7.6rem;
          position: absolute;
          background: #dbe7e5;
          align-items: center;
        }
      }
      .chat-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #ffffff;
        border: 0.1rem solid #e6e6e6;
        box-shadow: 0 0 0.6rem rgba(193, 199, 204, 0.3);
        border-radius: 0.2rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input[type="file"] {
          display: none;
        }
        label {
          cursor: pointer;
        }
        .chat-text-container {
          position: relative;
          width: 92%;
          background: #dbe7e5;
          .attachment {
            position: absolute;
            right: 1rem;
            top: 1.5rem;
            opacity: 0.5;
          }
          .chat-text-box {
            background: #dbe7e5;
            border: 0.1rem solid #e5ede7;
            border-radius: 0.4rem;
            height: 4.2rem;
            width: 87%;
            outline: none;
            padding-left: 1rem;
          }
        }
        .send {
          width: 8%;
          align-self: center;
          margin-right: 1.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .mobileView {
    &.new-message {
      .side-popup-content {
        width: 100%;
      }
      .modal-body {
        height: calc(100% - 8.3rem) !important;
      }
    }
    &.chat-side-popup {
      .side-popup-content {
        width: 100%;
        .header {
          align-items: center;
          padding: 1.7rem 1.8rem;
          margin-bottom: 0;
          .heading {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
      }
      .modal-header {
        height: 5.4rem !important;
        box-shadow: 0 0 1rem rgba($black, 0.1);
      }
      .modal-body {
        height: calc(100% - 5.4rem) !important;
      }
      .chat-content {
        .chat-footer {
          padding: 1rem 1.5rem 1rem 2rem;
          box-sizing: border-box;
        }
      }
    }
  }
}

#modal {
  .filter-side-popup {
    .filter-content-container {
      width: 377px;
      .modal-header {
        margin-bottom: 4.6rem;
      }
      .modal-body {
        height: 82%;
        padding: 1.6rem;

        .filter-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .status {
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: $grey-sub-header-color;
            margin-bottom: 2.1rem;
          }
          .filter-buttons {
            display: flex;
            flex-direction: column;
            margin-bottom: 2.4rem;

            .apply-button {
              margin-bottom: 1.6rem;
            }

            .apply-button,
            .clear-button {
              width: 100%;
              height: 4rem;
              font-family: "Mon-medium", sans-serif;
              font-size: 1.6rem;
              line-height: 2rem;
            }
          }
          .checkboxes {
            margin-bottom: 1.4rem;
          }
        }
      }
    }
    &.mobileView {
      background: rgba(0, 0, 0, 0.6);
      .filter-content-container {
        width: 100%;
        .modal-header {
          margin-bottom: 0;
          height: auto;
          max-height: 8.3rem;
          .header {
            flex-direction: row-reverse;
            align-items: center;
            padding-bottom: 3.6rem;
            margin-bottom: 0;
            .heading {
              text-align: left;
              margin-left: 0;
            }
          }
        }
        .modal-body {
          overflow-y: auto;
          height: calc(100% - 8.3rem - 3.2rem);
          padding: 0;
          margin: 1.6rem;

          .filter-content {
            .status {
              &:not(:first-of-type) {
                margin-top: 4rem;
              }
            }
            .filter-buttons {
              margin-top: 4.5rem;
              margin-bottom: 0;

              .apply-button,
              .clear-button {
                height: 5rem;
              }
            }
            .checkbox-container {
              height: auto;
            }
          }
        }
      }
      &.call-participants-popup {
        .filter-content-container {
          width: 100%;
        }
        .modal-body {
          margin-top: 0;
        }
      }
    }
  }
}

.mobileView {
  #navigator-dashboard-container {
    .navigator-dashboard-header {
      align-items: center;
      flex-direction: row-reverse;
      .header-title {
        font-size: 2.4rem;
        line-height: 2rem;
      }

      .header-screen-name {
        line-height: 2rem;
      }

      .status-and-date-picker {
        align-items: center;
      }
    }
    .all-filters-section {
      flex-wrap: wrap;
      margin-top: 1.6rem;
      .right-section {
        width: 100%;
        .search-box {
          width: 100%;
          margin-right: 0;
          min-height: 4rem;
        }
        .filter-icon-container {
          margin-left: 1.6rem;
        }
        .make-a-call-button {
          display: none;
        }
        .make-a-call-action {
          z-index: 2;
        }
      }
    }
    .table-container {
      padding: 0;
      margin-top: 0;
      height: calc(100% - 20rem);
      &::-webkit-scrollbar-thumb {
        background-color: $white;
      }
    }
  }

  .navigatorSection {
    width: 100%;
    margin-bottom: 1.6rem;
    .navigatorTabs {
      width: 100%;
      .tabs {
        width: 50%;
        text-align: center;
      }
    }
  }
}

.filter-status-container {
  position: relative;
}
