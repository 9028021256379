@import "shared/assets/scss/variables.scss";

.filter-pill {
  background-color: $grey-bg-color;
  padding: 8px;
  border-radius: 8px;
  margin: 0 10px 10px 0px;
  display: flex;
  .text-container {
    .title {
      font-family: "Mon-bold", sans-serif;
      font-size: 1rem;
      line-height: 1.7rem;
      color: #687170;
    }
    .text {
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      margin-right: 8px;
    }
  }
  .close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
