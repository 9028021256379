@import "../../shared/assets/scss/variables.scss";

.empty-screen-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 90%;
  .oops-image {
    width: 17.1rem;
    height: 14rem;
  }
  .empty-screen-text {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: $grey-text-color;
    margin-top: 2.3rem;
    margin-bottom: 2.4rem;
  }
  .add-navigator-button {
    width: 17.8rem;
    height: 4rem;
    font-family: "Mon-medium";
    font-size: 1.6rem;
    cursor: pointer;
    line-height: 2rem;
  }
}
