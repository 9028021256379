.textarea-input-group-section {
  display: flex;
  flex-direction: column;
  .label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6px;
    .left-label {
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      .red-color {
        color: red;
      }
    }
    .right-label {
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #767676;
    }
  }
  textarea {
    resize: none;
    outline: none !important;
    border: 1px solid #c1c9d2;
    border-radius: 6px;
    font-family: "Mon-medium", sans-serif;
    padding: 10px;
  }
  .focused {
    border: 1px solid #007aff;
    box-shadow: 0px 0px 10px rgba(0, 87, 255, 0.25);
  }
  .red-border {
    border: 1px solid #ff5757;
  }
  .disabled{
    cursor: not-allowed;
  }
  .error-wrap {
    color: #ff5757;
    margin-top: 6px;
    font-size: 12px;
    font-family: "Mon-medium", sans-serif;
  }
}
