@import "../../shared/assets/scss/variables.scss";

.ongoing-tag {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .dot {
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50%;
    background: $primary-bg-color;
  }
  .ongoing-text {
    font-family: "Mon-bold", sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $primary-bg-color;
    margin-left: 0.8rem;
    margin-right: 1.5rem;
  }
  .cross-icon-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
