.task-not-selected {
  width: 369px;
  border-radius: 16px;
  font-family: "Mon-bold", sans-serif;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 90px 0px;
  &-text {
    margin: 0px 58px 0px 58px;
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .heading {
    font-family: "Mon-bold", sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
    width: 266px;
    word-break: break-word;
    margin-top: 33px;
  }

  .sub-heading {
    font-family: "Mon-light", sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
    width: 266px;
    word-break: break-word;
    margin-top: 7px;
  }
}
