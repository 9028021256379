@import "../../shared/assets/scss/variables.scss";

.checkbox {
  appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  background: #ffffff;
  border-radius: 0.2rem;
  border: 0.1rem solid rgba(26, 169, 156, 0.5);
  cursor: pointer;
  margin: 0 0.8rem 0 0;

  &:disabled {
    cursor: not-allowed;
  }

  &:checked {
    background-color: #1aa99c;
    background-image: url("../../shared/assets/images/checkbox-tick.svg");
    background-repeat: no-repeat;
    height: 1.6rem;
    width: 1.6rem;
    background-origin: border-box;
    background-position: center;
  }
}

.checkbox-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #333333;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  &.disabled-but-highlighted {
    cursor: default;
  }
}
