#browser-view-container {
  padding-left: 12px;
  width: calc(100vw - 80px);
  #browser-view-context {
    height: 100%;

    #header-container {
      display: flex;
      background: #b9dbd8;
      height: 40px;
      padding: 8px 24px 0px 30px;
    }

    #view-container {
      height: 100%;
      #view-context {
        height: 100%;
        width: calc(100vw - 92px);
        .task-not-selected-container {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          &.expand {
            left: 10%;
          }
          &.collapse {
            left: 0;
          }
        }
      }
    }
  }
}
