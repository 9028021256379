@import "shared/assets/scss/variables.scss";

.otpScreenContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .otpFormWrapper {
    display: flex;
    height: inherit;
    width: 40%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .left-content {
      transform: translateY(-3rem);
      .otpBoxes {
        .reset-password-section {
          .text-input-field-container {
            .field-wrap {
              .input-field {
                width: 100%;
                background-color: transparent;
                font-size: 1.4rem;
                line-height: 115%;
                font-family: "Mon-semi-bold";
                border-style: solid;
                border: none;
                &::placeholder {
                  color: rgba(255, 255, 255, 0.42);
                }
              }
            }
          }
        }
      }
      .login-input-container {
        .fieldLabel {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.5rem;
          line-height: 130%;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.42);
          margin-bottom: 0.2rem;
          padding-left: 0.2rem;
        }

        .username-icon {
          margin-left: 1.425rem;
          margin-right: 1.225rem;
        }

        .login-input-container {
          width: 38rem !important;
        }
        .input-field {
          width: 100%;
          background-color: transparent;
          font-size: 1.4rem;
          line-height: 115%;
          font-family: "Mon-semi-bold";
          border-style: solid;
          border: none;
          &::placeholder {
            color: rgba(255, 255, 255, 0.42);
          }
        }
      }
    }

    .otp-header {
      position: absolute;
      top: 4.2rem;
    }

    .login-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .forgot-password {
        font-size: 1.6rem;
        line-height: 2rem;
        font-family: "Mon-medium", sans-serif;
      }
      .login-button-container {
        .login-button {
          background-color: $primary-bg-color;
          color: white;
          font-family: "Mon-medium", sans-serif;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 11.1rem;
          height: 4rem;
          border-radius: 0.8rem;
          border: none;
          font-size: 1.6rem;
          cursor: pointer;
        }

        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }

    .heading {
      display: flex;
      align-items: center;
      margin-bottom: 4rem;
      .reset-password-icon-container {
        height: 3.4rem;
        width: 3.4rem;
        background-color: #eaf4e3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 1.7rem;
      }
      .reset-password-heading {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 2.2rem;
        line-height: 2.7rem;
        color: #000000;
      }
    }

    .otpBoxes {
      margin-bottom: 0.8rem;
      .otp-line {
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-family: "Mon-medium", sans-serif;
        margin-bottom: 1rem;
        & + div {
          justify-content: center;
        }
      }

      .username-icon {
        margin-left: 1.425rem;
        margin-right: 1.225rem;
      }
      .inputStyling {
        width: 7.6rem !important;
        height: 7.6rem !important;
        margin-right: 0.8rem;
        background-color: transparent;
        border: 0.1rem solid #cdd4db;
        border-radius: 1.2rem;
        font-size: 3.6rem;
        color: #000000;

        &::placeholder {
          transform: translateY(-0.4rem);
        }
        &:focus {
          border: 0.1rem solid #007aff;
          box-shadow: 0 0 1rem rgba(0, 87, 255, 0.25);
        }
        &.error {
          border: 0.1rem solid #ff5757;
          box-shadow: 0 0.4rem 0.7rem 0.2rem rgba($black, 0.13);
        }
        &.verified {
          border: 0.1rem solid #4cbf07;
          box-shadow: 0 0.4rem 0.7rem 0.2rem rgba($black, 0.13);
        }
      }
    }
    .timeRemainingDiv {
      .timeRemainingSpan {
        font-family: "Mon-medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #8c8c8c;
        .seconds {
          font-family: "Mon-bold", sans-serif;
          color: black;
        }
      }
    }

    .error {
      color: #ff5757;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }

    .verified {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #4cbf07;
    }
    .reset-password-section {
      display: flex;
      flex-direction: column;
      .input {
        margin-left: 0;
      }
      .confirm-button {
        width: 12.7rem;
        height: 4rem;
        align-self: flex-end;
        cursor: pointer;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;

        &:disabled {
          opacity: 0.3;
        }
      }
    }

    .validations {
      margin-top: 5.8rem;
      li {
        list-style-image: url("../../shared/assets/images/validation-dot.svg");
        margin: 2rem 0 0 1rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: $light-black;

        span {
          position: relative;
          left: 1rem;
        }
      }

      .tick {
        list-style-image: url("../../shared/assets/images/validation-tick.svg");
      }
    }

    .button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-container {
        cursor: pointer;
        .resend-otp {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: $primary-bg-color;
          padding: 0;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .disabled {
        pointer-events: none;
        opacity: 0.5;
        color: rgba(255, 255, 255, 0.35);
      }
    }
  }
  .rightImageBlock {
    flex: 1;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: inherit;
    }
    .big-text {
      position: absolute;
      bottom: 8rem;
      z-index: 2;
      .line1 {
        font-family: "Mon-light";
        font-size: 3.4rem;
        line-height: 4.1rem;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
      .line2 {
        text-align: center;
        font-family: "Mon-bold";
        font-size: 6.3rem;
        line-height: 8.2rem;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
    }
  }
}
.another-username {
  font-family: "Mon-medium", sans-serif;
  font-size: 1.5rem;
  line-height: 1.7rem;
  padding: 1.5rem 0;
  &:hover {
    cursor: pointer;
  }
}

.otpScreenContainer.mobileView {
  display: block;

  .otpFormWrapper {
    justify-content: start;
    width: auto;
    margin: 0 1.6rem;

    .left-content {
      transform: none;
      width: 100%;
      max-width: 36rem;
      margin: 0.6rem auto 0;
      .otpBoxes {
        .reset-password-section {
          .text-input-field-container {
            .field-wrap {
              width: 100%;
              .input-field {
                font-size: 1.6rem;
                &::placeholder {
                  font-size: 1.4rem;
                }
              }
            }
          }
        }
      }
      .login-input-container {
        width: 100%;
        .input-field {
          font-size: 1.6rem;
          &::placeholder {
            font-size: 1.4rem;
          }
        }
      }
    }

    .otp-header {
      position: static;
      margin: 8.2rem 0 1rem;
      img {
        width: 4.8rem;
      }
    }

    .login-button-wrapper {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin-top: 4.8rem;
      margin-bottom: 8.6rem;
      .forgot-password {
        padding: 0.6rem;
      }
      .login-button-container {
        width: 100%;
        margin-bottom: 3rem;
        .login-button {
          width: 100%;
          height: 4.8rem;
        }
      }
    }

    .heading {
      margin-bottom: 2.4rem;
    }

    .otpBoxes {
      margin-bottom: 1.6rem;
      .otp-line {
        font-size: 1.6rem;
        margin-bottom: 1.8rem;
      }
      .inputStyling {
        width: 6.5rem !important;
        height: 6.5rem !important;
        margin-right: 1.5rem;
        box-sizing: border-box;
        border-radius: 0.4rem;
        @media (max-width: $xsMobile) {
          width: 5.5rem !important;
          height: 5.5rem !important;
        }
      }
    }
    .timeRemainingDiv {
      .timeRemainingSpan {
        color: $grey-text-color;
        font-size: 1.6rem;
        line-height: 2rem;
        letter-spacing: 0.08rem;
      }
    }
    .reset-password-section {
      .confirm-button {
        width: 100%;
        height: 4.8rem;
        margin-top: 2rem;
      }
    }

    .validations {
      margin-top: 3.4rem;
      margin-bottom: 3.4rem;
    }
  }
  .another-username {
    margin: 2.8rem auto;
  }
}
