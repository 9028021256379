#audio-player-modal {
  .modal-header {
    height: auto !important;
  }
  .modal-main {
    width: 39.3rem;
    border-radius: 0.8rem;
    padding: 2rem 1.5rem 1rem 1rem;

    .audio-player-section {
      height: 8rem;
      width: 90%;
      margin: auto;
      .rhap_progress-section {
        margin-left: 0;
      }
      .rhap_current-time,
      .rhap_time,
      .rhap_total-time {
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
      }
    }

    .audio-player-section,
    .rhap_container {
      background-color: white;
    }
  }
  .modal-body {
    height: auto !important;
  }
  .heading {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
    text-align: center;

    .right-section {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    .name {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.6rem;
      line-height: 20px;
      letter-spacing: 0rem;
      color: #000000;
      margin-bottom: 1rem;
      word-break: break-word;
      max-height: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 24rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .type,
    .date {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 17px;
      letter-spacing: 0em;
      color: #787878;
      margin-bottom: 1rem;
    }
  }
  .modal-footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
