// @import 'src/app/common/utils/scss/main.scss';
@import "src/shared/assets/scss/variables.scss";

.loginScreenWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100%;

  .leftLoginBlock {
    width: 40%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .logoHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo-image {
        height: 4rem;
        width: 4rem;
        scale: 2;
      }
    }
  }

  .rightImageBlock {
    width: 60%;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-image: url("../../shared/assets/images/login-screen-image.png");
    background-size: cover;

    .big-text {
      position: absolute;
      bottom: 8rem;
      z-index: 2;
      .line1 {
        font-family: "Mon-light";
        font-size: 3.4rem;
        line-height: 4.1rem;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
      .line2 {
        text-align: center;
        font-family: "Mon-bold";
        font-size: 6.3rem;
        line-height: 8.2rem;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
    }

    .login-screen-image {
      background-size: 100%;
    }
  }
}

.loginScreenWrapper.mobileView {
  .loginFormWrapper {
    @media only screen and (min-height: 580px) and (max-height: 1180px) and (max-width: 1024px) {
      position: absolute;
      top: 20%;
    }
  }
  .leftLoginBlock {
    width: 100%;

    .logoHeader {
      margin-top: 0;
      .logo-image {
        height: 4.8rem;
        width: 4.8rem;
        scale: 1.5;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .loginScreenWrapper.mobileView {
    height: 100% !important;
    .leftLoginBlock {
      width: 40%;
      .logoHeader {
        margin-top: 0;
        .logo-image {
          height: 4.8rem;
          width: 4.8rem;
        }
      }
      .parentContainer {
        display: block;
      }
    }

    .rightImageBlock {
      width: 60%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 865px) and (max-width: 915px) and (height: 1368px) {
  .loginScreenWrapper {
    .leftLoginBlock {
      .loginFormWrapper {
        scale: 0.85;
      }
    }
  }
  .loginScreenWrapper.mobileView {
    .leftLoginBlock {
      .loginFormWrapper {
        position: absolute;
        top: 20%;
        scale: 1.5;
      }
    }
  }
}

@media screen and (min-width: 2500px) and (min-height: 1000px) {
  .loginScreenWrapper {
    .leftLoginBlock {
      .loginFormWrapper {
        scale: 1.5;
      }
    }
  }
  .loginScreenWrapper.mobileView {
    .leftLoginBlock {
      .loginFormWrapper {
        scale: 1.5;
      }
    }
  }
}

@media only screen and (min-height: 1024px) and (max-height: 1180px) and (min-width: 768px) and (max-width: 1024px) {
  .loginScreenWrapper {
    .loginFormWrapper {
      scale: 0.75;
    }
  }
  .loginScreenWrapper.mobileView {
    .loginFormWrapper {
      scale: 1.25;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 330px) and (min-height: 568px) and (max-height: 659px) {
  .loginScreenWrapper.mobileView {
    .leftLoginBlock {
      .loginFormWrapper {
        scale: 0.85;
      }
    }
  }
}

@media only screen and (min-height: 375px) and (max-height: 640px) {
  .loginScreenWrapper.mobileView {
    .loginFormWrapper {
      scale: 0.75;
    }
  }
}

@media only screen and (min-height: 320px) and (max-height: 374px) {
  .loginScreenWrapper.mobileView {
    .loginFormWrapper {
      scale: 0.7;
    }
  }
}

.loginFormWrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  scale: 1;

  .login-form {
    display: flex;
    flex-direction: column;
    margin-top: 5.7rem;

    .login-field-wrapper {
      .fieldLabel {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.5rem;
        line-height: 130%;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.42);
        margin-bottom: 0.2rem;
        padding-left: 0.2rem;
      }

      .username-icon {
        margin-left: 1.425rem;
        margin-right: 1.225rem;
      }

      .login-input-container {
        width: 38rem !important;
      }
      .input-field {
        width: 100%;
        background-color: transparent;
        font-size: 1.4rem;
        line-height: 115%;
        font-family: "Mon-semi-bold";
        border-style: solid;
        border: none;
        &::placeholder {
          color: #7e7e7e;
        }
      }
    }

    .login-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .forgot-password {
        font-size: 1.6rem;
        line-height: 2rem;
        font-family: "Mon-medium", sans-serif;
      }
      .login-button-container {
        .login-button {
          background-color: $primary-bg-color;
          color: white;
          font-family: "Mon-medium", sans-serif;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 11.1rem;
          height: 4rem;
          border-radius: 0.8rem;
          border: none;
          font-size: 1.6rem;
          cursor: pointer;
        }

        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
}

.loginFormWrapperTransform {
  transform: translateX(-150%);
}

.mobileView {
  .loginFormWrapper {
    position: static;
    width: auto;
    height: auto;
    display: block;
    // margin: 3.6rem auto 0;
    max-width: 36rem;

    .login-form {
      .login-field-wrapper {
        transform: none;
        .login-input-container {
          width: 100% !important;
        }
        .input-field {
          font-size: 1.6rem;
          &::placeholder {
            font-size: 1.4rem;
          }
        }
      }

      .login-button-wrapper {
        flex-wrap: wrap;
        transform: none;
        .forgot-password {
          margin-left: auto;
          text-align: right;
        }
        .login-button-container {
          margin-top: 4.8rem;
          width: 100%;
          .login-button {
            width: 100%;
            height: 4.8rem;
          }
        }
      }
    }
  }

  .loginFormWrapperTransform {
    transform: none;
  }
}
