.loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Mon-medium", sans-serif;
}

.loading-container {
  height: calc(100vh - 31rem);
}

.notification-toast-progress-bar-color {
  background-color: #636d70;
}
