.footer-button-container {
    position: fixed;
    height: 7rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    background: #ffffff;
    box-shadow: 0 -0.7rem 1.2rem rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .footer-button-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      .save-button {
        width: 16rem;
        height: 4rem;
        font-family: "Mon-semi-bold", sans-serif;
        background: #1aa99c;
        border-radius: 0.8rem;
        border: none;
        color: white;
        cursor: pointer;
        &:disabled {
          background: #a3ddd7;
          cursor: not-allowed;
        }
      }
    }
}