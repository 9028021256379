.table-with-pagination-container {
  height: 100%;
  .table-with-pagination {
    height: 100%;
    &-header {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      background: #f1faf9;
      border-radius: 0.8rem 0.8rem 0 0;
      box-shadow: 0 0 0 0.1rem #bcdfef;
      color: #333;
      z-index: 1;
      .column {
        display: flex;
        box-sizing: border-box;
        // padding: 1.7rem 0 1.8rem 2.2rem;
        align-items: center;
        word-break: break-word;
        .column-content {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          .content {
            font-family: "Mon-bold", sans-serif;
            font-size: 14px;
          }
          &.disabled {
            cursor: not-allowed !important;
          }
          &.cursor-pointer {
            cursor: pointer;
          }
          .sort-icon {
            &:hover {
              cursor: pointer;
              background: #f1faf9;
              border-radius: 8px;
            }
          }
        }
      }
    }
    &-body {
      height: calc(100% - 120px);
      background-color: #fff;
      box-sizing: border-box;
      display: block;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      border: 1px solid #eef0f6;
      // border-radius: 8px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0rem;
      #scroll {
        margin-top: 0;
      }

      .table-with-pagination-row {
        display: flex;
        flex-direction: row;
        box-shadow: 0 1px 0 0 #eef0f6;
        position: relative;
        &.disabled {
          opacity: 0.4;
          &:hover {
            cursor: no-drop;
            background: none;
          }
        }
        &:hover {
          background: #eeeeee;
        }
        .column {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          // padding: 1.7rem 0 1.8rem 2.2rem;
          word-break: break-word;

          .content {
            display: flex;
            align-items: center;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
          }
        }
        &:hover {
          background: #eeeeee;
        }
      }
      .empty-message-container {
        height: 100%;
        width: 100%;
      }
    }
    .table-with-pagination-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 6.8rem;
      padding: 1.6rem;
      background-color: white;
      box-sizing: border-box;
      border-bottom-right-radius: 0.8rem;

      border-bottom-left-radius: 0.8rem;
      .page-button-section {
        .page-button,
        .filler {
          background: #fff;
          cursor: pointer;
          font-family: "Mon-medium", sans-serif;
          border: none;
          color: #333;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0px 6px;
          width: 30px;
          height: 30px;
        }
        .active {
          border-radius: 8px;
          border: 1px solid #c3e3f0;
          background: #fff;
        }
        .filler {
          cursor: auto;
        }
      }
      .disabled {
        opacity: 0.3;
      }
      .previous-button,
      .next-button {
        height: 3.6rem;
        padding: 0 1.2rem;
        background: #fff;
        border: 1px solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        cursor: pointer;
        font-family: "Mon-medium", sans-serif;
        &:disabled {
          cursor: no-drop;
        }
      }
    }
  }
}
