.section-container {
  margin-bottom: 3.2rem;
  .section-heading {
    font-family: "Mon-bold", sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 3.2rem;
    color: #00887c;
  }
}
