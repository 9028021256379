.episode-details-container-with-patient-declined-alert {
  .procedure-details-parent-container {
    .loader {
      height: calc(100vh - 47rem);
    }
    .input-form-container {
      max-height: calc(100vh - 49rem);
    }
  }
}
.procedure-details-parent-container {
  .loader {
    height: calc(100vh - 44rem);
  }
  .input-form-container {
    max-height: calc(100vh - 46rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .input-grid-container {
    max-width: 111.6rem;
    display: grid;
    grid-template-columns: repeat(3, 30rem);
    grid-column-gap: 10.8rem;
    grid-row-gap: 0.8rem;
    .input-row-container {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .first-row {
      margin-top: 2.5rem;
    }
    .input-with-no-error {
      height: 2.2rem;
    }

    .input-container {
      padding-left: 4px;
      .input-wrapper {
        .label {
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
          margin-top: 0px;
          margin-bottom: 6px;
        }
        .dropdown-label {
          margin-bottom: 6px;
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
        }
        .text-input-field-container {
          margin-bottom: 0;
          .field-title {
            margin-top: 0px;
          }
          .field-wrap {
            opacity: 0.8;
            width: auto !important;
            height: 40px;
            flex-direction: column;
            display: inline;
          }
        }
      }
    }
    .text-area-container {
      grid-column: 1 / span 3;
      display: block;
      .label-container {
        width: 60%;
      }
      textarea {
        width: 58.8%;
      }
    }
    .textarea-input-group-section {
      width: 100%;
    }
    .tab-section {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .content-area {
      display: grid;
      grid-template-columns: 60% 40%;
      height: 100%;

      .preview-container {
        height: 85%;
        overflow: auto;
      }
      .patient-personal-preview-container {
        height: 75%;
        overflow: auto;
      }
    }
    .patient-input-fields-section {
      height: calc(100vh - 18rem);
      overflow-y: auto;
    }
    .input-fields-section {
      margin-top: 13px;
      height: calc(100vh - 18rem);
      overflow-y: auto;
      #date-picker-container {
        top: -10px !important;
        left: 248px !important;
        right: none !important;
      }
      .input-container {
        .dropdown-container {
          .select-span {
            opacity: 0.8;
          }

          .surgeon-admitting-physician-dropdown,
          .surgeon-practice-dropdown,
          .program-dropdown,
          .episode-dropdown,
          .surgery-site-side-dropdown,
          .acute-care-facility-dropdown,
          .patient-episode-status-dropdown,
          .gender-dropdown .state-dropdown {
            max-height: 225px;
            overflow: auto;
            width: 100%;
          }

          .option-box-wrapper {
            width: 100%;
          }
        }
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
      .date-picker-input {
        width: 100% !important;
        box-sizing: border-box !important;
      }
    }

    .patient-personal-details-section {
      .emergency-contact-name {
        margin-top: 0px;
      }
      .phone-number-container.emergency-contact-number {
        margin-bottom: 0px;
      }
      .preffered-number-container {
        .phone-number-code {
          height: 40px;
          width: 48px;
          margin-right: 0px;
        }
        .phone-number-input {
          height: 40px;
          width: 100%;
          padding-right: 0px;
          min-width: 0px;
          .field-wrap {
            width: 100%;
            height: 40px;
          }
        }
      }
      .phone-number-container {
        margin-top: 0px;
        .phone-number-code {
          height: 40px;
          width: 48px;
          margin-right: 0px;
        }
        .phone-number-input {
          width: 100%;
          padding-right: 0px;
          min-width: 0px;
          .field-wrap {
            height: 40px;
            width: 100%;
          }
        }
        .phone-number-inputs {
          align-items: start;
        }
      }
    }
    .red-color-asterisk {
      color: red;
    }
    .patient-episode-status-input-disabled {
      font-family: "Mon-medium";
    }
  }
}
