@import "../../shared/mixins/mixins.styles";
.days {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #667a78;
  border-radius: 12px;
  width: 18px;
  height: 18px;
  @include fontStyle("Mon-semi-bold", 10px, 12px, #667a78);
}
