@import "../../shared/assets/scss/variables.scss";

#date-picker-selector {
  position: relative;
  display: inline-flex;
  z-index: 3;
  .calender-icon {
    cursor: pointer;
    position: absolute;
    top: 33%;
    right: 6.6%;
  }
  .date-picker-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.4rem 1.6rem 1rem 1.2rem;
    position: static;
    width: 22.7rem;
    background: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
    border: 0.1rem solid #1aa99c;
    border-radius: 0.8rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    outline: #1aa99c;
    font-family: "Mon-medium", sans-serif;
  }
  .date-picker-input::placeholder {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #333333;
  }
  #date-picker-container {
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    background: transparent;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 1rem;
    top: 3.2rem;
    right: -1rem;
    z-index: 1;
    .date-picker-inner-container {
      display: inline-flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .date-picker-footer {
      position: relative;
      top: -6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;

      &.buttons-with-no-right-section {
        .apply-button,
        .cancel-button {
          font-family: "Mon-medium", sans-serif;
          padding: 1rem;
          width: 12.2rem;
          height: 4rem;
          font-size: 1.6rem;
          cursor: pointer;
        }
        .apply-button {
          margin-left: 1rem;
        }
      }

      &.buttons-with-right-section {
        .apply-button,
        .cancel-button {
          font-family: "Mon-medium", sans-serif;
          padding: 1rem;
          transform: translateX(-10.5rem);
          width: 12.2rem;
          height: 4rem;
          font-size: 1.6rem;
          cursor: pointer;
        }

        .apply-button {
          margin-left: 1rem;
        }
      }
    }

    .date-picker {
      &.no-right-section {
        .rdrDefinedRangesWrapper {
          display: none;
        }
      }
      flex-direction: row-reverse;
      border: 0.1rem solid rgba(51, 51, 51, 0.2);
      border-radius: 0.8rem;
      box-sizing: border-box;
      background: #ffffff;
      padding: 0.1rem 0 0.1rem 0.2rem;
      height: 43.7rem;

      .rdrCalendarWrapper {
        position: relative;
        padding: 2.9rem 0 4rem 0;
      }
      .rdrDateDisplayWrapper {
        background-color: white;
      }
      .rdrDateDisplayItem {
        border-radius: 0;
        background-color: white;
        box-shadow: none;
        border: none;
      }
      .rdrNextPrevButton {
        position: absolute;
        top: 2.4rem;
        background: transparent;

        i {
          display: none;
        }
      }
      .rdrMonthAndYearPickers {
        .rdrMonthPicker {
          select {
            font-family: "Mon-medium", sans-serif;
          }
        }
      }
      .rdrPprevButton {
        background-image: url("../../shared/assets/images/left-arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
      .rdrNextButton {
        right: 0.9rem;
        background-image: url("../../shared/assets/images/right-arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
      .rdrDateInput {
        display: none;
      }
      .rdrWeekDays {
        margin-bottom: 1.136rem;
        .rdrWeekDay {
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 1.2rem;
          line-height: 1.5rem;
          color: #6b727f;
        }
      }
      .rdrDays {
        .rdrDayWeekend {
          font-family: "Mon-semi-bold", sans-serif;
          color: #6b727f;
          mix-blend-mode: normal;
          opacity: 0.4;
        }
        .rdrDay {
          height: 4.3rem;
          .rdrDayNumber {
            span {
              font-family: "Mon-semi-bold", sans-serif;
              font-size: 1.6rem;
              line-height: 2rem;
              color: #6b727f;
            }
          }
        }
      }
      .rdrMonths {
        .rdrMonth {
          padding: 0 1.4em 1.666em 0.833em;
          .rdrMonthName {
            position: absolute;
            top: 0;
            margin-top: 1.3rem;
            margin-left: 13.5rem;
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 1.6rem;
            line-height: 2rem;
            color: #6b727f;
          }
        }
      }
    }

    .rdrDefinedRangesWrapper {
      font-family: "Mon-medium", sans-serif;
      border-left: 0.1rem solid #c4c4c4;
      padding-top: 1.7rem;
      width: 20.3rem;
      .rdrStaticRanges {
        .rdrStaticRange {
          border-bottom: none;
          .rdrStaticRangeLabel {
            font-family: "Mon-medium", sans-serif;
            padding: 1rem 2.8rem 1.6rem 3.3rem;
            font-size: 1.4rem;
            line-height: 1.7rem;
          }
          &:last-child {
            width: 18.2rem;
            height: 4rem;
            background: white;
            border: 0.1rem solid rgba(51, 51, 51, 0.2);
            box-sizing: border-box;
            border-radius: 0 0.8rem 0.8rem 0;
            border-left: none;
            position: relative;
            left: -0.1rem;
            z-index: 2;
            margin-top: 2.4rem;

            .rdrStaticRangeLabel {
              font-family: "Mon-bold", sans-serif;
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #1aa99c;
            }
            .rdrStaticRangeLabel:hover {
              background: transparent;
            }
          }
        }
      }
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
      color: rgba(255, 255, 255, 0.85) !important;
    }
  }
}

.mobileView {
  #date-picker-selector {
    width: 100%;
    .date-picker-input {
      padding: 1.2rem;
      font-size: 1.6rem;
      box-sizing: border-box;
      width: 100%;
    }
  }

  .home-page-datepicker {
    #date-picker-selector {
      width: 114%;
      .calender-icon {
        z-index: 2;
        top: 1.2rem;
        left: 1.2rem;
        right: auto;
        path {
          color: $grey-text-color;
        }
      }
      .date-picker-input {
        color: $grey-4;
        z-index: 2;
        font-size: 1.6rem;
        line-height: 1.7;
        padding: 0.8rem 1.2rem 0.8rem 4.2rem;
        transform: scale(0.875);
        transform-origin: left top;
        margin-bottom: -1.1rem;
      }
      #date-picker-container {
        z-index: 5;
        position: fixed;
        left: 0;
        right: 0;
        top: 8rem;
        height: calc(100% - 8rem);
        bottom: 0;
        margin-top: 0;
        .date-picker-inner-container {
          justify-content: space-between;
          overflow-y: auto;
          height: 100%;
        }
        &:before {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: $white;
          z-index: -1;
        }

        .date-picker-footer {
          top: auto;
          justify-content: flex-end;
          margin-bottom: 2rem;

          &.buttons-with-right-section {
            .apply-button,
            .cancel-button {
              transform: none;
              width: 50%;
            }
          }
        }

        .date-picker {
          height: auto;
          flex-wrap: wrap;
          border: none;

          .rdrCalendarWrapper {
            padding-bottom: 2rem;
          }
          .rdrMonthAndYearWrapper {
            height: auto;
          }
          .rdrMonthAndYearPickers {
            position: absolute;
            top: 2rem;
            left: 50%;
            right: 50%;
          }
          .rdrDays {
            .rdrDay {
              height: 4.8rem;
            }
          }
          .rdrMonths {
            flex-direction: column;
            .rdrMonth {
              width: 95vw;
              padding-left: 0;
              padding-right: 0;
              .rdrMonthName {
                position: static;
                margin: 0;
                text-align: center;
              }
            }
          }
        }

        .rdrDefinedRangesWrapper {
          width: 100%;
          border: none;
          padding: 0;
          .rdrStaticRanges {
            display: block;
            .rdrStaticRange {
              width: 50%;
              display: inline-block;
              .rdrStaticRangeLabel {
                text-align: center;
              }
              &:not(:last-child) {
                .rdrStaticRangeLabel {
                  color: $grey-text-color;
                }
              }
              &:last-child {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                border: 0.1rem solid rgba(51, 51, 51, 0.2);
                border-radius: 0.8rem;
              }
            }
          }
        }
      }
    }
  }

  #profile-container {
    #date-picker-selector {
      .calender-icon {
        right: 2.1rem;
      }
      .date-picker-input {
        min-height: 4.8rem;
      }
      #date-picker-container {
        .date-picker {
          height: 50rem;
          .rdrPprevButton,
          .rdrNextButton {
            z-index: 1;
          }
          .rdrCalendarWrapper {
            width: 100%;
          }
          .rdrDays {
            .rdrDay {
              height: 4.8rem;
            }
          }
          .rdrMonths {
            .rdrMonth {
              width: 100vw;
              .rdrMonthName {
                margin-left: 0;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
