@import "../../shared/assets/scss/variables.scss";

.radio-input-group-section {
  display: flex;
  flex-direction: column;
  .radio-group-label {
    font-family: "Mon-medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    .red-color-asterisk {
      color: red;
    }
  }
  .input-section {
    display: flex;
    flex-direction: row;
    margin-top: 0.2rem;
    .single-radio {
      margin-right: 2.9rem;
      .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        margin-bottom: 1.2rem;
        cursor: pointer;
        color: #7e7e7e;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        input {
          cursor: pointer;
          appearance: none;
          height: 1.8rem;
          width: 1.8rem;
          border: 0.2rem solid #656565;
          border-radius: 50%;
          margin: 0rem 1rem 0rem  0rem ;
          &:checked {
            background-image: url("../../shared/assets/images/radio-input-checked.svg");
            background-repeat: no-repeat;
            background-position: center center;
          }&:disabled{
            opacity: 0.4
          }
        }
        .disable-radio-button{
          cursor: not-allowed;
        }
      }
      .disable-radio-button{
        cursor: not-allowed;
      }
    }

  }
  .error-wrap {
    color: #ff5757;
    font-family: "Mon-medium";
    font-size: 1.2rem;
    margin: 0rem  0rem ;
  }
}
