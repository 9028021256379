@import "../../shared/assets/scss/variables.scss";

.phone-input-with-dash-field-container {
  margin-bottom: 3rem;
  .red-color-asterisk {
    color: red;
  }
  .error-wrap {
    color: $red-notification-color;
    display: flex;
    align-items: center;
    margin-top: 0.6rem;
    font-size: 1.2rem;
    font-family: "Mon-medium",sans-serif;
    white-space: pre-wrap;
    .error-message-icon {
      margin-right: 0.65rem;
    }
  }
  .field-wrap {
    position: relative;
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
    .input-field {
      color: $grey-text-color;
      padding: 1.15rem 1.2rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
      background: #ffffff;
      mix-blend-mode: normal;
      box-sizing: border-box;
      border-radius: 0.8rem;
      border: 0.1rem solid $primary-input-border;
      margin-left: 0;
      font-family: "Mon-medium",sans-serif;
      white-space: nowrap;
      &::placeholder {
        color: $grey-text-color;
        opacity: 0.3;
      }
    }
    .phone-number {
      width: 5rem !important;
      margin-right: 1rem;
      border: 0.1rem solid $primary-input-border;
    }
    .blue-border {
      border: 0.1rem solid $primary-input-border;
    }
    .red-border {
      border: 0.1rem solid $red-notification-color;
    }
  }
  &:last-child {
    margin-bottom: 2.9rem;
    @media (max-width: $mobile) {
      margin-bottom: 1.5rem;
    }
  }
  .field-title {
    color: $grey-text-color;
    text-transform: capitalize;
    margin-bottom: 0.7rem;
    display: block;
    font-family: "Mon-medium",sans-serif;
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin: 0.6rem 0;
  }
  &.password-field {
    margin-bottom: 2.5rem;
    & input {
      -webkit-text-security: disc;
    }
  }
}

.mobileView {
  .text-input-field-container {
    .field-wrap {
      .input-field {
        font-size: 1.6rem;
        &::placeholder {
          font-size: 1.4rem;
        }
      }
    }
  }
}
