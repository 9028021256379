.active-tab-with-bottomline-container {
  display: flex;
  .active-tab-with-bottomline-tabs {
    display: flex;
    gap: 3.6rem;
    .allowed {
      cursor: pointer !important;
    }
    .tabs {
      color: #979797;
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 1.6rem;
      line-height: normal;
      display: flex;
      height: 40px;
      align-items: center;
      background-color: #fff;
    }

    .active {
      color: #00887c;
      border-bottom: 2px solid #00887c;
    }
  }
}
