.common-note-container {
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem 0.78rem 1rem;
  background: rgba(255, 245, 190, 0.6);
  word-break: break-word;
  border: 0.1rem solid rgba(255, 122, 0, 0.1);
  border-radius: 0.4rem;
  flex: none;
  order: 2;
  flex-grow: 0;
  max-width: fit-content;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #333333;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  .reminder-note-icon,
  .alert-note-icon {
    display: flex;
    margin-right: 0.85rem;
  }

  .alert-note-container {
    .alert-tag {
      font-family: "Mon-semi-bold", sans-serif;
      font-weight: bold;
    }
  }
}
