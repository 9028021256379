@import "../../shared/assets/scss/variables.scss";
#single-date-picker-selector {
  position: relative;
  .date-picker-container {
    position: relative;
    display: inline-block;
    z-index: 2;
  }
  .red-color {
    font-family: "Mon-medium", sans-serif;
    color: #ff5757;
    font-size: 1.2rem;
    margin: 0.6rem 0;
    mix-blend-mode: normal;
    position: static;
    outline: $primary-input-border;
  }
  .calender-icon {
    display: block !important;
    position: absolute;
    right: 1rem;
    top: 2.3rem;
    transform: translateY(-50%);
    &:hover {
      cursor: pointer;
    }
  }
  .react-datepicker__close-icon {
    right: 5px;
  }
  .date-picker-input {
    padding: 1.4rem 1.6rem 1rem 1.2rem;
    border: 0.1rem solid $primary-input-border;
    flex: none;
    opacity: 0.8;
    border-radius: 0.8rem;
    &.red-border {
      border: 0.1rem solid red;
    }
  }
  &.disabled-date-selector {
    cursor: not-allowed;
    .react-datepicker__close-icon {
      display: none;
    }
  }
  .date-picker-input:disabled {
    opacity: 0.4;
  }
  .date-picker-input::placeholder {
    font-family: "Mon-medium";
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: $placeholder-text-color;
    font-weight: 400 !important;
  }

  .react-datepicker__close-icon {
    right: 5px;
  }

  .date-picker-calender {
    font-family: "Mon-semi-bold", sans-serif;
    padding: 2.2rem 0 0 0;
    border: 0.1rem solid rgba(51, 51, 51, 0.2);
    border-radius: 0.8rem;
    .react-datepicker__day-names {
      position: relative;
      padding: 0 3px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .react-datepicker__header {
      background-color: #ffffff;
      border: none;

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        font-size: 1.6rem;
        color: #6b727f;
      }
    }

    .react-datepicker__day--disabled {
      opacity: 0.4 !important;
      cursor: not-allowed;
    }
    .react-datepicker__day--weekend {
    }

    .react-datepicker__navigation {
      top: 2.1rem;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: #ffffff;
    }
  }

  .react-datepicker__navigation-icon::before {
    border-color: #4f4f4f;
    border-width: 0.2rem 0.2rem 0 0;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 2.7rem;
  }
  .date-picker-day {
    font-family: "Mon-semi-bold", sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #6b727f;
    width: 4.8rem;
    line-height: 4.6rem;
    margin: auto;

    &.react-datepicker__day--selected {
      background-color: #00887c;
      color: #ffffff;
    }
  }
  .date-picker-week-day {
    font-family: "Mon-semi-bold", sans-serif;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #6b727f;
    margin-top: 2.9rem;
  }
}
