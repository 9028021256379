@import "src/shared/assets/scss/variables.scss";

.app-routes {
  display: flex;
  height: 100%;

  .app-main-content {
    flex: 1;
    padding: 2.5rem 3.2rem 2.5rem 3.2rem;
    overflow: hidden;
    background-color: $white-1;
  }
  &.mobileView {
    flex-direction: column-reverse;
    .app-main-content {
      padding: 1.6rem;
      background-color: $white;
      overflow: auto;
    }
  }
}

#modal {
  .online-offline-modal {
    text-align: center;
    .save-risk-assessment-container {
      width: 50rem;
    }
  }
  .mobileView {
    &.online-offline-modal {
      background: rgba($black, 0.6);
      .save-risk-assessment-container {
        width: 32rem;
        .header {
          padding-bottom: 2.7rem;
        }
        .content {
          text-align: left;
          padding: 0 1.6rem;
        }
        .button-section {
          width: calc(100% - 3.2rem);
          padding: 0 1.6rem;
          .white-button {
            margin-right: 1.2rem;
          }
          .green-button,
          .white-button {
            width: 50%;
            height: 4.8rem;
          }
        }
      }
    }
  }
}
