@import "shared/assets/scss/variables.scss";

.notification-icon-container-mobile {
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 8px;
  display: flex;
  height: 4rem;
  justify-content: center;
  margin-right: 1.25rem;
  position: relative;
  width: 4rem;

  .notification-icon {
    cursor: pointer;
    animation: animTab 0.3s ease-in-out forwards;
    color: #000;
  }

  .notification-count-badge {
    align-items: center;
    background-color: rgba(255, 0, 0, 0.78);
    border: 1px solid #fff;
    border-radius: 2rem;
    color: #fff;
    display: flex;
    font-family: Mon-bold, sans-serif;
    font-size: 1rem;
    font-style: normal;
    justify-content: center;
    left: 2.5rem;
    min-height: 2rem;
    min-width: 1.2rem;
    padding: 0.2rem 0.4em;
    position: absolute;
    text-align: center;
    top: -1rem;
    z-index: 4;
  }
}
