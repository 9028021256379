.task-container-tab {
  overflow: auto;
  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.footer-not-present {
    height: calc(100vh - 22rem);
  }
  &.footer-present {
    height: calc(100vh - 28rem);
  }
  .task-info-patient-decline-alert {
    margin-bottom: 2.4rem;
  }
  &-info {
    display: grid;
    grid-template-columns: 37% 1fr;
    row-gap: 0.8rem;

    &.footer-not-present {
      max-height: 92%;
    }

    &.footer-present-open {
      max-height: 40vh;
    }

    &.footer-present-not-open {
      max-height: 65vh !important;
    }

    &.footer-not-present-open {
      max-height: 55vh;
    }

    &.footer-not-present-not-open {
      max-height: 75vh;
    }

    .title {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.2rem;
      line-height: 22px;
      color: #667a78;
      justify-self: flex-end;
      margin-right: 1.6rem;
    }
    .value {
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      line-height: 22px;
      color: #333333;
      word-break: break-word;

      .risk-level,
      .off-track {
        color: #f76659;
        font-family: "Mon-bold", sans-serif;
      }

      .risk-level {
        .risk-when-unplanned {
          color: #333333;
          font-family: "Mon-medium", sans-serif;
        }
      }
    }
  }
}
