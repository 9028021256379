#episode-details-container {
  .episode-tasks-table {
    min-width: 122.2rem;
    max-width: 96vw;
    &.episode-tasks-table-with-owner-column {
      .disabled {
        opacity: 0.4;
        &:hover {
          cursor: not-allowed;
          background: none;
        }
      }
    }
    &-header {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      background: #f1faf9;
      border-radius: 0.8rem 0.8rem 0 0;
      box-shadow: 0 0 0 0.1rem #bcdfef;
      color: #333;
      z-index: 2;
      &-scrollbar-visible {
        .column:last-child {
          min-width: 11.5rem !important;
          width: 11.5rem !important;
        }
      }
      .column {
        display: flex;
        box-sizing: border-box;
        padding: 1.7rem 0 1.8rem 2.2rem;
        align-items: center;
        width: 16%;
        word-break: break-word;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5) {
          width: 12rem;
          min-width: 12rem;
        }
        &:nth-child(4) {
          min-width: 19.1rem;
          width: 19.1rem;
        }
        &:nth-child(3) {
          width: 100%;
          min-width: 19.5rem;
        }

        &:nth-child(6) {
          width: 17rem;
          min-width: 17rem;
        }
        &:nth-child(7) {
          min-width: 17rem;
          width: 17rem;
        }
        &:last-child {
          width: 10rem;
          min-width: 10rem;
        }
        .column-content {
          display: flex;
          align-items: center;
          &-cursor-pointer {
            cursor: pointer;
          }
          &-disable-cursor {
            cursor: not-allowed;
          }
          .content {
            margin-right: 8px;
            font-family: Mon-bold, sans-serif;
            font-size: 1.4rem;
          }
        }
      }
    }
    &-body {
      background-color: #fff;
      box-sizing: border-box;
      display: block;
      height: calc(100vh - 44rem);
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      border: 1px solid #eef0f6;
      border-radius: 8px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      & > .infinite-scroll-component__outerdiv > .infinite-scroll-component {
        overflow: overlay !important;
      }
      .episode-listing-row {
        display: flex;
        flex-direction: row;
        box-shadow: 0 0.1rem 0 0 #eef0f6;
        opacity: 1;
        &.no-cursor-for-show-edit {
          .buttons {
            .custom-tooltip {
              padding: 0.6rem 1.6rem !important;
            }
          }
        }
        &.no-cursor {
          .buttons {
            .custom-tooltip {
              padding: 0.6rem 1.6rem !important;
            }
          }
          &:hover {
            cursor: not-allowed;
          }
        }
        .stricked-row {
          text-decoration: line-through;
          line-height: 300%;
          &.disabled {
            opacity: 0.4;
          }
        }
        &.disabled-row {
          opacity: 0.4;
          &:hover {
            cursor: not-allowed;
            background: none;
          }
        }
        &:hover {
          cursor: pointer;
          background: #eeeeee;
        }
        .column {
          box-sizing: border-box;
          word-break: break-word;
          padding: 1.7rem 0 1.8rem 2.2rem;
          display: flex;
          align-items: center;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(5) {
            width: 12rem;
            min-width: 12rem;
          }
          &:nth-child(4) {
            min-width: 19.1rem;
            width: 19.1rem;
          }
          &:nth-child(3) {
            width: 100%;
            min-width: 19.5rem;
          }

          &:nth-child(6) {
            width: 17rem;
            min-width: 17rem;
          }
          &:nth-child(7) {
            min-width: 17rem;
            width: 17rem;
          }
          &:last-child {
            width: 10rem;
            min-width: 10rem;
            padding-right: 0.8rem;
            .content {
              display: flex;
              justify-content: end;
              align-items: center;
              gap: 0.5rem;
            }
            .edit-icon-container {
              min-width: 3.2rem;
              height: 3.2rem;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background-color: #ffffff;
            }
            .edit-icon-tooltip {
              display: flex;
              padding-bottom: 0.2rem;
            }
          }
          .content {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            width: 100%;
            line-height: 1.7rem;
            .note-for-tasks {
              cursor: pointer;
              &:hover {
                cursor: pointer;
              }
            }
            .note {
              width: 3.2rem;
              height: 3.2rem;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              cursor: pointer;
              &:hover {
                cursor: pointer;
              }
            }
            .status-type,
            .service-details,
            .outdated-status,
            .reminder-title,
            .waiting-approval,
            .rejected-approval {
              width: fit-content;
              font-size: 1.2rem;
              font-family: "Mon-medium", sans-serif;
              margin-top: 0.5rem;
              color: rgba(0, 0, 0, 0.4);
              font-weight: 500;
              line-height: 14.63px;
            }
            .waiting-approval {
              color: #00887c;
            }
            .outdated-status,
            .rejected-approval {
              color: #f76659;
            }
            .service-details,
            .reminder-title {
              max-width: 100%;
              width: fit-content;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &.status {
            .content {
              flex-direction: column;
              align-items: unset;
              justify-content: center;
              &:first-child {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}
