.compose-message-popup-container {
  .modal-footer {
    .create-conversation-button {
      width: -webkit-fill-available;
      padding: 1rem;
      margin: 0 2rem 2rem 2rem;
      margin-top: 70%;
    }
  }
  .row {
    margin: 1.6rem 0rem 1.6rem 0rem;
    &.disabled-row {
      opacity: 0.5;
      pointer-events: none;
    }
    .recipient-text {
      color: #333;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      display: flex;
      padding: 15px 0px 0px 0px;
      justify-content: flex-end;
    }
    #subject-box {
      min-width: 30rem !important;
      max-width: 30rem;
    }
    .single-select-dropdown-with-search {
      margin: 0;
      .select__control {
        border: 0.1rem solid #acc0bb;
        &:focus {
          border: 0.1rem solid #acc0bb !important;
        }
        &:hover {
          border: 0.1rem solid #acc0bb !important;
        }
      }
    }
    .tag-search-dropdown-container {
      width: auto;
      .select-span {
        padding: 0;
      }
      .no-pointer-events {
        opacity: 1;
      }
    }
    .dropdown-container {
      .option {
        font-size: 1.2rem;
      }
      .navigator-box,
      .subject-box {
        .no-result-found {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          padding: 1rem;
        }
      }
      .search-box {
        width: 100%;
        margin: 0;
      }
    }
    label {
      margin-bottom: 0.4rem;
      color: #333;
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 1.2rem;
      line-height: 100%;
      display: block;
    }

    .recipient-and-patient {
      display: grid;
      grid-template-columns: 15% 1fr;
      margin-bottom: 1.6rem;
      .recipient-dropwdown-container {
        margin-left: 1rem;
        .selected-text {
          color: #636d70;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          margin-top: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          span {
            color: #636d70;
            font-family: "Mon-bold", sans-serif;
            font-size: 1.2rem;
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
      .tag-search-dropdown-container {
        .option-box-wrapper {
          width: 100%;
        }
        .search-lens-icon {
          width: 18px !important;
          transform: translateY(1px);
        }
      }
    }

    .patient-checkbox {
      .checkbox-label {
        color: #333;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        line-height: 100%;
        display: grid;
        grid-template-columns: 15% 1fr;
        text-align: right;
        align-items: center;

        &.disabled {
          cursor: not-allowed;
        }

        #patient {
          border-radius: 0.2rem;
          border: 0.1rem solid #bcdfef;
          width: 1.6rem;
          height: 1.6rem;
          display: flex;
          flex-grow: 0;
          flex-shrink: 0;
          grid-column: 2;
          grid-row: 1;
          margin-left: 1rem;
          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }

    .note {
      color: #636d70;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      font-style: italic;
      line-height: 100%;
    }
  }
}
