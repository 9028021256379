@import "../../shared/assets/scss/variables.scss";

.common-state-container,
.empty-state-container,
.access-denied-container {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader {
  margin: auto;

  img {
    width: 10rem;
  }
}

.button-text {
  font-family: "Mon-bold", sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: rgba(51, 51, 51, 0.8);
  margin-top: 2.1rem;
  margin-bottom: 3rem;
}

.no-data-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .no-data-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading,
    .text {
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-size: 1.4rem;
    }
    .text {
      font-family: "Mon-medium", sans-serif;
      text-align: center;
      margin-top: 0.7rem;
    }
    .heading {
      font-family: "Mon-bold", sans-serif;
    }
    .button {
      .back-button {
        cursor: pointer;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        border-radius: 0.8rem;
        padding: 1rem 1.6rem;
        transition: all 0.2s;
        background: #1aa99c;
        border-radius: 0.8rem;
        border: none;
        color: white;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

.no-task-container {
  flex-direction: column;
  .no-task-message {
    font-size: 1.4rem;
    align-self: center;
    font-family: "Mon-medium", sans-serif;
    margin-top: 1rem;
  }
  .green-button {
    margin-top: 1.6rem;
    border-radius: 0.8rem;
    background: #1aa99c;
    padding: 1rem 1.6rem;
    outline: none;
    border: none;
    color: #fff;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.6rem;
    cursor: pointer;
    height: 4.2rem;
  }
}

.plan-of-care-not-found {
  .add-service {
    margin-top: 1.6rem;
    padding: 1rem 1.6rem;
    outline: none;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.6rem;
    cursor: pointer;
    height: 4.2rem;
  }
}
