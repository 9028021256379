.poc-details-row {
  display: flex;
  .silver-color-text {
    color: #999;
    font-family: "Mon-semi-bold", sans-serif;
  }
  .name {
    min-width: 16.2rem;
    width: 16.2rem;
    color: #999;
    font-family: "Mon-semi-bold", sans-serif;
  }
}
