@import "../../shared/assets/scss/variables.scss";

#contact-container {
  .contact-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 4.55rem;
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .right-section {
      display: flex;
      align-items: center;
      .toggleContainer {
        margin-top: 0;
        .toggleTitle {
          margin: 0;
        }
        .switch {
          margin: 0 1.2rem;
        }
      }
      .dropdown-container {
        width: auto;
        margin: 0 2.2rem 0 0;
        .select-span {
          margin-left: 0;
          margin-right: 0;
          &:not(:empty) {
            min-width: 22rem;
          }
        }
        .name-container {
          margin-right: 1rem;
        }
        .option-box-wrapper {
          margin-left: 0;
          margin-right: 0;
        }
      }
      .search-icon-container,
      .filter-icon-container {
        width: 4rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2.6rem;
      }
      .filter-icon-container {
        margin: 0 2.6rem;
      }
    }
  }
}

.mobileView {
  #contact-container {
    .contact-header {
      flex-direction: column;
      align-items: end;
      margin-bottom: 1.6rem;
      .header-title {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
