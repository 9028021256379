#patient-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .patient-status-container {
    padding: 0 0 0 1.6rem;
    background: #f5faf9;
    border-radius: 0.8rem;
    display: flex;
    width: fit-content;
    position: relative;
    border: 0.1rem solid #bcdfef;
    &-title {
      padding: 0rem 1.6rem 0rem 0;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #00887c;
      border-right: 0.1rem solid #bcdfef;
      display: flex;
      align-items: center;
    }
    &-value {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 0 0.45rem 0 0;
      border-radius: 0.8rem;
      .status-value {
        border: none;
        padding-left: 1rem;
        padding-right: 2.2rem;
        color: #333;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .icon-container,
      .icon-container-disabled {
        background: #1aa99c;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.3rem;
        height: 3.1rem;
        margin: 0.45rem 0 0.45rem 0;
        cursor: pointer;
      }
      .icon-container-edit {
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.3rem;
        height: 3.1rem;
        margin: 0.45rem 0 0.45rem 0;
        cursor: pointer;
      }
    }
  }
}
