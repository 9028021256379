@import "shared/assets/scss/variables.scss";

html,
body,
#root,
.app {
  height: 100%;
  font-size: 10px;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  position: relative;
}

input,
textarea {
  outline: none;
}

@font-face {
  font-family: "Mon-medium";
  src: local("Mon-medium"), url(./shared/assets/fonts/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Mon-semi-bold";
  src: local("Mon-semi-bold"), url(./shared/assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Mon-bold";
  src: local("Mon-bold"), url(./shared/assets/fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Mon-light";
  src: local("Mon-light"), url(./shared/assets/fonts/Montserrat-Light.ttf) format("truetype");
}

b {
  font-family: "Mon-bold", sans-serif;
}

.no-pointer-events {
  pointer-events: none;
  opacity: 0.4;
  cursor: none;
}

:root {
  --toastify-color-success: #1aa99c;
  --toastify-color-error: #e17271;
  --toastify-color-warning: #d09713;
}
.Toastify__toast--error {
  background: #fae1e2 !important;
  .on-call-navigator-toast {
    &-div {
      & .bold {
        font-weight: bold;
      }
    }
  }
}
.Toastify__toast--success {
  background: #f0f9fa !important;
}
.Toastify__toast--warning {
  border: 0.1rem solid #ecca22 !important;
  background: #fafac5 !important;
}
.Toastify__toast-body {
  color: #444c63;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 1.5rem;
  width: 100%;
}
.Toastify__close-button {
  align-self: auto;
  margin: 1rem;
}
.Toastify__toast-icon {
  margin-right: 2rem;
}
.Toastify__toast-container {
  padding: 1rem 1rem 1rem 1rem;
  width: max-content;
  max-width: 45rem;
  min-width: 35rem;
  z-index: 10 !important;
  &:has(.on-call-navigator-toast) {
    max-width: 60rem;
  }
  @media (max-width: $tablet) {
    width: 100%;
    max-width: auto;
    min-width: auto;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
