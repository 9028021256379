@import "shared/assets/scss/variables.scss";

.change-tnav-task-owner-modal-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) !important;

  .modal-header {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2.4rem;
  }

  .change-tnav-task-owner-modal-main {
    width: 40rem;
    background: #ffffff;
    border-radius: 1.2rem;
    padding: 2rem;
    .cross-container {
      display: none;
    }
  }

  #change-tnav-task-owner-modal-content {
    .content {
      color: #333;
      font-family: Mon-medium, sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 2.4rem;
      .bold {
        font-family: "Mon-semi-bold", sans-serif;
      }
    }

    .tnav-dropdown-container {
      .label-content {
        color: #333;
        font-family: Mon-medium, sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0.4rem;
      }
      margin-bottom: 3rem;
    }

    .button-container {
      display: flex;
      justify-content: center;
      .disabled {
        cursor: not-allowed;
      }
      .cancel-button {
        font-family: "Mon-medium", sans-serif;
        color: #333333;
        padding: 1rem;
        width: 12.2rem;
        height: 4rem;
        font-size: 1.6rem;
        cursor: pointer;
        margin: 0rem 1rem;
      }

      .submit-button {
        height: 4rem;
        width: 12.2rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-left: 1rem;
        cursor: pointer;

        &:disabled {
          pointer-events: none;
        }
      }
    }
  }
}
