.alert-collapsible-class-container {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  &.collapsible-container {
    max-height: fit-content;
  }
  .__contentInner {
    max-height: 20.6rem;
  }
}
.alert-collapsible-container-content {
  max-height: inherit;
  overflow: auto;
  max-height: 24rem;
  &-alert-item {
    display: flex;
    .alert-title {
      width: 150px;
      font-family: "Mon-bold", sans-serif;
      font-size: 12px;
      line-height: 22px;
      text-align: right;
      color: #667a78;
      margin-right: 1.6rem;
    }
    .alert-detail {
      width: 60%;
      word-wrap: break-word;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      line-height: 22px;
      color: #333333;
    }
  }
}
