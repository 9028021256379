.sub-header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 42px;
    .right-section {
      display: flex;
      align-items: center;
      position: fixed;
      right: 32px;
      .search-icon-container,
      .filter-icon-container {
        width: 4rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .filter-status-container {
        position: relative;
        .filtered {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #cb2020;
          position: absolute;
          right: -0.25rem;
          top: -0.25rem;
        }
      }
      .filter-icon-container {
        margin-left: 1.6rem;
      }
    }
  }
  