@import "../../shared/assets/scss/variables.scss";

#modal {
  .modal-container {
    z-index: 4;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    &-open {
      transition: all 0.3s ease-in-out;
    }
    &-close {
      transition: none;
    }

    &.modal-wrapper-enter-done {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1);
    }
    &.modal-wrapper-exit {
      opacity: 0;
      transform: scale(0.9);
    }

    &.right {
      justify-content: flex-end;
    }
    &.left {
      justify-content: flex-start;
    }
    &.center {
      justify-content: center;
      align-items: center;
    }
    &.bottom {
      align-items: flex-end;
    }
    .modal-main {
      background-color: #ffffff;
      .modal-header {
        height: 8%;
        &-with-patient-details {
          height: 22.5% !important;
        }
      }
      .modal-body {
        height: 87.5%;
        &-with-patient-details {
          height: 77% !important;
        }
      }
    }
    &.mobileView {
      .modal-main {
        .modal-header {
          &.compose {
            height: auto;
            max-height: 8.3rem;
            .header {
              margin-bottom: 0;
              padding-bottom: 3.6rem;
            }
          }
        }
      }
    }
  }
}
