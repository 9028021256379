@import "shared/assets/scss/variables.scss";

.notification-left-caret {
  position: absolute;
  display: none;
  z-index: 4;
  left: -1.3rem;
  scale: 0.8;
  top: 34rem;
}

.notification-drawer {
  display: none;
  height: 40rem;
  width: 40rem;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 6.9rem;
  bottom: 6rem;
  background: #ffffff;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 20%);
  color: #333;
  font-size: 1.2rem;
  font-family: "Mon-medium", sans-serif;
  border-radius: 0.8rem;
}

.notification-drawer-header {
  display: flex;
  padding: 1.6rem 0.8rem;
  margin: 0 0.8rem;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px rgb(0, 0, 0, 0.1) solid;
  justify-content: space-between;
  h2 {
    color: #333;
    font-size: 1.4rem;
    font-family: "Mon-medium", sans-serif;
  }
  p {
    color: #077269;
    font-size: 1rem;
    font-family: "Mon-medium", sans-serif;
    line-height: 14px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.notification-drawer-body {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  height: 85%;
  overflow-x: auto;
  z-index: 5;
  width: 40rem;
}

.unread {
  background: #f0f4f4;
}

.read {
  background: #ffffff;
}

.notification-is-open {
  display: flex !important;
}
