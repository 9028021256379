.single-select-dropdown-with-search {
  margin: 1.5rem 3.2rem 0 2.8rem;
  &.css-3iigni-container {
    pointer-events: all;
  }
  &.select--is-disabled {
    cursor: not-allowed !important;
  }
  .select__control--is-disabled {
    cursor: not-allowed !important;
  }
  &:focus-visible {
    outline: none;
  }
  .select__control--is-focused,
  .select__control--menu-is-open {
    border: 0.1rem solid #a6d9ce;
    outline: none;
    box-shadow: none;
  }
  .select__dropdown-indicator {
    padding: 5px;
    cursor: pointer;
    svg {
      height: 22px;
      width: 18px;
      background-color: #eeeeee;
      color: black;
      border-radius: 5px;
      padding: 3px;
    }
  }
  .bold-text {
    font-weight: bold;
    color: #1aa99c;
  }
}
