@import "../../../shared/assets/scss/variables.scss";
#modal {
  .new-call {
    .side-popup-content {
      display: flex;
      flex-direction: column;
      width: 50rem;
      .modal-body {
        height: 83.5%;
      }
      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .make-a-call-button {
          width: 85%;
          height: 4rem;
          cursor: pointer;
          font-family: "Mon-medium", sans-serif;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
    .chat-messages {
      overflow: auto;
      height: 90%;
    }
  }
  .mobileView {
    &.new-call {
      .side-popup-content {
        width: 100%;
        .modal-header {
          margin-bottom: 0;
          height: auto;
          max-height: 8.3rem;
          .header {
            margin-bottom: 0;
            padding-bottom: 3.6rem;
          }
        }
        .modal-body {
          overflow-y: auto;
          height: calc(100% - 8.3rem);
          .dropdown-container .select-span {
            margin-top: 0;
          }
        }
        .modal-footer {
          .make-a-call-button {
            font-size: 1.6rem;
            height: 5rem;
            width: 100%;
            margin: 1.6rem 1.6rem 2.4rem;
          }
        }
      }
    }
    .outbound-number-dropdown-container {
      .outbound-number-selection {
        margin: 1.5rem 1.6rem 0 1.6rem;
      }
    }
  }
}
.outbound-number-dropdown-container {
  display: flex;
  flex-direction: column;

  .outbound-number-selection {
    width: auto;
    height: 4.2rem !important;
    border: 0.1rem solid #a6d9ce !important;
    margin: 1.5rem 3.2rem 0 2.8rem;
  }
  .outbound-number-dropdown {
    width: 43.8rem !important;
    margin: -1rem 3.2rem 0 2.8rem;
    overflow-y: auto;
    top: 7rem !important;
  }
}

.mobileView {
  .outbound-number-dropdown-container {
    .outbound-number-selection {
      margin: 1.5rem 1.6rem 0 1.6rem;
    }
  }
}
