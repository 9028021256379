.activity-details-column {
  display: flex;
  flex-direction: column;
  .details {
    font-family: "Mon-Medium", sans-serif;
    font-size: 1.4rem;
    display: flex;
    justify-content: start;
    line-height: 19.6px;
    width: 100%;
    .empty-column {
      width: 21rem;
      text-align: right;
    }
    &-name {
      font-family: "Mon-semi-bold", sans-serif;
      color: #999;
      width: 26rem;
      margin-right: 1rem;
      display: flex;
      justify-content: end;
    }
    &-description {
      width: 100%;
      white-space: pre-wrap;
    }
  }
}
