.collapsible-container {
  margin-bottom: 1.4rem;
  &-heading {
    font-family: "Mon-bold", sans-serif;
    font-size: 1.6rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 0.85rem;
    margin-bottom: 1.2rem;
  }
  .is-present {
    color: #f76659;
    cursor: pointer;
  }

  .is-not-present {
    color: rgba(51, 51, 51, 0.4);
    &.collapsible-container-heading {
      margin-bottom: 0;
    }
  }

  .is-disabled {
    cursor: not-allowed;
  }

  .__contentInner {
    // max-height: 206px;
  }
}
