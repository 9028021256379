.collapse-container,
.Collapsible {
  width: 95%;
  background: rgba(241, 250, 249, 0.6);
  border: .1rem solid #bcdfef;
  border-radius: .8rem;
  margin-top: 0;

  .Collapsible__trigger {
    display: flex;
    padding: 1.7rem 0 1.8rem 3.2rem;
    font-family: "Mon-bold";
    font-size: 1.4rem;
    color: #333333;
  }
  .Collapsible__contentOuter {
    background: #eeeeee;
  }
  .Collapsible__contentInner {
    margin-left: 3.2rem;
    padding-top: 3.2rem;
  }
}
