@import "shared/assets/scss/variables.scss";

.revision-reason-modal-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) !important;

  .modal-header {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2.4rem;
  }

  .revision-reason-modal-main {
    width: 40rem;
    background: #ffffff;
    border-radius: 1.2rem;
    padding: 2rem;
    .cross-container {
      display: none;
    }
  }

  #revision-reason-modal-content {
    .info {
      color: #333;
      font-family: Mon-medium, sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 2.4rem;
    }

    .reason-dropdown-container {
      .label-content {
        color: #333;
        font-family: Mon-medium, sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0.4rem;
      }
      margin-bottom: 3rem;
    }

    .warning-content {
      display: flex;
      padding: 6px 10px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 4px;
      margin-top: 2rem;
      border: 1px solid rgba(255, 122, 0, 0.1);
      background: rgba(255, 245, 190, 0.6);
      .warning-icon {
      }

      .warning-info {
        color: #333;
        font-family: Mon-medium, sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .cta-container {
      display: flex;
      justify-content: center;
      .cancel-button {
        font-family: "Mon-medium", sans-serif;
        color: #333333;
        padding: 1rem;
        width: 12.2rem;
        height: 4rem;
        font-size: 1.6rem;
        cursor: pointer;
        margin: 0rem 1rem;
      }

      .create-button {
        height: 4rem;
        width: 12.2rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-left: 1rem;
        cursor: pointer;

        &:disabled {
          pointer-events: none;
        }
      }
    }
  }
}
