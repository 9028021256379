.pill-checkbox-container {
  background: #ffffff;
  border: 0.1rem solid #bec3c2;
  border-radius: 0.4rem;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #7e7e7e;
  display: inline-flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5rem 1.7rem 0.5rem 1rem;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  cursor: pointer;

  &.checked {
    background: rgba(198, 221, 217, 0.6);
    border: 0.1rem solid rgba(26, 169, 156, 0.2);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .pill-checkbox {
    appearance: none;
    height: 1.55rem;

    &:checked {
      background-image: url("../../../../shared/assets/images/white-tick-with-green-background.svg");
      background-repeat: no-repeat;
      height: 1.55rem;
      width: 1.55rem;
      background-origin: border-box;
      background-position: center;
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.nested-pill-questions {
  .pill-checkbox-container {
    display: inline-flex;
    cursor: pointer;
    .pill-checkbox {
      .pill-title {
        display: inline-block;
      }
    }
  }
  .checkbox-options-title,
  .pill-option-title {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    color: #333333;
    margin-right: 3.6rem;
    line-height: 100%;
    flex-shrink: 0;
  }
  .checkbox-extra-section {
    display: inline-flex;
    .pill-checkbox-container {
      margin: 0px 0px 13px 0;
    }
  }
  .extra-section-1 {
    position: relative;
    &.extra-section-pill {
      align-items: center;
      position: relative;
    }
    margin-top: 2.5rem;
    display: flex;
    align-items: flex-start;
    .single-select-dropdown-container {
      margin-bottom: 1rem;
      .no-pointer-events {
        cursor: not-allowed !important;
        pointer-events: all;
      }
    }
    .not-selected-error-message {
      position: absolute;
      bottom: -2rem;
    }

    .checkbox-extra-section {
      display: block;
      .not-selected-error-message {
        position: relative;
        bottom: 1.2rem !important;
      }
      .single-select-dropdown-container {
        margin-bottom: 1rem;
        .no-pointer-events {
          cursor: not-allowed !important;
          pointer-events: all;
        }
      }
    }

    .pill-checkbox-container {
      margin: 1.3rem 0 1.3rem 0;
    }

    .checkbox-label {
      margin-bottom: 1rem;
    }

    &-checkbox {
      display: flex;
      .checkbox-heading {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #333333;
        margin: 0 4rem 0 0;
      }
      .checkbox-label {
        margin-bottom: 1.1rem;
      }
    }
  }
}

.text-area {
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.8;
  border: 0.1rem solid #c1c9d2;
  border-radius: 0.8rem;
  padding: 1rem;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    background: #f2f2f2;
  }
}

.single-select-dropdown-container {
  display: flex;
  align-items: center;
  min-width: 30rem;
  margin-bottom: 1rem;
  .no-pointer-events {
    cursor: not-allowed !important;
    pointer-events: all;
    .icon-container {
      .select-box-down-arrow {
        cursor: not-allowed;
      }
    }
  }
  .dropdown-title {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    line-height: 100%;
    color: #333333;
    margin-right: 3.6rem;
    flex-shrink: 0;
  }
}

.text-area-container {
  display: flex;
  column-gap: 1.6rem;
  .text-area-title {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #767676;
    margin-bottom: 1rem;
  }
}

.days-selector-container {
  display: flex;
  align-items: center;
  .days-selector-label {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    color: #333333;
    margin-left: 0.8rem;
    line-height: 100%;
  }
  .days-selector {
    width: 4.5rem;
    height: 4rem;
    background: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
    border: 0.1rem solid #c1c9d2;
    border-radius: 0.8rem;
    padding: 0.2rem 2rem;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
      background: #f2f2f2;
    }
  }
}

.age-selector-container {
  display: flex;
  align-items: center;
  .age-selector-label {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    color: #333333;
    margin-left: 0.8rem;
    line-height: 100%;
  }
  .age-selector {
    width: 4.5rem;
    height: 4rem;
    background: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
    border: 0.1rem solid #c1c9d2;
    border-radius: 0.8rem;
    padding: 0.2rem 2rem;
    cursor: not-allowed;
    &:disabled {
      opacity: 0.8;
      background: #f2f2f2;
    }
  }
}

.text-area-main-container {
  display: grid !important;

  &.full-width-text-area-container {
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
  }
  .full-width-text-area {
    width: 100%;
    textarea {
      flex-grow: 1;
      font-family: "Mon-medium", sans-serif;
      resize: vertical;
      min-height: 2rem;
    }
  }
}

.text-area-container-max-length {
  display: flex;
  column-gap: 1.6rem;
  margin-left: 7rem;

  &.right-aligned {
    display: flex;
    justify-content: flex-end;
  }
  .text-area-title {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #767676;
    margin-bottom: 1rem;
  }
}

.section-question-heading {
  font-family: "Mon-bold", sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 3.2rem;
  color: #00887c;
  margin-bottom: 1rem;
}
