@import "shared/mixins/mixins.styles";

.task {
  min-height: 3.7rem;
  background: #ffffff;
  border-radius: 0.6rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  &:hover {
    background: #eeeeee;
    .task-info-container {
      background-color: unset;
    }
  }
  &:last-child {
    margin-bottom: 6rem;
    .task-info {
      border-bottom: none;
    }
  }
  &.selected-task {
    background: linear-gradient(180deg, #6ab8dc 0%, #71c69b 100%);
    border: none;
    border-radius: 0.8rem;
    .task-info-container {
      background-color: #e9f7f6;
    }
    .task-info-toc-tasks-details {
      background-color: #ffffff;
    }
  }
  &-info-container {
    padding: 0 1.2rem;
    background-color: white;
    border-radius: 0.7rem;

    &.selected-info {
      background: #e9f7f6;
    }
  }
  &-info {
    column-gap: 2.5rem;
    padding: 1.2rem 0;
    border-bottom: 0.1rem solid rgba(102, 122, 120, 0.1);

    .bold-text {
      font-weight: bold;
      color: #00887c;
    }

    &-sections {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .custom-tooltip {
          max-width: 22rem;
          left: 90px !important;
          white-space: normal;
          word-break: break-word;
        }
        .name-and-attempts {
          display: flex;
          align-items: start;
          .name {
            display: inline-block;
            margin-right: 0.8rem;
            max-width: 17rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @include fontStyle("Mon-bold", 1.4rem, 1.7rem, #64666a);
          }
          .handshake-icon {
            cursor: pointer;
            display: inline;
            position: relative;
            top: 0.4rem;
          }
          .attempt-count {
            color: #667a78;
            font-family: "Mon-bold", sans-serif;
            font-size: 1rem;
            line-height: 120%;
            letter-spacing: -0.06rem;
            display: inline-flex;
            margin-right: 0.8rem;
          }
          .delay-icon-image {
            top: 0.2rem;
            position: relative;
            margin-right: 0.8rem;
          }
        }
      }
      .right-section {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;
        .date {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          .due {
            @include fontStyle("Mon-medium", 1.2rem, normal, #858585);
          }
          .due-date {
            @include fontStyle("Mon-medium", 1.2rem, 1.5rem, #858585);
            &.red {
              color: #f76659;
              font-family: "Mon-bold", sans-serif;
            }
            &.grey {
              color: #64666a;
            }
          }
        }
        .physician {
          display: flex;
          column-gap: 0.5rem;
          &-name {
            @include fontStyle("Mon-medium", 1.2rem, normal, #333);
            opacity: 0.6;
            max-width: 14rem;
            word-break: break-all;
          }
          .phy {
            @include fontStyle("Mon-medium", 1.2rem, normal, #333);
            opacity: 0.6;
          }
        }
      }
    }
    &-toc-tasks-details {
      margin-top: 0.4rem;
    }

    .patient-details {
      @include fontStyle("Mon-medium", 1.2rem, 1.5rem, #333);
      opacity: 0.6;
      max-width: 22rem;
      word-break: break-word;
    }
    .procedure {
      &-text {
        @include fontStyle("Mon-semi-bold", 1.2rem, 2.2rem, #333333);
      }
      @include fontStyle("Mon-medium", 1.2rem, 2.2rem, #333333);
    }
    .task-name {
      &-text {
        @include fontStyle("Mon-semi-bold", 1.2rem, 2.2rem, #333333);
      }
      @include fontStyle("Mon-medium", 1.2rem, 2.2rem, #333333);
    }
  }
}
