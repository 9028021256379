@import "../../shared/assets/scss/variables.scss";

.status-selector {
  min-height: 250px;
  border-radius: 8px;
  position: absolute;
  bottom: 1rem;
  padding: 1rem;
  display: none;
  left: 6.9rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 20%);
  width: 19.5rem;

  &-about {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.4rem;
    .avatar-container {
      width: 5.4rem;
      height: 5.4rem;
      box-sizing: border-box;
      margin-bottom: 1.2rem;
    }
    &--name {
      font-family: "Mon-Medium", sans-serif;
      font-size: 1.4rem;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-align: center;
    }
  }
  .status-picker-component {
    margin-bottom: 1.5rem;
    width: 19.64rem;
    & > div {
      width: 100%;
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .status-selector-profile,
    .status-selector-logout {
      padding: 1rem;
      border-radius: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.2);
      text-align: center;
      width: 7rem;
      height: 7rem;
      color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .status-selector-profile {
      &.margin-right-20 {
        margin-right: 2rem;
      }
      &-icon {
        margin-bottom: 1rem;
        text-align: center;
      }
      &-text {
        font-size: 1.4rem;
        font-family: "Mon-medium";
      }
    }
    .status-selector-logout {
      &-icon {
        margin-bottom: 1rem;
        text-align: center;
      }
      &-text {
        font-size: 1.4rem;
        font-family: "Mon-medium";
      }
    }
  }
  &.opacity50 {
    background-color: whitesmoke;
  }
}

.status-is-open {
  display: flex !important;
}
.left-caret {
  position: absolute;
  display: none;
  left: 5.6rem;
  bottom: 3rem;
  z-index: 4;
  scale: 0.8;
}
