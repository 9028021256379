.poc-service-modal-side-popup {
  .poc-service-content-container {
    width: 50rem;
  }
  .modal-main {
    .modal-header {
      margin-bottom: 0 !important;
      .header > .close-icon-container > .close-icon.show {
        width: 1.6rem !important;
        height: 1.6rem !important;
      }
    }
    .disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
    }
    .modal-body {
      height: 88% !important;
      padding-right: 0 !important;
      .loading-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .poc-service-modal-content {
        margin-top: 1rem !important;
        height: 100% !important;
        padding: 1.9rem 0rem 0rem 2.5rem;
        .bold-text {
          font-weight: bold;
          color: #1aa99c;
        }
        .error-container {
          color: #ff6136;
          font-size: 1.2rem;
          font-family: "Mon-medium", sans-serif;
        }
        .poc-service-modal-form {
          height: calc(100% - 7.5rem);
          overflow: auto;
          margin-bottom: 2rem;
          &-field {
            margin-bottom: 1.6rem;
            padding-right: 2.5rem;
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
            .checkbox-label {
              margin-top: 0.4rem;
              margin-bottom: 1.2rem;
            }
            .textarea-input-group-section {
              .label-container {
                margin-bottom: 0 !important;
              }
            }
            .date-field {
              font-family: "Mon-medium", sans-serif;
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              .calender-icon {
                &.disabled {
                  display: none !important;
                }
                display: block !important;
                position: absolute;
                right: 1rem;
                top: 2.4rem;
                transform: translateY(-50%);
                &:hover {
                  cursor: pointer;
                }
                &.disabled {
                  opacity: 0.4;
                  cursor: default;
                }
              }
              .date-picker-mask-input.date-picker-input {
                width: 18rem;
                box-sizing: border-box;
                font-family: "Mon-medium", sans-serif;
              }
            }
            &.admission-discharge-date-container {
              display: flex;
              gap: 1rem;
              flex-direction: row;
            }
            .action-dropdown-container {
              .no-pointer-events {
                cursor: not-allowed;
                pointer-events: unset;
              }
            }
            textarea {
              background-color: #fff;
              color: #333333;
            }
            .los {
              flex-direction: column;
              .field-title {
                margin: 0;
              }
              .disabled {
                opacity: 0.5;
              }
              .field-wrap {
                width: 10.5rem !important;
                input:disabled {
                  opacity: 0.5;
                  cursor: not-allowed;
                }
              }
            }
            .date-selector {
              input:disabled {
                cursor: not-allowed;
              }
              button:disabled {
                cursor: not-allowed;
              }
              .react-datepicker__close-icon--disabled::after {
                cursor: not-allowed !important;
              }
            }
            .disabled-date {
              input:disabled {
                cursor: not-allowed;
              }
              button {
                display: none;
              }
            }
            //text fields scss
            .text-input-field-container {
              &:last-child {
                margin-bottom: 0rem;
                display: flex;
              }
              .field-wrap {
                height: 4rem;
                width: 100%;
                .input-field {
                  border: none;
                  width: 100%;
                }
              }
            }
          }
          .field-label {
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: #687170;
            .red-color-asterisk {
              color: red;
            }
          }
        }
      }
    }
    .character-count {
      color: #767676;
      text-align: right;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
    }
    .more-padding {
      padding-right: 4.1rem;
    }
    .less-padding {
      padding-right: 2.8rem;
    }
    .poc-service-modal-button-container {
      display: flex;
      justify-content: space-between;
      .save-button:disabled {
        cursor: not-allowed;
      }
      .white-button,
      .green-button {
        font-family: "Mon-medium", sans-serif;
        width: 8.8rem;
        height: 4rem;
      }

      & > div {
        display: flex;
        gap: 1rem;
      }
    }
  }
}
