.task-container-tab {
  overflow: auto;
  .task-list-data {
    border-radius: 0.5rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
    .upcoming-task-title {
      overflow: hidden;
      color: #333;
      text-overflow: ellipsis;
      font-family: "Mon-bold", sans-serif;
      font-size: 1.6rem;
    }
    .add-task-cta {
      display: flex;
      align-items: center;
      font-family: "Mon-bold", sans-serif;
      font-size: 1.4rem;
      letter-spacing: 0px;
      color: #1aa99c;
      cursor: pointer;
      .plus-add-icon {
        margin-right: 5px;
      }
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .task-card {
    min-height: 1.85rem;
    margin-bottom: 1rem;
    padding: 1.2rem;
    background: #ffffff;
    border-radius: 0.5rem;
    flex-direction: column;
    & .task-info-toc-tasks-details {
      margin-top: 0.6rem;
    }
    .task-name {
      align-items: center;
      color: #64666a;
      font-size: 1.4rem;
      font-family: "Mon-bold", sans-serif;
    }
    .task-date-container {
      display: flex;
      justify-content: space-between;
      margin-top: 0.6rem;
      p {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        color: #33333399;
      }
    }
  }
}
