.message-read-participants-details-mobile-modal {
  & .modal-main {
    border-radius: 0.8rem;
  }
  animation: openDrop 0.2s forwards;
}

.close-drop {
  animation: closeDrop 0.3s backwards;
}
@keyframes openDrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closeDrop {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
