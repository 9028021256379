#episode-details-container {
  overflow: auto;
  padding: 0 0.1rem;
  &.episode-details-container-with-patient-declined-alert {
    .episode-tasks-not-found {
      height: calc(100vh - 39rem);
    }
    .episode-tasks-table {
      &-body {
        height: calc(100vh - 47rem);
      }
    }
  }
  .episode-tasks-not-found {
    width: 100%;
    height: calc(100vh - 38rem);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &-content {
      max-width: 22rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      & img {
        width: 21.4182rem;
        height: 19.394rem;
      }
    }
    &-heading,
    &-text {
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-size: 1.4rem;
    }
    &-text {
      font-family: "Mon-medium", sans-serif;
      text-align: center;
      max-width: 22rem;
      margin-top: 0.7rem;
    }
    &-heading {
      margin-top: 1.5rem;
      font-family: "Mon-bold", sans-serif;
    }
  }
}
