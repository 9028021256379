@import "shared/assets/scss/variables.scss";

.message-sent-by {
  font-family: "Mon-medium";
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin: 0.6rem 0 0.8rem 0;
  font-weight: 700;
  color: #077269;
}
.overlay-image-view {
  position: absolute;
  z-index: 99;
  right: 0px;
  top: 0px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  display: flex;
  justify-content: end;
  .close-icon {
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
  }
}

.message {
  margin: 0 0.9rem;
  display: block;

  .right-aligned {
    float: right;
    .media-with-progress {
      background-color: #d9d6bf;
      border-radius: 0.4rem;
      margin-bottom: 0.625rem;
      .progress-bar {
        .CircularProgressbar {
          .CircularProgressbar-trail {
            stroke: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

  .message-container {
    background: #f2f0e2;
    border-radius: 0.4rem;
    display: inline-flex;
    flex-direction: column;
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    max-width: 75%;
    margin: 1.1rem 1.1rem 0.2rem 1.1rem;
    .image {
      height: 20rem;
      width: 20rem;
      object-fit: cover;
      margin-bottom: 0.625rem;
      max-width: 100%;
      &:hover {
        cursor: pointer;
      }
    }
    .image-with-progress {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 20rem;
      height: 20rem;
      .progress-bar {
        height: 3.4rem;
        width: 3.4rem;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        .cancel-upload {
          position: absolute;
        }
        .retry-upload {
          position: absolute;
        }
      }
    }
    .media-with-progress {
      display: flex;
      padding: 1rem;
      align-items: center;
      .media {
        display: flex;
        align-items: flex-start;
        &:hover {
          cursor: pointer;
        }
        .pdf-icon {
          height: 4.125rem;
          margin-right: 1.117rem;
        }
        .text {
          font-family: "Mon-semi-bold";
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: #333333;
        }
      }
      .progress-bar {
        height: 3.4rem;
        width: 3.4rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2.792rem;
        .cancel-upload {
          position: absolute;
        }
        .retry-upload {
          position: absolute;
        }
      }
    }

    &.white {
      background-color: white;
      .media-with-progress {
        background-color: #dbe7e5;
        border-radius: 0.4rem;
        margin-bottom: 0.625rem;
      }
      border: 0.1rem solid rgba(148, 212, 180, 0.8);
    }

    .text {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #333333;
      margin-bottom: 0.7rem;
      word-break: break-word;
    }

    .file-size {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #333333;
      mix-blend-mode: normal;
      opacity: 0.6;
    }

    .timeRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .time {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #636d70;
        margin-right: 6.5rem;
      }
      &__right {
        display: flex;
        align-items: center;

        .messageSentIcon {
          height: 8px;
          width: 15px;
        }
      }
    }
  }
}
.participant-read-container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  justify-content: end;
  position: relative;
  .participant-read-initials {
    width: fit-content;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    width: max-content;
    transition: all 0.15s ease;
    padding: 1rem;
    color: white;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.1rem silver;
    font-size: 1rem;
    text-align: center;
  }

  [data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: #1aa99c;
    margin-top: -6rem;
    margin-left: 0;
  }
}
.participant-read-initial {
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  font-size: 0.7rem;
  color: #ffffff;
  background: #807a4d;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Mon-medium";
  @media (max-width: $mobile) {
    font-size: 0.6rem;
  }
  & > span {
    text-transform: uppercase;
  }
  &:not(&:last-child) {
    margin-right: 0.5rem;
  }
}
