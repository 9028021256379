.no-task-found-container {
  .no-task-found-image {
    width: 18.3rem;
    height: 18.3rem;
  }
  .text {
    font-family: "Mon-medium", sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
  }
}
