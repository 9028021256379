.patient-decline-navigation-alert-container {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid rgba(255, 122, 0, 0.1);
  box-sizing: border-box;
  background: rgba(255, 245, 190, 0.6);
  .alert-icon {
    display: flex;
    align-items: center;
  }
  .text-and-notes-icon-container {
    color: #333;
    font-family: "mon-semi-bold", sans-serif;
    font-size: 1.2rem;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .notes-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 24px;
      height: 24px;
      padding: 1px 2px 2px 2px;
      gap: 10px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.06);
      .patient-decline-notes-tooltip {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        word-break: break-word;
        max-width: 100rem;
        .bold {
          font-family: "mon-bold", sans-serif;
        }
      }
    }
  }
}
