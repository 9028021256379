@import "shared/assets/scss/variables.scss";

.patient-contact-table {
  .contact-table-header {
    margin-bottom: 0;
    display: table;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: unset;
    &.hidden {
      display: none;
    }
    &-content {
      display: flex;
      align-items: center;
      width: fit-content;
      &-name {
        margin-right: 8px;
      }
      &:hover {
        cursor: pointer;
      }
      &-disable-cursor {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
    tr {
      font-weight: bold;
      color: $grey-text-color;
      background: rgb(241, 250, 249);
      border-radius: 0.8rem 0.8rem 0 0;
      border-style: hidden;
      box-shadow: 0 0 0 0.1rem $primary-border-color;
      th {
        font-size: 1.4rem;
        padding: 1.7rem 0 1.8rem 0;
        border: 0.1rem solid $primary-border-color;
        border-style: solid none solid none;
        text-align: left;
        font-family: "Mon-bold", sans-serif;
      }
      th:nth-child(1) {
        border-left: 0.1rem solid $primary-border-color;
        border-radius: 0.8rem 0 0 0;
        padding-left: 2.4rem;
        width: 18%;
      }
      th:nth-child(2) {
        width: 20%;
      }
      th:nth-child(3) {
        width: 20%;
      }
      th:nth-child(4) {
        width: 17%;
      }
      th:last-child {
        width: 25%;
        border-style: solid solid solid none;
      }
    }
  }
  & > tbody > tr > td {
    padding: 0;
  }
  .infinite-scroll-component__outerdiv {
    width: 100%;
    border-radius: 0 0 0.8rem 0.8rem;
    box-sizing: border-box;
    box-shadow: 0 0 0 0.1rem #eef0f8;
    .infinite-scroll-component {
      width: 100%;
      background-color: white;
      .contact-table-body {
        .loading-container {
          height: calc(100vh - 31rem);
        }
        .contact-no-border-row {
          box-shadow: unset !important;
        }
        width: 100%;
        display: block;
        height: calc(100vh - 20rem);
        box-sizing: border-box;
        overflow-y: overlay;
        overflow-x: hidden;
        box-sizing: border-box;
        background-color: white;
        tr.no-message-container {
          display: table;
        }
        tr {
          width: 100%;
          display: table;
          box-shadow: 0 0.1rem 0 0 $grey-border-color;
          background-color: unset;
          td {
            font-size: 1.4rem;
            line-height: 1.6rem;
            padding: 1.7rem 0 1.8rem 0;
            text-align: left;
            border-style: none;
            font-family: "Mon-medium", sans-serif;
            a {
              color: $primary-text-color;
              cursor: pointer;
            }
          }
          td:nth-child(1) {
            padding-left: 2.4rem;
            width: 18%;
          }
          td:nth-child(2) {
            width: 20%;
          }
          td:nth-child(3) {
            width: 20%;
          }
          td:nth-child(4) {
            width: 17%;
          }
          td:last-child {
            width: 25%;
            padding-right: 1.5rem;
            .icons {
              display: flex;
              justify-content: flex-end;

              &.visible {
                visibility: visible;
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
              }
              &.hidden {
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.1s ease-in-out;
              }
              .icon-background {
                height: 3.2rem;
                width: 3.2rem;
                background-color: white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                &.disabled {
                  pointer-events: none;
                  cursor: not-allowed;
                }
                &.message {
                  margin-left: 1.4rem;
                }
              }
            }
          }
        }
        tr:last-child {
          border-radius: 0 0 0.8rem 0.8rem;
        }
        .hovered {
          background: #eeeeee;
        }
      }
    }
  }
}

.mobileView {
  .patient-contact-table {
    .contact-no-border-in-mobile {
      box-shadow: unset;
    }
    .infinite-scroll-component__outerdiv {
      box-shadow: none;
      border-radius: 0;
      .infinite-scroll-component {
        .contact-table-body {
          height: calc(100vh - 34rem);
          &::-webkit-scrollbar-thumb {
            background: $white;
          }
          tr {
            box-shadow: none;
            td {
              padding: 1.1rem 0;
              span {
                display: block;
              }
              .identity-box {
                color: $grey-2;
                background-color: $white-3;
                border: 0.1rem solid $white-4;
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .avatar-container {
                  border: none;
                  padding: 0;
                  margin: 0;
                }
              }
              .patient-name {
                font-family: "Mon-bold", sans-serif;
                color: $grey-text-color;
                margin-bottom: 0.8rem;
              }
              .procedure-name {
                color: #636d70;
              }
            }
            td:nth-child(1) {
              width: 4.8rem;
              padding-left: 0;
              padding-right: 2rem;
            }
            td:last-child {
              width: auto;
            }
          }
        }
      }
    }
  }
}
