.custom-tooltip {
  border-radius: 8px !important;
  z-index: 5 !important;
  font-family: "Mon-medium", sans-serif;
  background-color: #004640 !important;
  &.bottom,
  &.top {
    &::after {
      border-bottom-color: #004640 !important;
      border-top-color: #004640 !important;

      border-left-color: transparent !important;
      border-right-color: transparent !important;
    }
  }

  &.right,
  &.left {
    &::after {
      border-left-color: #004640 !important;
      border-right-color: #004640 !important;

      border-bottom-color: transparent !important;
      border-top-color: transparent !important;
    }
  }
}
