@import "../../shared/assets/scss/variables.scss";

@mixin status-picker-common {
  display: flex;
  border-radius: 8px;
  column-gap: 10px;
  align-items: center;
  position: relative;
  height: 40px;
  justify-content: center;
  cursor: pointer;
}

.status-picker {
  @include status-picker-common;
  &.online {
    background: #f1faf9;
    border: 1px solid #a7ddce;
    width: 106px;
  }

  &.offline {
    background: #faf1f1;
    border: 1px solid #dda7a7;
    width: 107px;
  }

  &.forwarded {
    background: #faf7f1;
    border: 1px solid #ddc8a7;
    width: 141px;
    pointer-events: none;
    cursor: crosshair;
  }

  .status {
    font-family: "Mon-medium", sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    position: relative;
  }

  &.forwarded {
    .status {
      opacity: 0.6;
    }
  }

  .green-tick-icon {
    .green-tick-image {
      height: 10px;
      width: 10px;
    }
  }
}
