.avatar-container {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.1rem solid gray;
  position: relative;
  margin-bottom: 2.4rem;
  font-size: 1.6rem;

  &.pointer {
    cursor: pointer;
  }
  .avatar-status {
    position: absolute;
    bottom: 0.1rem;
    right: 0.1rem;
    .online,
    .offline,
    .forwarded {
      border: 1px solid white;
      height: 1rem;
      width: 1rem;
    }
  }
}
