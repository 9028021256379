@import "shared/assets/scss/variables.scss";

thead.manager-dashboard-table-header {
  margin-bottom: 1rem;
  position: sticky;
  top: 1px;
  left: 0;
  z-index: 2;
  .manager-dashboard-table-header-content {
    display: flex;
    align-items: center;
    width: fit-content;
    .name {
      margin-right: 8px;
    }
    &:hover {
      cursor: pointer;
    }
    &-disable-cursor {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  tr.manager-dashboard-table-header-rows {
    font-weight: bold;
    color: $grey-text-color;
    background: rgb(241, 250, 249);
    border-radius: 0.8rem 0.8rem 0 0;
    border-style: hidden;
    box-shadow: 0 0 0 0.1rem $primary-border-color;
    th {
      font-size: 1.4rem;
      padding: 1.5rem;
      border: 0.1rem solid $primary-border-color;
      border-style: solid none solid none;
      text-align: right;
      font-family: "Mon-bold", sans-serif;
      width: 11%;
      & > div {
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }
    th:first-child {
      border-left: 0.1rem solid $primary-border-color;
      border-radius: 0.8rem 0 0 0;
      width: 3%;
    }
    th:nth-child(2) {
      & > div {
        justify-content: left;
        text-align: left;
      }
      padding-left: 0;
    }
    th:last-child {
      border-style: solid solid solid none;
    }
  }
}

tbody {
  &.manager-dashboard-table-body {
    & > tr {
      background-color: unset;
      & > td {
        padding: 0 !important;
        border: none;
        .manager-stats-table-body {
          height: calc(100vh - 38rem);
          overflow-y: auto;
          background-color: white;
          .table-loading-container {
            height: calc(100vh - 41rem);
          }

          & tr {
            box-shadow: 0 0 0 0.1rem #eef0f6;
            background-color: white;
            & > td {
              border-style: none !important;
              &:first-child {
                width: 3%;
              }
              width: 11%;
            }
          }
        }
      }
    }
  }

  tr {
    background-color: white;
    td {
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 1.5rem;
      text-align: right;
      border: 0.1rem solid $grey-border-color;
      border-style: solid none solid none;
      font-family: "Mon-medium", sans-serif;
      &.total-calls-count {
        position: relative;
        cursor: pointer;

        .inbound-outbound-count {
          position: absolute;
          left: 7rem;
          top: 4rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: 27.2rem;
          padding: 16px;
          border-radius: 4px;
          border: 1px solid #e6e6e6;
          background-color: white;
          z-index: 1;
          box-shadow: 0px 4px 12px 0px #00000026;
          cursor: default;

          .inbound-count {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 1.6rem;
            &-value {
              font-family: "Mon-bold", sans-serif;
              font-size: 1.8rem;
            }
          }
          .outbound-count {
            display: flex;
            width: 100%;
            justify-content: space-between;
            &-value {
              font-family: "Mon-bold", sans-serif;
              font-size: 1.8rem;
            }
          }
        }
      }
      a {
        color: $primary-text-color;
        cursor: pointer;
      }
    }
    .online {
      background: #008f12;
    }
    .offline {
      background: #cb2020;
    }
    .forwarded {
      background: #ffa723;
    }
    td:first-child {
      border-style: solid none solid solid;

      .work-hour-status {
        border-radius: 100%;
        width: 1rem;
        height: 1rem;
        margin: auto;
      }
    }
    tr {
      background-color: white;
      td {
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding: 1.5rem;
        text-align: right;
        border: 0.1rem solid $grey-border-color;
        border-style: solid none solid none;
        font-family: "Mon-medium", sans-serif;
        a {
          color: $primary-text-color;
          cursor: pointer;
        }

        .navigator-name-container {
          display: flex;
          align-items: center;
          column-gap: 18px;
          word-break: break-word;
        }
      }
      td:nth-child(1) {
        text-align: left;
        border-style: solid none solid solid;
      }
      td:last-child {
        border-style: solid solid solid none;
      }
    }
    td:nth-child(2) {
      text-align: left;
      padding-left: 0;
    }
    td:last-child {
      border-style: solid solid solid none;
    }
  }
  tr:last-child {
    border-radius: 0 0 0.8rem 0.8rem;
    border-style: hidden;
    box-shadow: 0 0 0 0.1rem $grey-border-color;
  }
  .no-message-container {
    position: relative;
    .no-message-td {
      height: 5rem;
      border-style: none;
    }
    .no-messages {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
    }
  }
}
