.summary-modal-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) !important;
  .modal-header {
    margin-bottom: 2.5rem;
  }
  .summary-modal-main {
    width: 71rem;
    background: #ffffff;
    border-radius: 1.2rem;
    padding: 1.6rem 2.1rem 3.1rem 1.6rem;
    .cross-container {
      width: 2.8rem;
      height: 2.8rem;
      background: #f4f6fa;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-icon {
        width: 1.2rem !important;
        height: 1.2rem !important;
      }
    }
    #modal-header header {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.8rem;
      line-height: 100%;
      display: flex;
      align-items: center;
      color: #000000;

      .checklist-icon-container {
        width: 3.2rem;
        height: 3.2rem;
        background: #e6eeff;
        border-radius: .6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.2rem;
      }
    }
  }
  .summary-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      color: #333333;
    }
    &-table {
      thead {
        th {
          border-bottom: .1rem solid rgba(51, 51, 51, 0.1);
          text-align: left;
          padding-left: 1.6rem;
          font-family: "Mon-bold", sans-serif;
          font-size: 1.4rem;
          line-height: 1.7rem;
        }
        th:first-child {
          width: 40.7rem;
          height: 4.4rem;
          background: #f5f5f5;
          border-radius: .6rem 0 0 0;
        }
        th:nth-child(2) {
          width: 14.1rem;
          height: 4.4rem;
          background: #ecfdf5;
          color: #057857;
        }
        th:nth-child(3) {
          width: 12rem;
          height: 4.4rem;
          background: #fff0f0;
          color: #f87171;
        }
      }
      tbody {
        background: rgba(237, 255, 246, 0.4);
        td {
          text-align: left;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          line-height: 2rem;
          color: #101828;
          padding: .8rem 1.6rem .8rem 1.6rem;
          border-style: solid solid solid solid;
        }
      }
    }
  }
}
