#modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  header {
    font-family: "Mon-bold", sans-serif;
    font-size: 2rem;
    line-height: 158.1%;
    color: #333333;
  }
  .close-icon-container {
    font-size: 0;
    cursor: pointer;
  }
}
