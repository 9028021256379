.transcription-modal {
  .transcription-modal-content {
    background: #ffffff;
    border-radius: .8rem;
    padding: 2.4rem 3.2rem 2.4rem 3.9rem;
    height: 70%;

    .modal-header {
      margin-bottom: 5rem;
    }

    .transcription-modal-content-container {
      .transcript-line {
        display: flex;
        margin-bottom: 1rem;

        .name {
          font-family: "Mon-bold", sans-serif;
          font-size: 1.4rem;
        }
        .value {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
        }
      }
    }
  }
}
