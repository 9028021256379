#episode-details-container {
  &.episode-details-container-with-patient-declined-alert {
    .no-activity-history-logs-found.no-data-found-container {
      height: calc(100vh - 41rem);
      &.applied-filter {
        height: calc(100vh - 51rem);
      }
    }
    .episode-activity-table-body {
      height: calc(100vh - 47rem);
      &.filter-applied {
        height: calc(100vh - 53.2rem);
      }
    }
  }
  .no-activity-history-logs-found.no-data-found-container {
    width: 100%;
    height: calc(100vh - 38rem);
    position: relative;
    &.applied-filter {
      height: calc(100vh - 48rem);
    }
    .no-data-found {
      max-width: 25.7rem;
      & img {
        width: 21.4182rem;
        height: 19.394rem;
      }
    }
  }
  .filter-applied-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .filter-text {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #687170;
    }
    .filters-applied {
      display: flex;
      margin: 0 1rem;
    }
    .clear-filter-button {
      color: #1aa99c;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      cursor: pointer;
    }
  }

  .episode-activity-table {
    &-header {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      background: #f1faf9;
      border-radius: 0.8rem 0.8rem 0 0;
      box-shadow: 0 0 0 0.1rem #bcdfef;
      color: #333;
      z-index: 2;
      .column {
        display: flex;
        box-sizing: border-box;
        padding: 1.1rem 1.6rem;
        align-items: center;
        word-break: break-word;
        box-sizing: border-box;
        &:nth-child(1) {
          width: 16%;
        }
        &:nth-child(2) {
          width: 10%;
          max-width: 6rem;
          padding: 0rem;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 60%;
          padding: 1.4rem 1.6rem 1.4rem 22rem;
        }
        .column-content {
          display: flex;
          align-items: center;
          .content {
            margin-right: 0.8rem;
            font-family: Mon-bold, sans-serif;
            font-size: 1.4rem;
          }

          &-disable-cursor {
            cursor: not-allowed;
          }
        }
      }
    }
    &-body {
      background-color: #fff;
      box-sizing: border-box;
      display: block;
      height: calc(100vh - 44rem);
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      border: 0.1rem solid #eef0f6;
      border-radius: 0.8rem;
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
      &.filter-applied {
        height: calc(100vh - 50.2rem);
      }
      & > .infinite-scroll-component__outerdiv > .infinite-scroll-component {
        overflow: overlay !important;
      }
      .episode-activity-listing-row {
        display: flex;
        flex-direction: row;
        box-shadow: 0 0.1rem 0 0 #eef0f6;
        .column {
          box-sizing: border-box;
          word-break: break-word;
          padding: 1.6rem;
          display: flex;
          font-family: "Mon-medium", sans-serif;
          flex-direction: column;

          font-size: 1.4rem;

          &.date-time-col {
            width: 16%;
            .date {
              color: #333;
            }
            .time {
              color: #00000066;
              font-size: 1.2rem;
            }
          }
          &.task-info-col {
            width: 20%;
            .task-name {
              font-family: "Mon-semi-bold", sans-serif;
              font-size: 1.4rem;
              color: #00887c;
            }
            .task-status,
            .care-plan-dates,
            .done-by {
              color: #00000066;
              font-size: 1.2rem;
              display: flex;
              gap: 0.4rem;
              span {
                color: #333;
              }
            }
            .task-status {
              &-label {
                min-width: fit-content;
              }
              span {
                text-transform: capitalize;
                &.red-color {
                  color: #b91d1d;
                }
                .toc-approve-message {
                  width: 100%;
                  color: #00000066;
                  display: block;
                  margin-bottom: 0.2rem;
                  text-transform: none;
                  font-style: italic;
                }
              }
            }
          }
          &:nth-child(2) {
            width: 10%;
            max-width: 6rem;
            padding: 1.8rem 0;
            align-items: end;
            & > svg:not(:last-child) {
              margin-bottom: 1rem;
            }
          }
          &:nth-child(4) {
            width: 60%;
            display: flex;
            justify-content: start;
            padding: 1.6rem;
          }
          .content {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
          }
          &.status {
            .content {
              flex-direction: column;
              align-items: unset;
              justify-content: center;
              .status-type {
                width: fit-content;
                padding: 0.2rem 0.2rem;
                font-size: 1.2rem;
                font-family: "Mon-medium", sans-serif;
                margin-top: 0.5rem;
                color: rgba(0, 0, 0, 0.4);
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}
