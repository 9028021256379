.category {
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #101828;
  width: 100%;
}

.risk-score {
  font-family: "Inter";
  font-size: 1.4rem;
  line-height: .4rem;
  color: #101828;
}

.risk-level-pill {
  height: 2.2rem;
  border-radius: 1.6rem;
  padding: .2rem .8rem .2rem .8rem;
  display: inline-block;
  line-height: 2rem;
  &.high {
    background-color: #fff0f0;
    color: #f87171;
  }
  &.standard {
    color: #027a48;
    background: #ecfdf3;
  }
}
