@import "shared/assets/scss/variables.scss";

.container {
  display: flex;
  .revisons {
    width: 100%;
    .heading {
      display: flex;
      justify-content: space-between;
      .info-container {
        .patient-info {
          margin-bottom: 10px;

          .name {
            color: #1aa99c;
            font-size: 16px;
            text-decoration-line: underline;
            margin-right: 12px;
            font-family: "Mon-bold", sans-serif;
          }
          .info {
            font-size: 14px;
            color: #636d70;
            font-size: 14px;
            font-family: "Mon-medium", sans-serif;
          }
        }
        .text {
          font-family: "Mon-bold", sans-serif;
          font-size: 24px;
          line-height: 24px;
          color: #64666a;
        }
      }

      .cta-container {
        .new-revision-container {
          .new-revision-cta {
            width: 216px;
            height: 40px;
            font-size: 16px;
          }
        }
      }
    }
    .revision-table {
      &-container {
        .toc-revision-default-width {
          min-width: unset !important;
          width: 45% !important;
          margin-left: 0rem;
        }
        &.task-info-tab-visible {
          // width: calc(100vw - 52rem); //width should be 100% when integrated with task info tab branch
          .note-episode-container {
            width: 100%;
            margin-right: 2rem;
            margin-left: 2rem;
          }
          .location-grid-container {
            width: 100%;
            margin-right: 2rem;
            margin-left: 2rem;
          }
        }
      }
      .revision-table-header-scrollbar-is-visible {
        .revision-table-header-scrollbar-is-visible:nth-child(4) {
          width: 48%;
        }
      }

      .revision-table-header {
        display: flex;
        position: sticky;
        top: 0;
        left: 0;

        text-align: left;
        font-size: 1.4rem;
        padding: 1.5rem;
        border: 0.1rem solid $primary-border-color;
        font-family: "Mon-bold", sans-serif;

        font-weight: bold;
        color: $grey-text-color;
        background: rgb(241, 250, 249);
        border-radius: 0.8rem 0.8rem 0 0;
        border-style: hidden;
        box-shadow: 0 0 0 0.1rem $primary-border-color;
        &.revision-table-header-scrollbar-is-visible {
          .revision-table-header-cell:nth-child(4) {
            width: 46.4%;
          }
          &.task-details-visible .revision-table-header-cell:nth-child(4) {
            width: 47%;
          }
        }
        .revision-table-header-cell {
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            padding-right: 0px;
            width: 20%;
          }
          &:nth-child(3) {
            padding-right: 0px;
            width: 15%;
          }
          &:nth-child(4) {
            padding: 0px;
            width: 20%;
          }
          &:nth-child(5) {
            border-radius: 0 0.8rem 0 0;
            padding-right: 0px;
            width: auto;
          }
        }
      }

      .revision-table-body {
        overflow: overlay !important;
        background-color: #fff;
        box-sizing: border-box;
        display: block;
        height: calc(100vh - 20rem);
        width: 100%;
        border: 1px solid #eef0f6;
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        td {
          text-align: left;
          padding: 1.5rem;
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            padding-right: 0px;
            width: 20%;
          }
          &:nth-child(3) {
            padding-right: 0px;
            width: 15%;
          }
          &:nth-child(4) {
            padding: 0px;
            width: 20%;
          }
          &:nth-child(5) {
            padding-right: 0px;
            width: auto;
          }
        }
      }
    }
  }
}
