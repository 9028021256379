.task-details {
  background: #ecf2f2;
  border-left: 0.1rem solid rgba(51, 51, 51, 0.1);
  padding: 1rem 1.6rem 1rem 1.6rem;
  width: 25vw;
  min-width: 41rem;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .navigatorSection {
    .navigatorTabs {
      margin-bottom: 2.4rem;
      width: 100%;
      .active {
        cursor: pointer !important;
      }
      .tabs {
        text-align: center;
        cursor: not-allowed;
        width: 25%;
        &.activity-log-task-info-tab {
          width: 40%;
        }
        &.care-utilization-task-info-tab {
          min-width: fit-content;
        }
      }
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.4rem 0 2.4rem 0;
    &-text {
      font-family: "Mon-bold", sans-serif;
      color: #333333;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      &-icon {
        margin-left: 0.8rem;
        cursor: pointer;
      }
    }
    &-icons {
      display: flex;
      align-items: center;
      .task-info-icon {
        height: 3.2rem;
        width: 3.2rem;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
        &.call-icon {
          margin-right: 2rem;
        }
        &.call-icon-active {
          color: white;
          background: #1aa99c;
        }
      }
    }
  }
  .call-message-error-state {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: #ff5757;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.2rem;
    line-height: 140%;
    column-gap: 1rem;
    margin-bottom: 1.6rem;
  }

  .heading {
    font-family: "Mon-bold", sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #333333;
    margin-bottom: 1.6rem;
  }
}
