@import "../../shared/assets/scss/variables.scss";
#navigator-management-container {
  height: 100%;
  .loading-container {
    height: calc(100vh - 31rem);
  }
  .navigator-management-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .navigator-management-buttons {
      .add-navigator-button {
        height: 4rem;
        width: 17.8rem;
        color: $white;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-left: 1.6rem;
        cursor: pointer;
      }
      .set-on-call-navigator {
        height: 4rem;
        width: 20rem;
        color: $grey-text-color;
        font-size: 1.6rem;
        line-height: 2rem;
        cursor: pointer;
      }
    }
  }
}

.mobileView {
  #navigator-management-container {
    .navigator-management-header {
      .header-title {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
