@import "../../shared/assets/scss/variables.scss";
.profile-pic-container {
  position: relative;
  border-right: 1px solid #f4eded;
  padding-right: 2rem;
  width: 25%;
  .empty-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 30vh;
    .upload-text {
      position: absolute;
      bottom: 20%;
      color: $white;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    .empty-profile-image {
      cursor: pointer;
      margin-bottom: 0.8rem;
    }
    .profile-picture-text {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: $grey-text-color;
    }
  }
}

#modal {
  .photo-editor-modal {
    .photo-editor-content {
      width: 50rem;
      height: 50rem;
      padding: 2rem;
      border-radius: 1rem;

      .modal-header {
        #modal-header {
          margin-bottom: 1rem;
          header {
            margin: auto;
          }
        }
      }

      .modal-body {
        display: flex;
        justify-content: center;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .crop-button {
            width: 10rem;
            height: 4rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

.mobileView {
  .profile-pic-container {
    margin: 0 auto;
    border: none;
    width: 100%;
    padding: 0;
    .empty-image-container {
      min-height: auto;
    }
  }
}
