#misc-tab-container {
  position: relative;
  margin-top: 3.6rem;
  .misc-body {
    height: calc(100vh - 45rem);
    overflow-y: auto;
    overflow-x: hidden;

    &-content {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      &-field {
        .checkbox-label.disabled {
          opacity: 0.4;
          cursor: not-allowed;
          width: fit-content;
        }
        &.input-field {
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
          &.notes {
            width: 78.6rem;
          }
        }
        .field-label {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          margin-bottom: 0.5rem;
          color: #333;

          &.label-multiple-fields {
            margin-bottom: 1.5rem;
          }
          span.red-color {
            color: red;
            font-family: "Mon-medium", sans-serif;
          }
          &.field-sub-label {
            font-family: "Mon-medium", sans-serif;
          }
        }

        .error-character-length-container {
          column-gap: 1.6rem;
          display: flex;
          justify-content: space-between;

          .text-length {
            color: #767676;
            font-family: "Mon-bold", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
            margin-bottom: 1rem;
          }
        }
      }
      .text-area {
        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 1;
        border: 0.1rem solid #c1c9d2cc;
        border-radius: 0.8rem;
        padding: 1rem;
        resize: vertical;
        min-height: 4rem;
        font-family: "Mon-medium", sans-serif;
        width: 100%;
        box-sizing: border-box;
        &::placeholder {
          color: #00000066;
        }
        &:disabled {
          border: 0.1rem solid #c1c9d2;
          background: #f0f0f0;
          color: #333333;
          font-size: 1.4rem;
          resize: none;

          cursor: not-allowed;
        }
      }
    }
  }
  .misc-footer {
    position: fixed;
    height: 7rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    background: #ffffff;
    box-shadow: 0 -0.7rem 1.2rem rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .questions-footer-button-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      .footer-buttons-div-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .submit-button {
          border-radius: 0.8rem;
          background: #1aa99c;
          padding: 1rem 1.6rem;
          outline: none;
          border: none;
          color: #fff;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.6rem;
          cursor: pointer;
          height: 4.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 14.6rem;
          .button-loader-img {
            margin-left: unset;
          }
          &.create-button {
            width: 8.6rem;
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .cancel-button {
          border-radius: 0.8rem;
          border: 0.1rem solid #acc0bb;
          background: #fff;
          padding: 1rem 1.6rem;
          outline: none;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.6rem;
          cursor: pointer;
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
