@import "../../../shared/assets/scss/variables.scss";

.logout-popup {
  .modal-main {
    border-radius: 0.8rem;
  }
}

.logout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45rem;
  height: 17.9rem;
  background: #ffffff;
  border: 0.1rem solid rgba(51, 51, 51, 0.2);
  border-radius: 0.8rem;
  .header {
    font-family: "Mon-bold", sans-serif;
    font-size: 1.6rem;
    padding: 2.4rem 0;
    color: #333333;
  }
  .content {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    color: #333333;
  }
  .button-section {
    display: flex;
    flex-direction: row;
    margin: 2.4rem 0;
    .cancel-button {
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 1rem;
      width: 12.2rem;
      height: 4rem;
      font-size: 1.6rem;
      cursor: pointer;
      margin-right: 0.8rem;
    }
    .reload-button {
      font-size: 1.6rem;
      width: 12.2rem;
      height: 4rem;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

#modal {
  .logout-popup.mobileView {
    background: rgba(0, 0, 0, 0.6);
    .logout-container {
      width: 32rem;
      .button-section {
        width: calc(100% - 3.2rem);
        padding: 0 1.6rem;
        .white-button {
          margin-right: 1.2rem;
        }
        .green-button,
        .white-button {
          width: 50%;
          height: 4.8rem;
        }
      }
    }
  }
}
