#risk-assesment-container {
  font-family: "Mon-semi-bold", sans-serif;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #333333;
  height: 100%;
  overflow: hidden;

  .active-tab-with-top-line {
    height: 8.5rem;
  }
  .assessment-question-container {
    height: calc(100vh - 31rem);
    overflow: auto;
    &.no-answer-delay-note-added {
      height: calc(100vh - 42rem) !important;
    }
    .conclusion-container {
      width: 96%;
      .conclusion-line {
        opacity: 0.1;
        background-color: #000;
        height: 0.1rem;
        width: 100%;
        margin: 3.6rem 0 3.6rem 0;
      }
    }
    .question-section {
      margin-bottom: 3.6rem;

      &-title {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: #333333;
        margin-bottom: 1.2rem;
      }
      &-note {
        display: inline-block;
      }
      &-checkbox-grid {
        display: flex;
        column-gap: 2.4rem;
        row-gap: 1rem;
        flex-grow: 1;
        flex-flow: wrap;
        width: 100%;

        .no-pointer-events {
          cursor: not-allowed;
        }
      }
      .not-selected-error-message {
        margin-top: 1.45rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #ff5757;
        display: flex;
        align-items: center;
        column-gap: 0.65rem;
      }
    }
    .visible {
      display: inherit;
    }

    .hidden {
      display: none !important;
    }
  }
}

@media (max-width: 1600px) {
  .assessment-question-container {
    height: calc(100vh - 41rem);
    &.no-answer-delay-note-added {
      height: calc(100vh - 52rem) !important;
    }
  }
}
