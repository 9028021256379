.audio-player-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  .close-button {
    font-size: 2.6rem;
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer;
  }
  .rhap_container {
    box-shadow: none;
    background-color: #eeeeee;
    font-family: "Mon-medium", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding: 0;
    .rhap_volume-bar {
      .rhap_volume-filled {
        background-color: #1aa99c;
      }
    }
    .rhap_additional-controls {
      display: none;
    }
    .rhap_main-controls {
      .rhap_rewind-button {
        display: none;
      }
      .rhap_forward-button {
        display: none;
      }
    }
    .rhap_volume-controls {
      display: block;
      .rhap_volume-button {
        color: #1aa99c;
      }
      .rhap_volume-bar-area {
        .rhap_volume-bar {
          background-color: #b1ede7;
          .rhap_volume-indicator {
            background-color: #1aa99c;
          }
        }
      }
    }
    .rhap_progress-section {
      margin-left: 6%;
      .rhap_progress-bar {
        height: 0.6rem;
      }
      .rhap_progress-filled {
        background-color: #1aa99c;
        border-radius: 0.7rem;
      }
      .rhap_download-progress {
        background-color: #b1ede7;
      }
      .rhap_progress-bar-show-download {
        background-color: white;
        border-radius: 0.7rem;
      }
      .rhap_progress-indicator {
        background-color: #1aa99c;
      }
    }
    .rhap_controls-section {
      .rhap_main-controls-button {
        color: #1aa99c;
      }
    }
  }
}
