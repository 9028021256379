@import "shared/assets/scss/variables.scss";

.mobileView {
  .notification-container {
    .notification {
      padding: 1.6rem 0;
      font-size: 1.4rem;
    }
    .notification-content {
      display: flex;
      width: 70%;
      align-items: flex-start;
      flex-direction: column-reverse;
      justify-content: center;
      flex-grow: 0;
    }
    .notification-title {
      width: 100%;
      line-height: 14px;
      padding: 0;
    }
    .notification-right-caret {
      z-index: 4;
      scale: 0.8;
      rotate: 180deg;
      margin-right: 1.5rem;
    }

    .new-message-img-container,
    .new-toc-img-container {
      scale: 1;
      margin-left: 1.6rem;
    }

    .missed-call-img-container {
      background: #fd9788;
      margin-left: 1.6rem;
      scale: 1.1;
    }

    .new-message-img,
    .new-toc-img {
      scale: 1.4;
    }

    .infinite-scroll-component {
      height: calc(100vh - 22rem) !important;
    }
  }
  .empty-notifications-container {
    height: calc(100vh - 38rem);
  }

  .empty-notification-img {
    height: 14.5rem;
    width: 14.5rem;
  }
}

.empty-notifications-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: calc(100% - 6rem);
}

.empty-title {
  color: #333;
  text-align: center;
  font-family: "Mon-bold", sans-serif;
  font-size: 1.4rem;
  margin: 1.3rem 0 0.6rem;
}

.empty-content {
  display: flex;
  width: 18.7rem;
  flex-direction: column;
  flex-shrink: 0;
  color: #333;
  text-align: center;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  line-height: normal;
}

#modal {
  .side-popup-content {
    width: 50rem;
  }
  .chat-side-popup {
    .modal-header {
      height: 8%;
    }
    .modal-body {
      height: 91% !important;
    }
    .chat-content {
      position: relative;
      height: 92%;
      .chat-messages {
        &.height {
          height: 88%;
        }
        &.reduce-height {
          height: 76%;
        }
      }
      #chat-messages {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .preview-section {
        &.hidden {
          display: none;
          opacity: 0;
        }
        &.show {
          display: flex;
          opacity: 1;
          width: 100%;
          bottom: 7.6rem;
          position: absolute;
          background: #dbe7e5;
          align-items: center;
        }
      }
      .chat-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #ffffff;
        border: 0.1rem solid #e6e6e6;
        box-shadow: 0 0 0.6rem rgba(193, 199, 204, 0.3);
        border-radius: 0.2rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input[type="file"] {
          display: none;
        }
        label {
          cursor: pointer;
        }
        .chat-text-container {
          position: relative;
          width: 92%;
          background: #dbe7e5;
          .attachment {
            position: absolute;
            right: 1rem;
            top: 1.5rem;
            opacity: 0.5;
          }
          .chat-text-box {
            background: #dbe7e5;
            border: 0.1rem solid #e5ede7;
            border-radius: 0.4rem;
            height: 4.2rem;
            width: 87%;
            outline: none;
            padding-left: 1rem;
          }
        }
        .send {
          width: 8%;
          align-self: center;
          margin-right: 1.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .mobileView {
    &.new-message {
      .side-popup-content {
        width: 100%;
      }
      .modal-body {
        height: calc(100% - 8.3rem) !important;
      }
    }
    &.chat-side-popup {
      .side-popup-content {
        width: 100%;
        .header {
          align-items: center;
          padding: 1.7rem 1.8rem;
          margin-bottom: 0;
          .heading {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
      }
      .modal-header {
        height: 5.4rem !important;
        box-shadow: 0 0 1rem rgba($black, 0.1);
      }
      .modal-body {
        height: calc(100% - 5.4rem) !important;
      }
      .chat-content {
        .chat-footer {
          padding: 1rem 1.5rem 1rem 2rem;
          box-sizing: border-box;
        }
      }
    }
  }
}
