.task-management-header {
  &-title {
    font-family: "Mon-bold", sans-serif;
    font-size: 2.4rem;
    line-height: 2.9rem;
    letter-spacing: 0em;
    color: #64666a;
  }
  .patient-details {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .patient-name-container {
      gap: 0.8rem;
      display: flex;
      align-items: center;
    }
    .patient-name {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      letter-spacing: 0rem;
      color: #1aa99c;
    }
    .patient-other-details {
      display: flex;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0rem;
      gap: 0.8rem;
      margin-left: 0.8rem;
      color: #636d70;

      .dash {
        font-family: "Mon-light", sans-serif;
      }

      .dob {
        font-family: "Mon-bold", sans-serif;
      }
    }
  }
}
