.multiple-task-modal {
  backdrop-filter: blur(5px);
  .modal-main {
    border-radius: 0.8rem;
  }
  .multiple-task-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 43.3rem;
    // height: 17.9rem;
    background: #ffffff;
    border: 0.1rem solid rgba(51, 51, 51, 0.2);
    border-radius: 0.8rem;
    .header {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.6rem;
      padding: 2.4rem 0 1.3rem 0;
      color: #333333;
    }
    .content {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      color: #333333;
      margin: 0 3rem;
      text-align: center;
    }
    .button-section {
      display: flex;
      flex-direction: row;
      margin: 2.4rem 0;
      .left-button {
        font-family: "Mon-medium", sans-serif;
        color: #333333;
        padding: 1rem;
        width: 10rem;
        height: 4rem;
        font-size: 1.6rem;
        cursor: pointer;
        margin-right: 0.8rem;
        border: 1px solid #acc0bb;
      }
      .right-button {
        font-size: 1.6rem;
        width: 10rem;
        height: 4rem;
        cursor: pointer;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
