@import "shared/assets/scss/variables.scss";

.notification-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  background: white;
  height: 8rem;
}

.mobile-notification-text {
  color: $grey-text-color;
  display: block;
  width: 100%;
}

.mobileView {
  .notification-container {
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .mark-all-read-btn {
      color: #077269;
      font-size: 1.2em;
      font-family: "Mon-medium", sans-serif;
      line-height: 14px;
      text-decoration-line: underline;
      cursor: pointer;
      padding: 0;
    }
  }
  .mobile-notification-title {
    display: flex;
    align-items: center;
    font-family: "Mon-semi-bold", sans-serif;
    font-size: 2.2rem;
    line-height: 2.7rem;
    text-align: center;
    color: #3d3d3d;
    width: 100%;
    margin-top: 0.8rem;
    margin-bottom: 3.6rem;
    .back-to-home-icon {
      margin-right: 0.5rem;
      cursor: pointer;
      path {
        stroke: #3d3d3d;
      }
    }
  }
}
