.tabs-container {
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;
  position: relative;
  .tab-container {
    border-radius: 8px 8px 0px 0px;
    padding: 8px 10px 10px 12px;
    width: 140px;
    margin-right: 3px;
    cursor: pointer;
    #tab-context {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      width: auto;
      .title-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 130px;
        .offline {
          margin-right: 5px;
        }
        .title {
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 120px;
        }
      }

      .close-icon {
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .active {
    background: #fafafa;
    color: #333;
  }
  .inactive {
    background: rgba(250, 250, 250, 0.52);
    color: #636d70;
  }
}

@media (max-width: 1720px) {
  .tabs-container {
    display: flex;
    flex-direction: row;
    align-items: end;
    width: 100%;
    position: relative;
    .tab-container {
      border-radius: 8px 8px 0px 0px;
      padding: 8px 10px 10px 12px;
      width: 105px;
      margin-right: 3px;
      cursor: pointer;
      #tab-context {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        width: auto;
        .title-info {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 110px;
          .offline {
            margin-right: 5px;
          }
          .title {
            font-family: "Mon-medium", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 75px;
          }
        }

        .close-icon {
          height: 15px;
          width: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .active {
      background: #fafafa;
      color: #333;
    }
    .inactive {
      background: rgba(250, 250, 250, 0.52);
      color: #636d70;
    }
  }
}
