.episodes-container {
  .episodes-table {
    height: calc(100vh - 20rem);
    .episodes-table-container {
      .table-with-pagination {
        &-header .column,
        &-body .column {
          padding: 1.1rem 1.6rem;
          box-sizing: border-box;
          &:first-child {
            width: 30rem;
            max-width: 30rem;
          }
          &:nth-child(2) {
            width: 24rem;
            max-width: 24rem;
          }
          &:nth-child(3) {
            width: 12rem;
            justify-content: end;
          }
          &:nth-child(4) {
            width: 18rem;
          }
          &:nth-child(5) {
            width: 30rem;
          }
          &:nth-child(6) {
            width: 30rem;
          }
          &:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 144rem);
          }
        }
        &-header {
          .column {
            min-height: 5.2rem;
          }
        }
        &-body {
          .column {
            min-height: 6.4rem;
          }

          .empty-message-container {
            display: flex;
            align-items: center;
            justify-content: center;
            .no-episodes-found-container {
              height: fit-content;
            }
          }
        }

        .edit-button {
          height: 3.2rem;
          width: 3.2rem;
          background-color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}
