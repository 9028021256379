@import "../../shared/assets/scss/variables.scss";

.multi-select-drop-down {
  display: flex;
  flex-direction: column;
  .multi-select-drop-down-content-container {
    position: relative;
    margin: 1.5rem 3.2rem 0 2.8rem;
  }
  .add-recipient-search-section {
    display: flex;
    flex-direction: row;
    height: 4rem;
    border: 0.1rem solid #a6d9ce;
    border-radius: 0.8rem;
    .input-field-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem 0.5rem 0.5rem 1.2rem;
      .input-fields {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .add-recipient-input {
          border: none;
          outline: none;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.5rem;
          margin-left: 1.545rem;
        }
      }
      .selected-dropdown-section {
        .selected-dropdown-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: $light-green-color-2;
          border-radius: 0.4rem;
          padding: 0.4rem 0.7rem 0.5rem 1.3rem;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.5rem;
          line-height: 2.2rem;
          .down-arrow-icon {
            padding: 0.4rem;
          }
          .up-arrow-icon {
            padding: 0.4rem;
          }
        }
        .grey-background {
          background: $light-green-color-2;
        }
      }
    }
  }
  .selected-dropdown {
    margin: 0.7rem 0 0 0;
    background: #dbe7e5;
    border-radius: 1.2rem;
    max-height: 32rem;
    overflow-y: auto;
    .dropdown-single-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2.2rem 2.4rem;
      .detail-section {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
      }
    }
    .dropdown-single-item:not(:last-child) {
      border-bottom: 0.1rem solid #cad5d3;
    }
  }
  .search-results-section {
    margin: 0.7rem 0rem 0 0;
    background: #ffffff;
    border-radius: 1.2rem;
    max-height: 32rem;
    overflow-y: auto;
    border: 1px solid #cccccc;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    width: 100%;
    .empty-state-container {
      height: 61px;
    }
    .loader img {
      width: 5rem;
    }
    .search-items {
      display: flex;
      flex-direction: row;
      padding: 2.2rem 2.4rem;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      cursor: pointer;

      &:hover {
        background-color: #f1faf9;
      }

      .name-with-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .name {
          width: 65%;
          word-break: break-all;
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
        }

        .phone-number {
          margin-top: 6px;
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          color: #333333;
          opacity: 0.6;
          white-space: pre-wrap;
        }

        .label {
          &-type {
            color: #333333;
            opacity: 0.4;
            font-family: "Mon-medium", sans-serif;
            font-size: 14px;
            line-height: 17px;
          }
          &-invalid-number {
            color: #f76659;
            margin-top: .2rem;
          }
        }
      }

      .highlighted-text-section {
        display: flex;
        flex-direction: row;
      }
      .bold-text {
        font-weight: bold;
        color: #1aa99c;
      }
    }
  }
}

.unsaved-add-tag {
  margin-right: 0.2rem;
}

.mobileView {
  .multi-select-drop-down {
    .add-recipient-search-section {
      margin: 1.5rem 1.6rem 0 1.6rem;
      .input-field-container {
        position: relative;
        .input-fields {
          .add-recipient-input {
            font-size: 1.6rem;
            padding: 0;
            &::placeholder {
              font-size: 1.4rem;
            }
          }
        }
        .selected-dropdown-section {
          position: absolute;
          right: 0;
          .selected-dropdown-button {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
