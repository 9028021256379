@import "../../shared/assets/scss/variables.scss";

.patient-search-box {
  width: 100% !important;
  border: 0.1rem solid #c1c9d2;
  height: 4rem;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #333333;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  mix-blend-mode: normal;
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 0.8rem;

  .input-plus-lens {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .search-lens-icon {
      margin-right: 1.2rem;
      transform: translateY(0.2rem);
    }

    .input-field {
      border: none;
      -webkit-appearance: none;
      outline: none;
      font-family: "Mon-medium", sans-serif;
      width: 100%;

      &::placeholder {
        color: $grey-text-color;
        opacity: 0.3;
      }
    }
  }

  .icon-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.3rem;
  }
}

.green-button {
  background: $primary-bg-color;
  border-radius: 0.8rem;
  border: none;
  color: white;
}

.white-button {
  background: $white-bg-color;
  border: 0.1rem solid hsl(165, 14%, 71%);
  border-radius: 0.8rem;
}

.icon-button {
  cursor: pointer;
}

.mobileView {
  .patient-search-box {
    .input-plus-lens {
      width: 100%;
      justify-content: start;
      .input-field {
        width: 100%;
      }
    }
  }
}
