@import "shared/assets/scss/variables.scss";

.message-read {
  width: fit-content;
  height: fit-content;
  &-participants {
    &-container {
      position: absolute;
      right: 2rem;
      height: fit-content;
      width: 26rem;
      border-radius: 0.8rem;
      border: 1px solid #cccccc;
      background: #ffffff;
      padding: 1.2rem;
      z-index: 2;
      display: none;
      cursor: default;
      .show-read-participants & {
        display: block;
      }
      @media (max-width: $mobile) {
        .show-read-participants & {
          position: relative;
          right: unset;
          bottom: unset;
          top: unset;
        }
      }
    }
    &-heading {
      margin: 0.5rem 0 1.5rem 0;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      color: #333333;
      font-weight: 700;
    }
    &-list {
      width: 100%;
    }
  }
  &-participant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 1rem 0;
    color: #333333;
    font-family: "Mon-medium", sans-serif;
    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
    &-details {
      display: flex;
      align-items: center;

      &--initials {
        margin-right: 1rem;
        font-weight: 700;
        color: #7e7e7e !important;
        background: #e9e7d7 !important;
      }
      &--name {
        word-break: break-word;
        text-transform: capitalize;
        max-width: 18rem;
      }
    }
    &-time {
      color: #667a78;
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
}
.show-read-participants {
  display: block;
}
