@import "shared/assets/scss/variables.scss";

.NavigatorMessageTable {
  .navigator-message-table-header {
    margin-bottom: 0;
    display: table;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: unset;

    &.hidden {
      display: none;
    }
    &-content {
      display: flex;
      align-items: center;
      width: fit-content;
      .name {
        margin-right: 8px;
      }
      &:hover {
        cursor: pointer;
      }
      &-disable-cursor {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
    tr {
      font-weight: bold;
      color: $grey-text-color;
      background: rgb(241, 250, 249);
      border-radius: 0.8rem 0.8rem 0 0;
      border-style: hidden;
      box-shadow: 0 0 0 0.1rem $primary-border-color;
      th {
        text-align: left;
        font-size: 1.4rem;
        padding: 1.5rem;
        border: 0.1rem solid $primary-border-color;
        border-style: solid none solid none;
        font-family: "Mon-bold", sans-serif;

        .table-header-content {
          display: flex;
          align-items: center;
          &-name {
            margin-right: 0.9rem;
          }
        }
      }
      th:nth-child(1) {
        text-align: left;
        border-left: 0.1rem solid $primary-border-color;
        border-radius: 0.8rem 0 0 0;
        width: 18%;
        padding: 1.5rem;
      }
      th:nth-child(2) {
        width: 15%;
      }
      th:nth-child(3) {
        text-align: left;
        width: 23%;
      }
      th:nth-child(4) {
        width: 45%;
      }
      th:last-child {
        border-style: solid solid solid none;
      }
    }
  }
  tbody > tr > td {
    padding: 0;
  }

  .message-no-border-in-mobile {
    box-shadow: unset;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
    border-radius: 0 0 0.8rem 0.8rem;
    box-sizing: border-box;
    box-shadow: 0 0 0 0.1rem #eef0f8;
    height: calc(100vh - 23rem) !important;
    .infinite-scroll-component {
      width: 100%;
      background-color: white;
    }
  }

  .navigator-message-table-body {
    .unread-message {
      font-family: "Mon-bold", sans-serif;
      background: #f0f4f4;
      font-weight: bold;
    }

    .loading-container {
      height: calc(100vh - 26rem);
    }
    .message-no-border-row {
      box-shadow: unset !important;
    }
    width: 100%;
    display: block;
    box-sizing: border-box;
    background-color: white;
    tr.no-message-container {
      display: table;
    }
    tr {
      width: 100%;
      display: table;
      box-shadow: 0 0.1rem 0 0 $grey-border-color;
      background-color: unset;
      td {
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding: 1.5rem;
        border-style: none;
        font-family: "Mon-medium", sans-serif;
        a {
          color: $primary-text-color;
          cursor: pointer;
        }
        span {
          .bold-text {
            font-weight: bold;
            color: #1aa99c;
          }
        }
      }
      td:nth-child(1) {
        text-align: left;
        width: 18%;
        padding: 1.5rem;
        word-break: break-word;
      }
      td:nth-child(2) {
        width: 15%;
        padding: 1.5rem 1.5rem 1.5rem 2.2rem;
        text-transform: capitalize;
        word-break: break-word;
        .subject-column {
          display: flex;
          flex-direction: row;
          align-items: center;
          .subject-icon {
            cursor: pointer;
            margin-left: 8px;
          }
        }
      }
      td:nth-child(3) {
        width: 23%;
      }
      td:nth-child(4) {
        text-align: left;
        width: 45%;
        position: relative;
        .second-last-column {
          display: flex;
          align-items: center;
          &-content {
            margin-left: 2.462rem;
            white-space: nowrap;
            word-break: break-word;
            overflow: hidden;
            width: 80%;
            text-overflow: ellipsis;
            position: absolute;
          }
        }
      }
      td:nth-child(5) {
        text-align: left;
        text-decoration: underline;
        .second-last-column {
          display: flex;
          align-items: center;
          &-content {
            margin-left: 2.462rem;
          }
        }
      }
      td:last-child {
        .last-column {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          &.visible {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            .table-icon {
              cursor: pointer;
            }
            .table-icon-disabled {
              opacity: 0.5;
            }
          }

          &.hidden {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }

          .table-icon {
            width: 3.2rem;
            height: 3.2rem;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
          .table-icon-disabled {
            opacity: 0.5;
          }
        }
      }
    }
    tr:last-child {
      border-radius: 0 0 0.8rem 0.8rem;
      border-style: hidden;
      box-shadow: 0 0 0 0.1rem $grey-border-color;
    }
    .hovered {
      background: #eeeeee;
    }
  }

  .navigator-messages-mobile-data {
    .infinite-scroll-component__outerdiv {
      height: calc(100vh - 30rem) !important;
      box-shadow: none;
      border-radius: 0;
      .infinite-scroll-component {
        &::-webkit-scrollbar-thumb {
          background: $white;
        }
      }
    }

    .navigator-message-table-body {
      tr {
        box-shadow: none;
        &:last-child {
          box-shadow: none;
        }
        td {
          border: 0;
          padding: 0 0 3rem 0;
          &:first-child {
            width: 5.4rem;
            padding-right: 1.5rem;
          }
          &:nth-child(2) {
            width: auto;
            padding-left: 1.5rem;
            .subject-container {
              .subject-text {
                font-size: 1.2rem;
              }
            }
          }
          .identity-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5.4rem;
            height: 5.4rem;
            background-color: $white-3;
            border: 0.1rem solid $white-4;
            border-radius: 100%;
            color: $grey-1;
            font-size: 1.7rem;
            line-height: 2.1rem;
            margin: 0.6rem;
          }
          .message-container {
            font-size: 1.4rem;
            line-height: 1.5;
            margin-bottom: 0.8rem;
            color: $grey-3;
            word-break: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .participants-and-time {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 0.6rem;
            strong {
              font-family: "Mon-bold", sans-serif;
              margin-bottom: 0.6rem;
              margin-right: 0.8rem;
            }
            .timestamp {
              margin-bottom: 0.6rem;
              margin-right: 0.8rem;
              font-size: 1.1rem;
              color: $grey-text-color;
            }
          }
          .subject-container {
            width: fit-content;
            background-color: #ccecf5;
            padding: 0.2rem 1.2rem;
            border-radius: 0.9rem;
            .subject-text {
              text-transform: capitalize;
              text-align: center;
              color: #667a78;
              font-family: "Mon-medium", sans-serif;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
