.note-for-stricked-task {
  display: flex;
  width: 30rem;
  flex-direction: column;
  gap: 0.2rem;
  .column-for-stricked-task {
    display: flex;
    gap: 1rem;
    .heading {
      color: rgba($color: #fff, $alpha: 0.6);
    }
  }
}
