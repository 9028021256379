.statistic-box-container {
  display: flex;
  background: #ffffff;
  border: .1rem solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 .4rem 1rem rgba(0, 0, 0, 0.05);
  border-radius: .8rem;
  padding: .2rem 0 .2rem 0;
  align-items: flex-start;

  &-stats {
    display: flex;
    flex-direction: column;
    margin-left: 1.6rem;
    align-self: center;

    &-heading {
      margin-bottom: 1.1rem;
      font-family: "Mon-medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #333333;
    }

    &-info {
      font-family: "Mon-bold", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: rgba(51, 51, 51, 0.8);
    }
  }

  &-icon-background {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transform: translateX(-.5rem);
  }
}
