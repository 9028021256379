.session-timer-container {
  position: absolute;
  background: #cce6f2;
  height: 2.3rem;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 8px 8px;
  justify-self: center;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0.4rem 0.8rem;
  flex-shrink: 0;

  .session-ends-in {
    color: #333;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    line-height: normal;
    margin-right: 1rem;
  }

  .timer-minutes {
    color: #333;
    font-family: "Mon-bold", sans-serif;
    font-size: 1.4rem;
    line-height: normal;
  }
}
