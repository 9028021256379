.line-container {
  border-bottom: .2rem solid rgba(167,221,206, 0.4);
  position: relative;
  display: flex;
  justify-content: center;
  margin: 1.5rem 2rem 1.2rem 2rem;
  z-index: 1;

  .time {
    position: absolute;
    top: -.7rem;
    background-color: white;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #000000;
    padding: 0 0.5rem;
  }
}
