.navigator-picker-container {
  width: 100%;
  position: relative;

  .navigator-selector-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;

    &.dashed {
      border: 1px dashed rgba(0, 0, 0, 0.2);
      justify-content: center;
    }

    &.selected {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      justify-content: space-between;
    }

    &.disabled {
      pointer-events: none;
      position: relative;
      top: -0.5rem;
    }

    &.pointer-disabled {
      cursor: not-allowed !important;
    }

    .loading-icon {
      width: 100%;
      text-align: center;
      color: #111111;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
    }

    .holiday {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .es-holiday {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        color: #6b727f;
      }
      .holiday-name {
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        line-height: 15px;
        color: #6b727f;
      }
    }

    .plus-icon-container {
      .plus-icon {
        color: #333333;
      }
    }
    .selected-value-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 1.2rem 0 1rem;
      .remove-button {
        z-index: 10;
      }
      .selected-value {
        font-family: "Mon-bold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        color: #333333;
        word-break: break-word;
        margin-right: 0.5rem;
      }
      .cross-icon {
        color: #333333;
      }
    }
  }
}

#modal {
  .navigator-list-modal {
    width: 200px;
    position: absolute;
    background-color: #fff;
    .navigator-list {
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      max-height: 236px;
      background-color: #fff;
      border: 1px solid #cccccc;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      z-index: 10;
      width: 100%;
      &-items {
        width: 100%;
        max-height: 195px;
        overflow-y: auto;
        overflow-x: hidden;
        .navigator-list-item {
          width: 100%;
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          line-height: 100%;
          color: #111111;
          padding: 1.2rem 0px 1.2rem 1.6rem;
          cursor: pointer;

          &:hover {
            background-color: #f1faf9;
          }
          &:first-child, &:last-child {
            &:hover {
              background-color: #f1faf9;
              border-radius: 8px;
            }
          }
        }
      }
      &-item-header {
        width: 100%;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        line-height: 100%;
        color: #111111;
        opacity: 0.4;
        padding: 1.2rem 0px 1.2rem 1.6rem;
      }
    }
  }
}
