#new-manual-task-container {
  .new-manual-task-component {
    .question-section {
      &-container {
        width: 70.8rem;
      }
      .manual-task-input-field {
        .label {
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 1.4rem;
          color: #000;
          margin-bottom: 0.6rem;
          line-height: normal;
        }
        margin-bottom: 1.6rem;
      }
      &-dropdown-container {
        .horizontal-line {
          color: silver;
          margin: 2.5rem 0;
          border-top: unset;
        }
      }
      &.taskOwner {
        .single-select-dropdown-container {
          flex-direction: column;
          align-items: start;
          .dropdown-title {
            margin-bottom: 1rem;
            font-family: "Mon-bold", sans-serif;
            font-size: 1.2rem;
            margin-bottom: 1rem;
          }
        }
      }

      &-title {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 1.6rem;
        color: #333333;
        margin-bottom: 1.2rem;
      }
      &-note {
        display: inline-block;
      }
      &.empty-task-type {
        color: #37373766;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
      }
      .error-container {
        height: 1.5rem;
        margin-top: 0.5rem;
      }

      .not-selected-error-message {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        color: #ff5757;
        display: flex;
        align-items: center;
        column-gap: 0.65rem;
      }

      &.date-selector-container {
        width: fit-content;
        display: inline-block;
        & .days-selector-container {
          flex-direction: column;
          align-items: start;
          .single-date-picker-selector-container {
            position: relative;
            display: flex;
            align-items: center;
            #single-date-picker-selector {
              .date-picker-input {
                opacity: 1;
                border: 0.1rem solid #c1c9d2cc;
                &::placeholder {
                  color: #33333366;
                }
              }
              & > div {
                display: flex;
                align-items: center;
                .calender-icon {
                  display: block !important;
                  position: absolute;
                  right: 1rem;
                  &:hover {
                    cursor: pointer;
                  }
                  &.disabled {
                    opacity: 0.4;
                    cursor: default;
                  }
                }
              }
              &.disabled-date-selector {
                input {
                  cursor: not-allowed;
                }
              }
            }
          }
          .date-picker-calender .react-datepicker__day-names {
            position: relative;
            padding: 0 3px;
            display: flex;
            align-items: center;
            justify-content: space-around;
          }

          &:first-child {
            margin-right: 3rem;
          }
          .checkbox-options-title {
            font-family: "Mon-bold", sans-serif;
            font-size: 1.2rem;
            line-height: 15px;
            letter-spacing: 0em;
            margin-bottom: 1rem;
          }
          .disabled-date-selector {
            .react-datepicker__input-container {
              .date-picker-input {
                border: 0.1rem solid #c1c9d2 !important;
                background: #f0f0f0 !important;
                color: #333;
                font-family: "Mon-medium", sans-serif;
                font-size: 1.2rem;
              }
            }
            .react-datepicker__close-icon {
              display: none;
            }
          }
        }
      }
      .text-area {
        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 1;
        border: 0.1rem solid #c1c9d2cc;
        border-radius: 0.8rem;
        padding: 1rem;
        resize: none;
        min-height: 2rem;
        font-family: "Mon-medium", sans-serif;
        &::placeholder {
          color: #00000066;
        }
        &:disabled {
          border: 0.1rem solid #c1c9d2;
          background: #f0f0f0;
          color: #333;
          font-size: 1.2rem;
          resize: none;
          cursor: not-allowed;
        }
      }

      .single-select-dropdown-container {
        display: flex;
        align-items: center;
        min-width: 30rem;
        width: 50%;
        margin-bottom: 1rem;
        .dropdown-title {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          color: #333333;
          margin-right: 3.6rem;
          flex-shrink: 0;
        }
      }

      .text-area-container {
        display: flex;
        column-gap: 1.6rem;
        flex-direction: column;
        .text-area-title {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          margin-bottom: 1rem;
          color: #767676;
        }
      }
      .text-selector-container {
        width: 100%;
        .text-title {
          font-family: "Mon-bold", sans-serif;
          font-size: 1.2rem;
          color: #333333;
          margin-bottom: 1rem;
          text-transform: capitalize;
        }
        .text-selector {
          width: inherit;
          height: 4rem;
          background: #ffffff;
          mix-blend-mode: normal;
          border: 0.1rem solid #c1c9d2cc;
          border-radius: 0.8rem;
          padding: 0.2rem 1rem;
          box-sizing: border-box;
          font-family: "Mon-medium", sans-serif;

          &:disabled {
            border: 0.1rem solid #c1c9d2;
            background: #f0f0f0;
            color: #333;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.2rem;
            cursor: not-allowed;
            line-height: 22px;
          }

          &::placeholder {
            font-family: "Mon-medium", sans-serif;
            color: #33333366;
            font-size: 1.4rem;
          }
        }
        .text-container-max-length {
          display: flex;
          column-gap: 1.6rem;
          justify-content: space-between;
          height: 1.5rem;
          .text-length {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            color: #767676;
          }
        }
      }

      .text-area-main-container {
        display: grid !important;
        .not-selected-error-message {
          margin-top: 0 !important;
        }
      }

      .text-area-container-max-length {
        display: flex;
        justify-content: end;
        column-gap: 1.6rem;
        margin-left: 7rem;
        height: 1.5rem;
        .text-area-length {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          color: #767676;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
