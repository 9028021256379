.no-answer-delay-notes {
  display: flex;
  padding: 0.6rem 1rem;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.4rem;
  border: 1px solid rgba(255, 122, 0, 0.1);
  background: rgba(255, 245, 190, 0.6);
  margin-bottom: 1.6rem;
  margin-top: 2.6rem;
  &-content {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  & .title {
    color: #ff7a00;
    font-size: 1.4rem;
    font-family: "Mon-semi-bold", sans-serif;
    line-height: normal;
    display: flex;
    gap: 0.4rem;
  }
  & .text {
    color: #333;
    font-size: 1.4rem;
    font-family: "Mon-medium", sans-serif;
    line-height: normal;
  }
}
