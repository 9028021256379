@import "shared/assets/scss/variables.scss";

#episodes-container {
  .episodes-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.8rem;
    margin-left: 0;
    .disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .right-section {
      display: flex;
      align-items: center;
      position: relative;
      .add-user-dropdown {
        position: absolute;
        top: 4rem;
        right: 0rem;
        width: 14.6rem;
        z-index: 3;
        border: 0.1rem solid #cccccc;
        border-radius: 0.8rem;
        .add-user-dropdown-item {
          display: flex;
          align-items: center;
          padding: 0.6rem 0.4rem 0.6rem 1.6rem;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 15px;
          background: #ffffff;
          height: 2.8rem;
          color: #111111;
          border-radius: 0.8rem;
          &:hover {
            cursor: pointer;
            background: #f1faf9;
            border-radius: 0.8rem;
          }
        }
      }
      .search-box.icon-button {
        cursor: auto;
        .icon-section:hover {
          cursor: pointer;
        }
      }
      .search-icon-container,
      .filter-icon-container {
        width: 4rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .filter-status-container {
        position: relative;
        .filtered {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #cb2020;
          position: absolute;
          right: -0.25rem;
          top: -0.25rem;
        }
      }
      & > div:first-child {
        margin-right: 1.6rem;
      }
      .add-user-button {
        height: 4rem;
        width: 14.6rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-left: 1.6rem;
        cursor: pointer;
      }
    }
  }
  .filter-applied-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .filter-text {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #687170;
    }
    .filters-applied {
      display: flex;
      margin: 0 1rem;
    }
    .clear-filter-button {
      color: #1aa99c;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      cursor: pointer;
    }
  }
}
.no-episodes-found {
  width: 25.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-container {
    height: calc(100vh - 14rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-heading,
  &-text {
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-size: 1.4rem;
    margin-top: 0.7rem;
  }
  &-text {
    font-family: "Mon-medium", sans-serif;
    text-align: center;
  }
  &-heading {
    font-family: "Mon-bold", sans-serif;
  }
}

.status-filter {
  margin-top: 4rem;
}

.toggleContainer {
  display: flex;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem !important;
  line-height: 1.7rem !important;
  color: #333333 !important;

  .toggleTitle {
    margin: 0 1.2rem;
  }

  .active {
    color: #1aa99c;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3.6rem;
    height: 1.6rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.1rem solid #cccccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    left: 0.2rem;
    bottom: 0.1rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: grey;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
    background-color: #1aa99c;
  }

  .slider.round {
    border-radius: 3.4rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.green-button-container {
  height: 4rem;
  width: 14.6rem;
  background: #1aa99c;
  margin-left: 1.6rem;
  border-radius: 0.8rem;
  justify-content: center;
  cursor: pointer;
  .green-button-input {
    background: #1aa99c;
    color: #fff;
    outline: none;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    border: none;
    padding: 0;
    margin-right: 1.6rem;
    cursor: pointer;
  }
  .up-arrow-icon,
  .down-arrow-icon {
    color: #fff;
  }
}
