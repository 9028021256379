@import "../../../shared/assets/scss/variables.scss";

#user-management-details-container {
  height: 90vh;
  .user-management-details-header {
    .breadcrumb-container {
      display: flex;
      .breadcrumb-item {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: medium;
      }
      .name::before {
        content: "  |  ";
        margin-left: 0.5rem;
        font-family: "Mon-medium", sans-serif;
        color: $grey-header-color;
      }
      .active {
        a {
          color: $primary-bg-color;
          text-transform: capitalize;
        }
      }
      .inactive {
        pointer-events: none;
        color: $grey-header-color;
      }
    }
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
      margin-top: 30px;
    }
  }

  .footer-button-container {
    position: fixed;
    height: 10vh;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    background: #ffffff;
    box-shadow: 0 -0.7rem 1.2rem rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .footer-button-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      .cancel-button {
        width: 16rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid #acc0bb;
        border-radius: 0.8rem;
        cursor: pointer;
        font-family: "Mon-semi-bold", sans-serif;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .save-button {
        width: 16rem;
        height: 4rem;
        font-family: "Mon-semi-bold", sans-serif;
        background: #1aa99c;
        border-radius: 0.8rem;
        border: none;
        color: white;
        cursor: pointer;
        &:disabled {
          background: #a3ddd7;
          cursor: not-allowed;
        }
      }
    }
  }
}

.toggleContainer {
  display: flex;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem !important;
  line-height: 1.7rem !important;
  color: #333333 !important;
  margin-top: 1.4rem;
  .toggleTitle {
    margin: 0 1.2rem;
  }

  .active {
    color: #1aa99c;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3.6rem;
    height: 1.6rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.1rem solid #cccccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    left: 0.2rem;
    bottom: 0.1rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: grey;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
    background-color: #1aa99c;
  }

  .slider.round {
    border-radius: 3.4rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
