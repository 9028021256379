.alert-container {
  margin-top: 1rem;
  padding: 1.45rem;
  background: rgba(255, 245, 190, 0.6);
  border: 1px solid rgba(255, 122, 0, 0.1);
  border-radius: 4px;
  width: auto;
  display: flex;
  flex-direction: row;

  .alert-icon-section {
    margin-right: 0.85rem;
  }

  .alert-details-section {
    width: 100%;
    .alert-title {
      font-family: "Mon-bold", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
    }
    .alert-list-section {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      color: #101828;
      .alert-list {
        list-style-type: disc;
        text-align: left;
        margin-left: 2rem;
        margin-top: 0.5rem;
      }
    }
  }
}
