.scale-container {
  height: 13rem;
  .scale-heading {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    line-height: 17px;
    letter-spacing: 0rem;
    margin: 2.4rem 0px 3.6rem 0px;
  }
  .reset-button {
    position: relative;
    left: 46rem;
    text-decoration: underline;
    color: #1aa99c;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .horizontal-slider {
    max-width: 41.7rem;
    &.disabled {
      .horizontal-slider-thumb-0,
      .horizontal-slider-thumb-1,
      .horizontal-slider-track {
        cursor: not-allowed;
      }
    }
    .horizontal-slider-thumb-0 {
      z-index: 1 !important;
    }
    .horizontal-slider-thumb-1 {
      z-index: 2 !important;
    }
    .horizontal-slider-thumb {
      height: 1.8rem;
      line-height: 18px;
      width: 1.8rem;
      text-align: center;
      background-color: #fff;
      color: #fff;
      border-radius: 50%;
      cursor: grab;
      border: 2px solid #1aa99c;
      top: -0.8rem;
      cursor: grab;
    }
    .horizontal-slider-track {
      background: #1aa99c;
      border-radius: 99.9rem;
      height: 0.5rem;
      max-width: 41.7rem;
      cursor: pointer;
    }
    .mark-scale {
      position: relative;
      .line {
        height: 2rem;
        width: 0.1rem;
        background-color: rgba(0, 0, 0, 0.1);
        color: red;
        transform: translate(1rem, 1rem);
      }
      .mark-value {
        color: rgba(0, 0, 0, 0.4);
        transform: translate(-0.4rem, 2rem);
        font-size: 1.4rem;
        font-family: "Mon-semi-bold", sans-serif;
      }
    }
  }
}
