.admin-container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.6rem;
  .practice-dropdown {
    &-container {
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }
    &-label {
      font-size: 1.4rem;
      font-family: "mon-semi-bold", sans-serif;
    }
    &-field-container {
      width: 26rem;
    }
  }

  .admin-tab {
    background-color: #fafafa !important;
  }
}
