.not-found-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader {
  margin: auto;

  img {
      width: 10rem;
  }
}

.button-text {
font-family: "Mon-bold", sans-serif;
font-size: 1.8rem;
line-height: 2.2rem;
color: rgba(51, 51, 51, 0.8);
margin-top: 2.1rem;
margin-bottom: 3rem;
}