#page-view-container {
  .task-render-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    .task-from-id {
      padding: 2.5rem 3.2rem 2.5rem 3.2rem;
      width: 100%;
      position: relative;
    }
  }
}
