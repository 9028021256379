@import "../../shared/assets/scss/variables.scss";

.green-button {
  background: $primary-bg-color;
  border-radius: 0.8rem;
  border: none;
  color: white;
  cursor: pointer;
}

.white-button {
  color: $secondary-text-color;
  background: $white-bg-color;
  border: 0.1rem solid hsl(165, 14%, 71%);
  border-radius: 0.8rem;
  cursor: pointer;
}

.no-border-button {
  border: none;
  outline: none;
  background: transparent;
  color: $primary-bg-color;
}

.green-button:disabled {
  opacity: 0.5;
}

.icon-button {
  cursor: pointer;
}

.text-for-icon:not(:empty) {
  margin-left: 0.6rem;
}

.img-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    margin-left: 1.2rem;
  }
}
