.activity-logs-filter-side-popup {
  .modal-main {
    .modal-header {
      margin-bottom: 0 !important;
      .header > .close-icon-container > .close-icon.show {
        width: 1.6rem !important;
        height: 1.6rem !important;
      }
    }
    .disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
    }
    .modal-body {
      height: 88% !important;
      padding-right: 0 !important;
      .filter-content {
        margin-top: 1rem !important;
        height: 100% !important;
        .bold-text {
          font-weight: bold;
          color: #1aa99c;
        }
        .filter-options {
          overflow-y: auto;
          margin-bottom: 1rem;
          padding-right: 1.6rem;
          scroll-margin-inline: 22px;
          .task-status-checkbox-container {
            & > div {
              &:not(:first-child) {
                margin-top: 1rem;
              }
            }
          }
          & > div {
            &:not(:first-child) {
              margin-top: 2.5rem;
            }

            .task-status {
              font-family: "Mon-semi-bold", sans-serif;
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #687170;
              margin-bottom: 0.8rem !important;
            }
          }
        }
      }
      .filter-buttons {
        padding-right: 1.6rem;
        .clear-button {
          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
