#admit-task-container {
  .task-ui-container {
    overflow: auto;
    height: calc(100vh - 28rem);
    &.no-answer-delay-note {
      height: calc(100vh - 37rem) !important;
    }
  }
  .form-section {
    margin: 1rem;
    margin-bottom: 1.5rem;

    .dropdown-container {
      width: 39rem;
      .no-pointer-events {
        cursor: not-allowed;
        color: #333333;
        opacity: 1;
        background: #f0f0f0;
        pointer-events: unset;
      }
    }

    .facility-dropdown-container {
      width: 39rem;
    }

    .admit-task-heading {
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 1.4rem;
      line-height: 17.07px;
      margin-bottom: 0.5rem;
      display: block;
    }
    .admit-task-sub-heading {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
    .astrick {
      color: red;
      font-family: "Mon-medium", sans-serif;
    }

    .admit-task-text-area {
      textarea {
        &::placeholder {
          color: #00000066;
        }
        &:disabled {
          border: 0.1rem solid #c1c9d2;
          background: #f0f0f0;
          color: #333333;
          font-size: 1.4rem;
          resize: none;

          cursor: not-allowed;
        }
      }
    }

    .disabled,
    input:disabled {
      color: #333333;
      opacity: 1;
      background: #f0f0f0;
      cursor: not-allowed;
    }
    .error-color {
      color: #ff6136;
      margin-top: 0.6rem;
      font-size: 1.2rem;
      font-family: "Mon-medium";
    }
    .estimated-actual {
      display: flex;
      gap: 1rem;
      .date-selector {
        font-family: "Mon-medium", sans-serif;
        .calender-icon {
          &.disabled {
            display: none !important;
          }
          display: block;
          position: absolute;
          right: 1rem;
          top: 2.4rem;
          transform: translateY(-50%);
          &:hover {
            cursor: pointer;
          }
          &.disabled {
            opacity: 0.4;
            cursor: default;
          }
        }
        .date-picker-mask-input.date-picker-input {
          width: 18rem;
          box-sizing: border-box;
          font-family: "Mon-medium", sans-serif;
        }
      }
    }
    .note-length {
      color: #767676;
      text-align: right;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
    }
  }
}
