$primary-bg-color: #1aa99c;
$primary-text-color: #00887c;
$primary-border-color: #bcdfef;
$secondary-text-color: #121213;
$grey-border-color: #eef0f6;
$grey-text-color: #333333;
$placeholder-text-color: #c1c1c1;
$white-bg-color: #ffffff;
$grey-header-color: #64666a;
$grey-sub-header-color: #687170;
$missed-call-color: #b91d1d;
$white: #ffffff;
$white-1: #fafafa;
$white-2: #e6ebf0;
$white-3: #dbe7e5;
$white-4: #d2e0e7;
$black: #000000;
$grey: #6b727f;
$grey-1: #787878;
$grey-2: #89979A;
$grey-3: #636D70;
$grey-4: #767676;
$grey-bg-color: #eeeeee;
$light-green-color: #f1faf9;
$red-notification-color: #ff6136;
$light-green-color-2: #e3f3ef;
$primary-input-border: #c1c9d2;
$error-color: #ff5757;
$light-black: #707070;
$light-grey: #C1C9D2;
$light-grey-1: #F0F0F0;
$shade-teal-green: #00887C;

// border
$night-border: rgba(30, 0, 148, 0.3);

// Breakpoints
$tablet: 1024px;
$xsTablet: 999px;
$mobile: 767px;
$mdMobile: 479px;
$smMobile: 376px;
$xsMobile: 340px;
