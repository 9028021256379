@import "shared/assets/scss/variables.scss";

.navigator-management-container-table {
  margin-top: 1.4rem;
  tbody {
    & > tr > td {
      padding: 0;
    }
    & > tr:last-child {
      box-shadow: unset;
      background: unset;
    }
    tr:nth-child(1) {
      td:nth-child(1) {
        table {
          margin-top: 0;
          tr {
            font-weight: bold;
            color: $grey-text-color;
            background: rgba(241, 250, 249, 0.6);
            border-radius: 0.8rem 0.8rem 0 0;
            box-shadow: 0 0 0 0.1rem $primary-border-color;
            border-bottom: 1px solid #bcdfef;
            th {
              font-size: 1.4rem;
              padding: 1.7rem 0 1.8rem 0;
              text-align: left;
              font-family: "Mon-bold", sans-serif;
            }
            th:first-child {
              width: 5.1rem;
            }
            th:nth-child(2) {
              width: 18%;
            }
            th:nth-child(3) {
              .navigator-management-table-header-content {
                text-align: right;
              }
              width: 15%;
              padding-right: 4rem;
            }
            th:nth-child(4) {
              width: 26%;
              padding-right: 4rem;
            }
            th:nth-child(5) {
              width: 30%;
            }
          }
        }
      }
    }
    .navigator-management-table-container {
      height: calc(100vh - 25rem);
      overflow-y: overlay;
      .navigator-management-table {
        margin-top: -0.1rem;
        .navigator-management-table-body {
          tr {
            &:last-child {
              box-shadow: unset !important;
              background-color: white;
            }
            &.night-border {
              border: 0.2rem solid $night-border;
            }
            td {
              font-size: 1.4rem;
              line-height: 1.6rem;
              padding: 1.7rem 0 1.8rem 0;
              text-align: left;
              border: 0.1rem solid $grey-border-color;
              border-style: solid none solid none;
              font-family: "Mon-medium", sans-serif;
              .navigator-name-container {
                display: flex;
                align-items: center;
                column-gap: 18px;
                &-content {
                  color: #00887c;
                  cursor: pointer;
                }
              }
              &.night-border {
                border-bottom: 0.2rem solid $night-border;
              }
              a {
                color: $primary-text-color;
                cursor: pointer;
              }
            }
            .online {
              background: #008f12;
            }
            .offline {
              background: #cb2020;
            }
            .forwarded {
              background: #ffa723;
            }
            td:first-child {
              border-style: solid none solid solid;
              width: 5rem;
              .work-hour-status {
                border-radius: 100%;
                width: 1rem;
                height: 1rem;
                margin: auto;
              }
            }
            td:nth-child(2) {
              padding-right: 0;
              width: 18%;
            }
            td:nth-child(3) {
              text-align: right;
              width: 15%;
              padding-right: 4rem;
            }
            td:nth-child(4) {
              width: 26%;
              padding-right: 4rem;
            }
            td:nth-child(5) {
              width: 30%;
              white-space: pre-wrap;
              .date-time-container {
                display: flex;
                align-items: center;
                .night-icon {
                  margin-left: 1.73rem;
                }
              }
            }
            td:last-child {
              border-style: solid solid solid none;
              padding-right: 1rem;
              .icons {
                display: flex;
                justify-content: flex-end;
                padding-right: 1.4rem;
                &.visible {
                  visibility: visible;
                  opacity: 1;
                  transition: opacity 0.3s ease-in-out;
                }
                &.hidden {
                  visibility: hidden;
                  opacity: 0;
                  transition: opacity 0.1s ease-in-out;
                }
                .icon-background {
                  height: 3.2rem;
                  width: 3.2rem;
                  background-color: white;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }
              }
            }
            .missed {
              color: $missed-call-color;
            }
          }
          tr:last-child {
            border-radius: 0 0 0.8rem 0.8rem;
            box-shadow: 0 0 0 0.1rem $grey-border-color;
            border-style: unset;
          }
          .hovered {
            background: #eeeeee;
          }
        }
      }
    }
  }
}
