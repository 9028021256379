#send-order-container {
  height: 100%;
  width: 100%;
  & > .task-not-found {
    height: calc(100vh - 29rem);
  }
  .send-order-title-with-label {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }
  .method-label {
    border-radius: .8rem;
    border: 1px #b9dbd8;
    display: flex;
    padding: .4rem .8rem;
    align-items: center;
    opacity: 0.8;
    background: #dbebe9;
    overflow: hidden;
    color: #636d70;
    box-sizing: border-box;
    text-overflow: ellipsis;
    font-family: "Mon-semi-bold", sans-serif;
    font-size: 1.2rem;
    line-height: normal;
  }
  .send-order-component {
    height: calc(100vh - 23rem);
    overflow-x: hidden;
    width: 100%;
    overflow-y: auto;
    padding-right: 0.5rem;
    &.no-answer-delay-note {
      height: calc(100vh - 32rem) !important;
    }
    .send-order-form-field {
      width: 100%;
      font-family: "Mon-medium", sans-serif;
      margin-bottom: 1rem;
      .field-label {
        font-family: "Mon-bold", sans-serif;
        font-size: 1.4rem;
        line-height: 17.07px;
        margin-bottom: 0.5rem;
        display: block;
        &.label-multiple-field {
          margin-bottom: 1.5rem;
        }
        span.red-color {
          color: red;
          font-family: "Mon-medium", sans-serif;
        }
        &.field-sub-label {
          font-family: "Mon-medium", sans-serif;
        }
      }
    }
    .send-order-form-row {
      display: flex;
      gap: 1rem;

      .send-order-form-column {
        .error-and-text-length-container {
          -webkit-column-gap: 1.6rem;
          column-gap: 1.6rem;
          display: flex;
          height: 1.7rem;
          justify-content: space-between;
          margin-top: 1rem;
          .not-selected-error-message {
            align-items: center;
            color: #ff5757;
            -webkit-column-gap: 0.65rem;
            column-gap: 0.65rem;
            display: flex;
            font-family: Mon-medium, sans-serif;
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
          .text-length {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: #767676;
          }
        }

        .send-order-text-field {
          margin-bottom: 0;
          &.disabled {
            opacity: 1;
            & .field-wrap {
              background: #f0f0f0;
              .input-field {
                background: #f0f0f0;
                opacity: 1;
              }
            }
          }
        }

        .date-picker-calender .react-datepicker__day-names {
          position: relative;
          padding: 0 3px;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }

        .disabled,
        input:disabled {
          color: #333333;
          opacity: 1;
          background: #f0f0f0;
          cursor: not-allowed;
        }

        &.service-type {
          width: 390px;
        }
        &.estimated-date,
        &.actual-date,
        &.send-date {
          font-family: "Mon-medium", sans-serif;
          .disabled-date {
            button {
              display: none;
            }
          }
          .calender-icon {
            display: block !important;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            &:hover {
              cursor: pointer;
            }
            &.disabled {
              opacity: 0.4;
              cursor: default;
            }
          }
          .date-picker-mask-input.date-picker-input {
            width: 18rem;
            box-sizing: border-box;
            font-family: "Mon-medium", sans-serif;
          }
        }

        &.send-method-type {
          width: 10rem;
        }
        &.send-method-value {
          width: 27.8rem;
        }
        &.preferred-send-date {
          width: 50.5rem;
        }
        &.provider {
          .facility-dropdown-container {
            width: 39rem;
          }
          .provider-custom-dropdown-container {
            width: 39rem;
          }
        }

        &.send-instruction,
        &.notes {
          width: 100%;
          .text-area {
            background: #ffffff;
            mix-blend-mode: normal;
            opacity: 1;
            border: 0.1rem solid #c1c9d2cc;
            border-radius: 0.8rem;
            padding: 1rem;
            resize: vertical;
            min-height: 4rem;
            font-family: "Mon-medium", sans-serif;
            width: 100%;
            box-sizing: border-box;
            &::placeholder {
              color: #00000066;
            }
            &:disabled {
              border: 0.1rem solid #c1c9d2;
              background: #f0f0f0;
              color: #333333;
              font-size: 1.4rem;
              resize: none;

              cursor: not-allowed;
            }
          }
        }

        .text-input-field-container {
          margin-bottom: 0;
          &.los {
            .field-wrap {
              width: 9.6rem;
            }
          }
          .field-title {
            line-height: 0;
            margin: 0;
          }
          .field-wrap {
            height: 4rem;
            width: 100%;
            .input-field {
              border: none;
            }
          }
        }
      }
    }
  }
}
