#send-order-container {
  .send-order-footer-container {
    position: fixed;
    height: 7rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    background: #ffffff;
    box-shadow: 0 -0.7rem 1.2rem rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    gap: 2rem;
    box-sizing: border-box;
    padding: 1.5rem 2.4rem;

    align-items: center;
    z-index: 2;
    .submit-button {
      border-radius: 0.8rem;
      background: #1aa99c;
      padding: 1rem 1.6rem;
      outline: none;
      border: none;
      color: #fff;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      cursor: pointer;
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 7.7rem;
      .button-loader-img {
        margin-left: unset;
      }
      &.create-button {
        width: 8.6rem;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .manager-approval-button,
    .cancel-button {
      border-radius: 0.8rem;
      border: 0.1rem solid #acc0bb;
      background: #fff;
      padding: 1rem 1.6rem;
      outline: none;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
