@import "../../shared/assets/scss/variables.scss";

.text-input-field-container {
  margin-bottom: 3rem;
  @media (max-width: $mobile) {
    margin-bottom: 2.2rem;
  }

  .field-wrap {
    position: relative;
    background: #ffffff;
    mix-blend-mode: normal;
    box-sizing: border-box;
    border-radius: 0.8rem;
    width: 30rem;
    height: 4.8rem;
    display: flex;
    align-items: center;

    &.black-border {
      border: 0.1rem solid #c1c9d2;
    }

    &.active-border {
      border: 0.1rem solid #5652ff;
      box-shadow: 0 0 1rem rgba(0, 87, 255, 0.25);
    }
    &:focus {
      border: 0.1rem solid #007aff;
      box-shadow: 0 0 1rem rgba(0, 87, 255, 0.25);
    }
  }
  .focused {
    border: 0.1rem solid #007aff;
    box-shadow: 0 0 1rem rgba(0, 87, 255, 0.25);
  }
  .error-wrap {
    color: $error-color;
    margin-top: 0.6rem;
    font-size: 1.2rem;
    font-family: "Mon-medium";
  }
  .field-title {
    color: #333333;
    text-transform: capitalize;
    margin-bottom: 0.7rem;
    display: block;
    font-family: "Mon-medium";
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin: 0.6rem 0;
    .red-color {
      margin-left: 0.5rem;
      color: red;
    }
  }
  &.password-field {
    margin-bottom: 2.5rem;
    & input {
      -webkit-text-security: disc;
    }
  }

  .red-border {
    border: 0.1rem solid $error-color;
  }
  .eye {
    cursor: pointer;
    padding-right: 1.4rem;
  }
  .input-field {
    color: $grey-text-color;
    border: none;
    margin-left: 1.2rem;
    width: 100%;
    height: 90%;
    border-radius: 0.8rem;

    &.red-border {
      // color: $error-color;
    }

    &::placeholder {
      color: $grey-text-color;
      opacity: 1;
    }
  }
}
