#notes-task-container {
  height: 100%;

  .task-management-header-title {
    text-transform: capitalize;
  }
  .notes-task-header {
    margin-left: 0px !important;
    text-transform: capitalize;
  }
  .common-state-container.loader {
    height: 100%;
    width: 100%;
  }
  #form-container {
    height: calc(100vh - 23rem);
    overflow: auto;
    .notes-fields-container {
      width: 70.8rem;
      display: flex;
      flex-direction: column;
      gap: 3.6rem;
      .notes-input-field {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .label {
          color: #000;
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 1.4rem;
        }
        .title-input {
          width: -webkit-fill-available;
          background: #fff;
          border: 0.1rem solid rgba(193, 201, 210, 0.8);
          border-radius: 0.8rem;
          font-family: Mon-medium, sans-serif;
          min-height: 2rem;
          mix-blend-mode: normal;
          opacity: 1;
          padding: 1rem;
          &:disabled {
            background: #f0f0f0;
            border: 0.1rem solid #c1c9d2;
            color: #000;
            cursor: not-allowed;
          }
        }

        .navigator-notes-input {
          width: -webkit-fill-available;
          background: #ffffff;
          mix-blend-mode: normal;
          opacity: 1;
          border: 0.1rem solid #c1c9d2cc;
          border-radius: 0.8rem;
          padding: 1rem;
          resize: vertical;
          min-height: 2rem;
          font-family: "Mon-medium", sans-serif;
          &::placeholder {
            color: #00000066;
          }
          &:disabled {
            border: 0.1rem solid #c1c9d2;
            background: #f0f0f0;
            color: #000;
            resize: none;
            cursor: not-allowed;
          }
        }
      }
    }
    .not-selected-error-message {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #ff5757;
      display: flex;
      align-items: center;
      column-gap: 0.65rem;
    }
  }

  .notes-task-footer-container {
    position: fixed;
    height: 7rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    background: #ffffff;
    box-shadow: 0 -0.7rem 1.2rem rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .footer-buttons-div-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .cancel-button {
        border-radius: 0.8rem;
        border: 0.1rem solid #acc0bb;
        background: #fff;
        padding: 1rem 1.6rem;
        outline: none;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        cursor: pointer;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}
