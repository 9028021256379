.episodes-filter-side-popup {
  .modal-main {
    .modal-header {
      margin-bottom: 0 !important;
      .header > .close-icon-container > .close-icon.show {
        width: 1.6rem !important;
        height: 1.6rem !important;
      }
    }
    .disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
    }
    .modal-body {
      height: 88% !important;
      padding-right: 0 !important;
      .filter-content {
        margin-top: 1rem !important;
        height: 100% !important;
        .bold-text {
          font-weight: bold;
          color: #1aa99c;
        }
        .filter-options {
          overflow-y: auto;
          margin-bottom: 1rem;
          padding-right: 1.6rem;
          scroll-margin-inline: 22px;
          .status-checkbox-container {
            & > div {
              &:not(:first-child) {
                margin-top: 1rem;
              }
            }
          }
          & > div {
            &:not(:first-child) {
              margin-top: 2.5rem;
            }
            .tag-search-dropdown-container {
              width: 100%;

              .select-span {
                border: 1px solid #acc0bb;
                padding: 1rem 0.5rem 1rem 1.2rem;
                .name-container > .search-box-wrapper {
                  height: 2rem;
                  margin: auto 0;
                  margin-left: 0.5rem;

                  .search-box.icon-button {
                    cursor: default;
                    padding: 0;
                    .search-lens-icon {
                      cursor: default;
                    }
                    .input-field::placeholder {
                      color: #636d70;
                      opacity: 0.6;
                    }
                  }
                }
              }
              .selected-option-tag {
                border: 1px solid #bcdfef;
              }
              .option-box-wrapper {
                width: 98%;
                top: 105%;
                max-height: 15rem;
                overflow-y: auto;
              }
            }
            .admit-date,
            .surgery-date {
              width: 100%;
              #date-picker-selector {
                width: 100%;
                z-index: 2;
                height: 4.3rem;
                & > div > svg {
                  width: 1.6rem !important;
                  height: 1.6rem !important;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 1.2rem;
                  color: #333333;
                  pointer-events: none;
                }
                .date-picker-input {
                  padding: 1rem 1.2rem;
                  border: 1px solid #bdcdc9;
                  opacity: 1;
                  cursor: pointer;
                  &::placeholder {
                    color: #33333366;
                  }
                }
                &:nth-child(2) {
                  right: 1.4rem;
                }
                #date-picker-container {
                  position: fixed;
                  top: unset;
                  right: 37rem;
                  display: none;
                }
                .date-picker-input {
                  width: 100%;
                  box-sizing: border-box;
                }
              }
            }
            .status,
            .pnav-label,
            .tnav-label,
            .physician-label,
            .sugery-date-label,
            .admit-date-label {
              font-family: "Mon-semi-bold", sans-serif;
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #687170;
              margin-bottom: 0.8rem !important;
            }
          }
        }
      }
      .filter-buttons {
        padding-right: 1.6rem;
      }
    }
  }
}
