@import "../../shared/assets/scss/variables.scss";

.active-tab-with-top-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  .single-tab {
    display: flex;
    flex-direction: column;
    margin-right: 0.4rem;
    width: 33%;
    .single-tab-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .need-action {
        color: #ff5757 !important;
        font-size: 1.4rem;
        line-height: 100%;
        font-family: "Mon-medium", sans-serif;
        margin-left: 0.5rem;
      }
      .top-line {
        width: 100%;
        height: 0.4rem;
        background-color: #c1c9d2;
        border-radius: 10rem;
      }
      .top-line-active {
        background-color: #1aa99c;
      }
      .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        margin-bottom: 1.2rem;
        cursor: pointer;
        color: #7e7e7e;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        &.active {
          color: #1aa99c;
        }
        .icon-div {
          display: flex;
        }
        input {
          cursor: pointer;
          appearance: none;
          height: 1.8rem;
          width: 1.8rem;
          border: 0.2rem solid #656565;
          border-radius: 50%;
          margin: 0 1rem 0 0;
          &:checked {
            background-repeat: no-repeat;
            background-position: center center;
            border: 0.6rem solid #1aa99c;
          }
        }
        .tick-in-black-background {
          margin-right: 0.8rem;
        }
      }
      .disabled-cursor {
        cursor: not-allowed !important;
      }
    }
  }
  .active {
  }
}
