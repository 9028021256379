.summary-container {
  &-header {
    font-family: "Mon-semi-bold", sans-serif;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #333333;
  }

  &-grid {
    display: grid;
    grid-template-columns: 30% 3.2rem 1fr;
    grid-template-areas:
      "chart . summary-table"
      ". . summary-table";

    .left-container {
      .gauge-chart-container {
        background-color: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.1);
        border-radius: 0.4rem;
        padding: 5.6rem 6.4rem 7.3rem 5.4rem;
        grid-area: chart;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        #gauge-chart {
          width: auto !important;
        }

        .chart-text {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-left: 0.7rem;
          margin-top: 4rem;
          .high-risk {
            font-family: "Mon-bold", sans-serif;
            font-size: 1.6rem;
            line-height: 2rem;
            text-transform: capitalize;
            color: #f87171;
          }
          .standard-risk {
            font-family: "Mon-bold", sans-serif;
            font-size: 1.6rem;
            line-height: 2rem;
            text-transform: capitalize;
            color: #027a48;
          }
          .action-needed {
          font-family: "Mon-bold", sans-serif;
          font-size: 1.2rem;
          line-height: 2rem;
          color: #64748b;
          background: #f7f8f9;
          border-radius: .4rem;
          text-align: center;
          margin-top: 1rem;
          }
        }
      }
    }
  }
  .summary-table-container {
    height: 70vh !important;
    grid-area: summary-table;
  }
}
