.provider-edit-modal {
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  .modal-main {
    border-radius: 0.8rem;
    .provider-edit-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #ffffff;
      border: 0.1rem solid rgba(51, 51, 51, 0.2);
      border-radius: 0.8rem;
      padding: 19px 25px;
      max-width: 61.6rem;

      .title {
        color: #333;
        text-align: center;
        font-family: "mon-semi-bold", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 2.4rem;
      }

      .form-body {
        .row {
          display: flex;
        }

        .col {
          margin-right: 1.6rem;
          width: 100%;
          &:last-child {
            margin-right: 0px;
          }
        }
        .disabled {
          border-radius: 8px;
          border: 1px solid #acc0bb;
          opacity: 0.8;
          cursor: not-allowed;
          background: #f0f0f0;
        }

        .dropdown-label {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: #333333;
          text-transform: capitalize;
          margin: 0.6rem 0;

          &.backup-navigator-label {
            margin-top: 3rem;
          }
          .optional-text {
            color: gray;
            margin-left: 4px;
          }
        }

        .assigned-privileges-dropdown-container {
          display: inline-flex;
          flex-direction: column;
          width: 100%;

          .assigned-privileges-selection {
            width: 100%;
            height: 100%;
            padding: 0.5rem;
            .text {
              max-width: 56.2rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .search-box-wrapper {
              width: 25rem;
            }
            .search-box.icon-button {
              width: 100%;
              background: transparent;
              .input-plus-lens {
                width: 100%;
                input {
                  width: 100%;
                }
              }
            }
          }
          .assigned-privileges-dropdown {
            max-height: 180px;
            overflow-y: auto;
            height: fit-content;
            width: 99.5%;
            right: 0;
            top: 100%;
          }
          .options-box {
            ul {
              li {
                font-size: 1.4rem;
                font-family: "Mon-Medium", sans-serif;
                &:last-child {
                  border-bottom: unset;
                }
                p:first-child {
                  margin-bottom: 0.2rem;
                  // font-weight: bold;
                }
                p:last-child {
                  color: #111111;
                }
              }
            }
          }
          .search-items {
            padding: 1.5rem;
            &-left {
              &__first {
                margin-bottom: 0.2rem;
                font-weight: bold;
              }
              &__second {
                color: #111111;
              }
            }
            &-right {
              div:first-child {
                margin-bottom: 0.2rem;
                font-size: 1.2rem;
              }
              div:last-child {
                color: #111111;
                font-size: 1.4rem;
              }
            }
          }
        }
      }

      .form-footer {
        display: flex;
        flex-direction: row;
        margin-top: 2.4rem;
        .submit-button {
          border-radius: 0.8rem;
          background: #1aa99c;
          padding: 1rem 1.6rem;
          outline: none;
          border: none;
          color: #fff;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.6rem;
          cursor: pointer;
          height: 4.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 9rem;
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .cancel-button {
          border-radius: 0.8rem;
          border: 0.1rem solid #acc0bb;
          background: #fff;
          padding: 1rem 1.6rem;
          outline: none;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.6rem;
          width: 9rem;
          margin-right: 1.2rem;
          cursor: pointer;
        }
      }
    }
  }
}
