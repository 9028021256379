@import "shared/assets/scss/variables.scss";

nav {
  font-size: initial;
  .breadcrumb {
    display: flex;
    .breadcrumb-item {
      font-family: "Mon-semi-bold", sans-serif;
      .name::before {
        content: "  |  ";
        margin-left: 0.5rem;
        font-family: "Mon-medium", sans-serif;
      }
    }
    .active {
      a {
        color: $primary-bg-color;
        text-transform: capitalize;
      }
    }
    .inactive {
      pointer-events: none;
      a {
        color: $grey-header-color;
      }
    }
  }
}
