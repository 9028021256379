@import "../../shared/assets/scss/variables.scss";

.search-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  mix-blend-mode: normal;
  border: 0.1rem solid #a6d9ce;
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 0.8rem;
  width: 28.4rem;
  margin-right: 1.6rem;
  .input-plus-lens {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .search-lens-icon {
      margin-right: 1.2rem;
      transform: translateY(0.2rem);
    }
    .input-field {
      width: 100%;
      border: none;
      -webkit-appearance: none;
      outline: none;
      padding-right: 0.4rem;
      font-family: "Mon-medium", sans-serif;
    }
  }
  .icon-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.3rem;
  }
}

.green-button {
  background: $primary-bg-color;
  border-radius: 0.8rem;
  border: none;
  color: white;
}

.white-button {
  background: $white-bg-color;
  border: 0.1rem solid hsl(165, 14%, 71%);
  border-radius: 0.8rem;
}

.icon-button {
  cursor: pointer;
}

.mobileView {
  .search-box {
    .input-plus-lens {
      width: 100%;
      justify-content: start;
      align-items: center;
      .search-lens-icon {
        transform: none;
      }
      .input-field {
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
        line-height: 1.2;
        background-color: transparent;
        &::placeholder {
          font-size: 1.4rem;
        }
      }
    }
  }
}
