.navigatorSection {
  display: flex;
  .navigatorTabs {
    display: flex;
    .allowed {
      cursor: pointer !important;
    }
    .tabs {
      padding: 1.15rem 2.4rem;
      font-size: 1.6rem;
      color: #606060;
      border-top: 0.1rem solid #eef0f6;
      border-bottom: 0.1rem solid #eef0f6;
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 1.4rem;
      cursor: pointer;
      background-color: #fff;
    }
    .tabs:first-child {
      border-left: 0.1rem solid #eef0f6;
      border-radius: 0.8rem 0 0 0.8rem;
    }
    .tabs:last-child {
      border-right: 0.1rem solid #eef0f6;
      border-radius: 0 0.8rem 0.8rem 0;
    }
    .active {
      background: #1aa99c;
      color: white;
      border-top: 0.1rem solid #1aa99c;
      border-bottom: 0.1rem solid #1aa99c;
    }
  }
}
