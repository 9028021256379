@import "../../shared/assets/scss/variables.scss";

#modal {
  .side-popup {
    transform: translateX(100%);
    &.left {
      transform: translateX(-100%);
    }
    &.bottom {
      transform: translateY(100%);
    }
    &.side-popup-animation-enter-done {
      transform: translateX(0%);
      opacity: 1;
      &.bottom {
        transform: translateY(0);
      }
    }

    &.side-popup-animation-exit {
      transform: translateX(100%);
      opacity: 0.1;
      &.left {
        transform: translateX(-100%);
      }
      &.bottom {
        transform: translateY(100%);
      }
    }

    &-content {
      width: 60%;
      height: 100vh;

      .header {
        padding: 16px;
        display: flex;
        margin-bottom: 1rem;
        &-with-patient {
          border-bottom: 1px solid rgba(151, 151, 151, 0.4);
        }
        .heading {
          font-family: "Mon-bold", sans-serif;
          font-size: 18px;
          line-height: 2.9rem;
          color: #333333;
          flex: 1;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 22px;
        }
        .close-icon-container {
          font-size: 0;
          padding: 6px;
        }
        .close-icon {
          cursor: pointer;
        }
        .back-arrow-icon {
          fill: #3d3d3d;
          display: none !important;
        }
      }
    }

    &-new-message {
      width: 38%;
      .header {
        padding: 3.2rem 2.8rem 0 2.8rem;
        display: flex;
        align-items: center;
        .heading {
          font-family: "Mon-semi-bold";
          font-size: 2.4rem;
          line-height: 2.9rem;
          color: #333333;
          flex: 1;
          text-align: center;
        }
        .close-icon {
          cursor: pointer;
        }
      }
    }

    &.mobileView {
      .side-popup-content {
        height: 100%;

        .header {
          padding: 2rem 1.6rem;
          margin-bottom: 2rem;
          .heading {
            font-size: 2.2rem;
            line-height: 2.7rem;
          }
          .close-icon {
            border: 0.1rem solid #cbe5e3;
            padding: 0.8rem;
            border-radius: 1rem;
            path {
              stroke-width: 140;
            }
            &.hidden {
              display: none !important;
            }
          }
          .back-arrow-icon {
            &.show {
              display: inline-block !important;
            }
          }
        }
      }

      .side-popup-new-message {
        .header {
          .heading {
            font-size: 2.2rem;
            line-height: 2.7rem;
          }
        }
      }
    }
  }
}
