@import "shared/assets/scss/variables.scss";

.notification-icon-container-web {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  color: #636d70;      
  .notification-icon {
    cursor: pointer;
  }

  .notification-count-badge {
    min-height: 1.4rem;
    min-width: 1.2rem;
    display: flex;
    font-family: "Mon-bold", sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    color: white;
    background-color: #f83b3b;
    position: absolute;
    left: 2.4rem;
    bottom:1rem;
    z-index: 6;
    border-radius: 40rem;
    padding: 0.3rem 0.8rem ;
    flex-direction: row;
    flex-shrink: 0;
    box-sizing: border-box;
  }
}
