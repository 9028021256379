.assessment-presentation-container {
  .submitted-note {
    color: #1aa99c;
    font-family: "Mon-bold", sans-serif;
    font-size: 1.2rem;
    border-radius: 0.4rem;
    background-color: #dbe9e6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 2rem 0rem 3.6rem 0rem;
  }
  .assessment-question-container {
    &.small-height {
      height: calc(100vh - 32rem);
    }
    &.big-height {
      height: calc(100vh - 23rem);
    }
    overflow: auto;
    .not-selected-error-message {
      margin-top: 1.45rem;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #ff5757;
      display: flex;
      align-items: center;
      column-gap: 0.65rem;
      position: initial !important;
    }
    .task-not-found {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 94%;
      row-gap: 0.7rem;
      margin-top: 2.4rem;
      .no-task-found {
        font-family: "Mon-bold", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        letter-spacing: 0rem;
        color: #333333;
      }
      .error-sub-title {
        font-family: "Mon-light", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        letter-spacing: 0rem;
        color: #333333;
        width: 32rem;
        text-align: center;
        word-break: break-word;
      }
    }
    .assessment-questions {
      display: flex;
      column-gap: 1.5rem;

      .number {
        color: #333;
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 1.6rem;
        line-height: 25.6px;
      }
      .question-section {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        flex: 0.9;
        .nested-pill-questions {
          width: 100%;
          .show-always-questions {
            width: 100%;
            margin-top: 1.4rem;
            .text-area-container {
              flex-direction: column;
            }
            .text-area-container-max-length {
              justify-content: flex-end;
            }
          }
        }
        .checkbox-parent-container {
          .show-always-questions {
            width: 100%;
            margin-top: 1.4rem;
          }
        }
        .question-title {
          color: #333;
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 1.6rem;
          line-height: 25.6px;
        }
        .question-section-checkbox-grid {
          display: flex;
          column-gap: 2.4rem;
          row-gap: 1rem;
          flex-grow: 1;
          flex-flow: wrap;
          width: 100%;
        }
      }
    }
    .conclusion-question {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-bottom: 1.5rem;
      .question-section {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
      }
      .conclusion-line {
        opacity: 0.1;
        background-color: #000;
        height: 0.1rem;
        width: 100%;
        margin: 3.6rem 0 3.6rem 0;
      }
      .question-title {
        color: #333;
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 1.6rem;
        line-height: 25.6px;
      }
      .text-area-container {
        width: -webkit-fill-available;
        .text-area {
          width: inherit;
          min-height: 2rem;
        }
      }
      .text-area-container-max-length {
        justify-content: flex-end;
        margin-top: 1rem;
      }
    }
  }
}
