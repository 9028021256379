@import "../../shared/assets/scss/variables.scss";

.call-participants-container {
  .loading {
    height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .border-line {
    height: 2px;
    background-color: gba(0, 0, 0, 0.1);
    width: 100%;
  }
  .participant-grid {
    display: grid;
    grid-template-columns: 25% 1fr;
    row-gap: 9px;
    column-gap: 16px;
    .patient {
      background-color: #eeeeee;
      display: inline-flex;
      padding: 5px 10px;
      justify-content: center;
      word-break: break-word;
    }
    .item-name {
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      &.primary-navigator-name {
        text-align: end;
      }
    }

    .patient-name {
      align-items: center;
    }
    .item-value {
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      white-space: pre-wrap;
      &.primary-navigator {
        display: flex;
        align-items: center;
      }
    }
    .phone-number-details {
      font-family: "Mon-semi-bold", sans-serif;
    }
    .on-track {
      width: 95px;
      height: 27px;
      background-color: #d6f9db;
      padding: 0px 10px 0px 9px;
      border-radius: 3px;
      color: #008f12;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Mon-semi-bold";
      border-radius: 70px;
    }

    .off-track {
      width: 95px;
      height: 27px;
      background-color: #f1dada;
      padding: 0px 10px 0px 9px;
      border-radius: 3px;
      color: #cb2020;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Mon-semi-bold";
      border-radius: 70px;
    }
  }
}

.mobileView {
  .call-participants-container {
    .participant-grid {
      row-gap: 1.6rem;
    }
  }
}
