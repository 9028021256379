@import "../../shared/assets/scss/variables.scss";

#navigator-setup-container {
  .loading-container {
    height: calc(100vh - 31rem);
  }
  height: 100%;
  .breadcrumb-container {
    margin-bottom: 2.3rem;
  }

  .navigator-setup-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 2.1rem;
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
    .save-changes {
      border-radius: 0.8rem;
      width: 17.3rem;
      height: 4rem;
      font-family: "Mon-medium";
      font-size: 1.6rem;
      line-height: 2rem;
      color: $white;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
      }
    }
    .new-navigator-button {
      width: 19.7rem;
      height: 4rem;
      border-radius: 0.8rem;
      font-family: "Mon-medium";
      font-size: 1.6rem;
      line-height: 2rem;
      color: $white;
    }
  }

  .navigator-setup-content {
    background: #ffffff;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    border-radius: 0.8rem;
    padding: 1.7rem 0 0 0;
    display: flex;
    height: 80%;
    overflow: hidden;

    .fc {
      width: 100%;
      .fc-day-sun,
      .fc-day-sat {
        background-color: #f4f4f4;
      }
      .fc-button {
        padding: 0;
        line-height: 100%;
      }
      .fc-button-primary {
        color: #4f4f4f;
        background-color: transparent;
        border: none;
        &:hover {
          background-color: transparent;
        }
      }
      .fc-header-toolbar {
        margin: 0px 0px 17px 0px;
        .fc-button-group {
          column-gap: 36px;
          margin-left: 3rem;
        }
        .fc-toolbar-chunk {
          display: flex;
          align-items: center;
          .fc-toolbar-title {
            font-family: "Mon-bold", sans-serif;
            font-size: 16px;
            line-height: 20px;
            color: #6b727f;
            margin-left: 16px;
          }

          .fc-headerTitle-button {
            font-family: "Mon-medium", sans-serif;
            font-size: 12px;
            line-height: 15px;
            color: #6b727f;
            pointer-events: none;
            cursor: text;
            margin-right: 24px;
          }
        }
      }
      .fc-scrollgrid {
        border-left: none;
        border-right: none;
        border-bottom: none;
        .fc-col-header {
          background-color: #ffffff;
        }
        thead {
          tr {
            background-color: #ffffff;
            box-shadow: none;
            &.fc-scrollgrid-section-header {
              th {
                &:last-child {
                  border-right-width: 0;
                }
                .fc-day {
                  text-align: center;
                  width: auto !important;
                  border-bottom: none;
                  .fc-col-header-cell-cushion {
                    font-family: "Mon-semi-bold", sans-serif;
                    font-size: 12px;
                    line-height: 15px;
                    color: #6b727f;
                    margin: 1rem auto;
                  }
                }
              }
            }
          }
        }
        thead {
          tr > th > .fc-scroller-harness > .fc-scroller {
            overflow: hidden !important;
            background-color: #f4f4f4;
            border-bottom: 1px solid var(--fc-border-color, #ddd);
          }
        }
        tbody {
          tr:first-child {
            td {
              &:first-child {
                border-style: solid none none solid;
              }
            }
          }
          tr {
            &.fc-scrollgrid-section-body {
              .fc-scroller {
                overflow: hidden;
              }
              td {
                .fc-scrollgrid-sync-table {
                  tbody {
                    tr {
                      td {
                        &.fc-day-today {
                          background: white !important;
                        }
                        &.fc-daygrid-day {
                          .fc-daygrid-day-frame {
                            position: relative;
                            min-height: 100%;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            min-width: 100%;
                            justify-content: space-between;
                            padding: 3.6rem 0rem 1.6rem 0rem;

                            .fc-daygrid-day-top {
                              justify-content: center;
                              .fc-daygrid-day-number {
                                font-family: "Mon-semi-bold", sans-serif;
                                font-size: 16px;
                                line-height: 20px;
                                color: #6b727f;
                                cursor: text;
                              }
                            }
                            .fc-daygrid-day-events {
                              min-width: 80%;
                              .fc-daygrid-event {
                                z-index: 5;
                                &.fc-event:focus {
                                  box-shadow: unset;
                                  &::after {
                                    background: unset;
                                  }
                                  & .dashed {
                                    background-color: rgba(0, 0, 0, 0.25);
                                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                                  }
                                }

                                &:has(.pointer-disabled) {
                                  pointer-events: unset !important;
                                  cursor: not-allowed !important;
                                }
                                &.fc-h-event {
                                  border: none;
                                }
                                .fc-event-main {
                                  color: transparent;
                                }
                                .fc-event-future {
                                  background-color: transparent;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobileView {
  #navigator-setup-container {
    .navigator-setup-header {
      .header-title {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
