@import "shared/assets/scss/variables.scss";

.side-bar {
  z-index: 4;
  background: $white;
  width: 8rem;
  &.mobileView {
    z-index: 3;
  }
  .desktop-side-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    box-shadow: 0.4rem 0 1rem rgba($black, 0.1);
    .side-bar-bottom {
      padding-bottom: 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      align-items: center;
      .nav-item {
        width: 5rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;

        .is-active-nav-item {
          animation: animTab 0.3s ease-in-out forwards;
        }
        a {
          color: #000000;
          border-radius: 0.4rem;
          width: 100%;
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .avatar-container {
        margin-bottom: 0;
      }
      .setting-container,
      .notification-container {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .setting-container {
        border-radius: 0.4rem;
        .icon-button {
          cursor: pointer;
        }
        &.isActive {
          animation: animTab 0.3s ease-in-out forwards;
        }
      }
      .notification-container {
        .notification-drawer {
          bottom: -3.8rem;
          left: 5.9rem;
        }
        .notification-icon-container {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  &-bottom {
    position: relative;
  }

  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .side-bar-header {
      margin: 2.5rem 0 2.1rem 0;
      .logo-image {
        height: 4rem;
        width: 4rem;
      }
    }

    .side-bar-body {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      .nav-item {
        width: 5rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;

        .is-active-nav-item {
          animation: animTab 0.3s ease-in-out forwards;
        }
        a {
          color: #000000;
          border-radius: 0.4rem;
          width: 100%;
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@keyframes animTab {
  0% {
    background-color: white;
  }
  100% {
    background-color: #f1faf9;
  }
}
[data-tooltip]:before {
  /* needed - do not touch */
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;

  /* customizable */
  transition: all 0.15s ease;
  padding: 1rem;
  color: white;
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 0.1rem silver;
  width: 10rem;
  font-family: "Mon-semi-bold", sans-serif;
  z-index: 9999;
}

[data-tooltip]:hover:before {
  /* needed - do not touch */
  opacity: 0.8;

  /* customizable */
  background: #1aa99c;
  margin-top: 0;
  margin-left: 20rem;
  width: 10rem;
  font-family: "Mon-semi-bold", sans-serif;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}

#modal {
  .sidenav-mobile-menu.mobileView {
    background: rgba(0, 0, 0, 0.6);
    .modal-main.side-popup-content {
      width: 100%;
      overflow-y: auto;

      .close-icon {
        border: 0.1rem solid #cbe5e3;
        padding: 1rem;
        border-radius: 1rem;
        path {
          stroke-width: 140;
        }
      }

      .modal-header,
      .modal-body {
        height: auto;
      }
    }
    .side-popup-content {
      position: relative;
      .header {
        padding: 3rem 1.6rem 3.2rem;
        margin-bottom: 0;
      }
      .status-picker {
        position: absolute;
        top: 2.5rem;
        right: 1.6rem;
        .status {
          font-size: 1.6rem;
        }
      }
    }
    .modal-body {
      padding-left: 1.6rem;
    }
  }
}

.mobile-logged-in-as {
  font-family: "Mon-medium", sans-serif;
  margin: 0 2rem 5.3rem 0;
  background: $light-green-color;
  border: 0.1rem solid #a7ddce;
  border-radius: 0.8rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  line-height: 2.6rem;

  .logged-in-as-text {
    color: $grey-text-color;
    margin-right: 0.5rem;

    .first-last-name {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.6rem;
      word-break: break-word;
    }
  }

  .green-button {
    font-family: "Mon-bold", sans-serif;
    font-weight: 700;
    min-width: 8.8rem;
    height: 5.6rem;
  }
}

.mobile-menu-list {
  display: block;
  margin-left: 1.5rem;

  li {
    display: flex;
    justify-content: space-between;
    color: $primary-bg-color;
    padding-bottom: 2.5rem;
    padding-right: 2rem;
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    &:not(:only-child) {
      margin-bottom: 2.4rem;
      border-bottom: 0.1rem solid #a7ddce;
    }
    .mobile-menu-link {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: $primary-bg-color;
    }

    .icon-with-title {
      font-family: "Mon-bold", sans-serif;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.4rem;

      svg {
        color: $black;
        margin-right: 1.1rem;
      }
    }
  }
}

.mobile-sticky-nav {
  background-color: $white;
  box-shadow: 0 -0.8rem 2.4rem rgba($black, 0.05);

  &.hidden {
    display: none;
  }

  .mobile-sticky-menu-list {
    display: flex;
    justify-content: space-around;

    .nav-item {
      width: 100%;
      font-family: "Mon-medium", sans-serif;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.5rem;

      &.hidden {
        display: none;
      }

      a {
        color: #bec3c2;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 1rem 0;
        .nav-item-text {
          text-align: center;
        }
        &.active {
          color: $primary-bg-color;

          svg path {
            fill: $primary-bg-color;
          }
        }
      }
    }

    .nav-item-circle {
      display: block;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 100%;
      background-color: transparent;
      margin: 0.7rem 0 0.4rem;

      &.is-circle-shown {
        background-color: $primary-bg-color;
      }
    }
  }
}
