@import "../../../../shared/assets/scss/variables.scss";

.physician-form-container {
  height: calc(90vh - 140px);
  .form-row {
    display: flex;
    .form-col {
      width: 33%;
      &.tnav-container {
        .tnav-label {
          color: #333;
          display: block;
          font-family: Mon-medium;
          font-size: 1.4rem;
          line-height: 1.7rem;
          margin: 0.6rem 0;
          text-transform: capitalize;
          width: 100%;
        }
      }
      .search-box {
        margin-bottom: 3rem;
        width: 40rem !important;
        border: 0.1rem solid #c1c9d2;
        height: 4rem;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #333333;
      }
      .status-switch {
        margin-top: 2rem;
        margin-left: -1.2rem;
      }
    }

    .status-switch-label {
      color: #333;
      display: block;
      font-family: Mon-medium;
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin: 0.6rem 0;
      text-transform: capitalize;
    }

    .client-input {
      width: 33%;
      .option-box-wrapper {
        width: 100%;
        padding-top: 0;
        height: fit-content;
        max-height: fit-content;
        overflow: hidden;
        .search-box {
          border: none;
          box-shadow: 0px 0px 0px 1px #a6d9ce9c;
          width: 100%;
        }
        ul {
          max-height: 30vh;
          .no-result-found {
            padding: 1rem;
            font-size: 1.4rem;
            font-family: Mon-Medium;
          }
          li {
            word-break: break-word;
          }
        }
      }
    }
    .assoc-phy-input {
      width: 67%;
      margin-bottom: 2.9rem;
      .option-box-wrapper {
        width: 50%;
      }
      .error-wrap {
        color: $red-notification-color;
        font-size: 1.2rem;
        font-family: "Mon-medium";
        margin-top: 0.65rem;
        display: flex;
        align-items: center;

        .error-message-icon {
          margin-right: 0.65rem;
        }
      }
    }
    .field-wrap,
    .navigator-dropdown-container {
      width: 42rem;
    }
    .field-wrap {
      height: 100%;
      flex-direction: column;
      align-items: start;
      background-color: #fafafa;
      width: 70%;
    }
    .navigator-dropdown-container {
      width: 70%;
    }
    .phone-col .field-wrap {
      flex-direction: row;
    }

    .account-type-disabled input {
      opacity: 0.5;
      background-color: #f0f0f0;
    }

    .phone-container {
      width: 100% !important;

      .error-wrap {
        color: $red-notification-color;
        font-size: 1.2rem;
        font-family: "Mon-medium";
        margin-top: 0.65rem;
        display: flex;
        align-items: center;

        .error-message-icon {
          margin-right: 0.65rem;
        }
      }

      .phone-container-content {
        margin-bottom: 0;

        .field-wrap {
          display: flex;
          background: none;
        }
      }
    }
    .created-disabled,
    .updated-disabled {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      opacity: 0.8;
      background-color: #f0f0f0;
    }
  }
}

.toggleContainer {
  display: flex;
  font-family: "Mon-medium", sans-serif;
  font-size: 1.4rem !important;
  line-height: 1.7rem !important;
  color: #333333 !important;
  margin-top: 1.4rem;
  .toggleTitle {
    margin: 0 1.2rem;
  }

  .active {
    color: #1aa99c;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3.6rem;
    height: 1.6rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.1rem solid #cccccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    left: 0.2rem;
    bottom: 0.1rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: grey;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.8rem);
    -ms-transform: translateX(1.8rem);
    transform: translateX(1.8rem);
    background-color: #1aa99c;
  }

  .slider.round {
    border-radius: 3.4rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.icon-container {
  width: 25px;
  height: 25px;
}

.client-input .error-wrap {
  color: $red-notification-color;
  font-size: 1.2rem;
  font-family: "Mon-medium";
  margin-top: 0.65rem;
  display: flex;
  align-items: center;

  .error-message-icon {
    margin-right: 0.65rem;
  }
}
.tag-search-dropdown-container {
  width: 84%;
}

.pcp-container {
  .pcp-title {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.8rem;
    line-height: 2.9rem;
    margin: 35px 0px 10px 0px;
  }
}
