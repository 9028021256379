.readmission-collapsible-class-container {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  .readmission-collapsible-container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .readmission-collapsible-card {
      padding: 1.2rem;
      background: #ffffff;
      border-radius: 0.6rem;
      flex-direction: column;
      display: flex;
      gap: 0.4rem;

      .readmission {
        align-items: center;
        color: #64666a;
        font-size: 1.4rem;
        font-family: "Mon-bold", sans-serif;
      }
      .provider,
      .admission-date,
      .discharge-date {
        color: #667a78;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.2rem;
        line-height: normal;
        span {
          margin-right: 0.4rem;
          font-family: "mon-bold", sans-serif;
        }
      }
      .date-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
