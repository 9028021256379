.activity-logs-container {
  overflow: auto;
  .task-management-no-activity-logs-found.no-data-found-container {
    width: 100%;
    height: 100%;
    .no-data-found {
      width: 24rem;
      .heading {
        margin-bottom: 0;
      }
      img {
        width: 16rem;
        height: 16rem;
        margin-bottom: 2rem;
      }
    }
  }
  &.footer-not-present {
    height: calc(100vh - 22rem);
  }
  &.footer-present {
    height: calc(100vh - 28rem);
  }
  .activity-logs {
    padding: 0 1rem;
    height: 100%;
    .activity-log {
      width: 100%;
      font-family: "Mon-medium", sans-serif;
      padding: 1rem 0;
      font-size: 1.2rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .task-name {
        color: #00887c;
        font-size: 1.4rem;
        font-family: "Mon-semi-bold", sans-serif;
      }
      .discharge-date,
      .admission-date {
        display: flex;
        color: #00000066;
        .admission-date-value,
        .discharge-date-value {
          color: #333;
          margin-left: 0.4rem;
        }
      }
      .task-status-details {
        display: flex;
        flex-wrap: wrap;
        column-gap: 0.8rem;
        color: #00000066;
        width: 101%;
        .task-status-value,
        .done-by-value {
          color: #333;
          margin-left: 0.4rem;
        }
        .task-status {
          &-value {
            text-transform: capitalize;
            &.red-color {
              color: #b91d1d;
            }
          }
          .toc-approve-message {
            width: 100%;
            color: #00000066;
            display: block;
            text-transform: none;
            font-style: italic;
          }
        }
      }
      .date-time {
        color: #00000066;
        margin: 0.5rem 0;
      }
      .activity-tab-right-panel {
        .details {
          font-size: 1.2rem;
          width: 100%;
          display: block;
          &-name,
          &-description {
            display: inline;
            width: unset;
            word-break: break-all;
          }
          &-name {
            color: unset;
            margin-right: 0.4rem;
          }
          &-description {
            width: unset;
          }
        }
      }
    }
  }
}
