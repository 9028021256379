@import "../../shared/assets/scss/variables.scss";

.thumbnail-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 1rem 2rem 1rem 2rem;
  width: 90%;
  max-width: 77%;
  .preview-image {
    width: 5rem;
    height: 5rem;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 1rem;
  }
  .thumbnail-right-section {
    width: 88%;
  }
  .cross-icon {
    background: white;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0.1rem solid grey;
    position: absolute;
    left: 0;
    top: 0.5rem;
    cursor: pointer;
  }
  .name {
    margin-bottom: 0.4rem;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .name,
  .size {
    font-family: "Mon-medium", sans-serif;
    font-size: 1.2rem;
  }
  .size {
    opacity: 0.6;
  }
}

.mobileView {
  .thumbnail-container {
    width: 75%;
    max-width: 75%;
    margin: 1rem 1rem 1rem 2rem;
    .thumbnail-right-section {
      width: 75%;
    }
  }
}
