.patient-status-popup {
  .modal-main {
    border-radius: 0.8rem;
    .modal-body {
      .patient-status-container {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.2);
        border-radius: 0.8rem;
        width: 36.8rem;
        min-width: 36.8rem;
        min-height: 38rem;
        padding: 1.9rem 2.5rem;

        .header {
          text-align: center;
          color: #333;
          font-family: "Mon-bold", sans-serif;
          font-size: 1.6rem;
          line-height: 100%;
          margin-bottom: 2.4rem;
        }

        .content {
          justify-self: flex-start;

          .care-utilization-verified {
            margin-bottom: 1.6rem;
            .input-section .single-radio .container {
              margin-bottom: 0rem;
            }
            .error-wrap {
              margin-top: 0.4rem;
            }
          }
          .episode-dropdown-content-title {
            color: #333;
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 1.2rem;
            margin-bottom: 0.4rem;
          }
          .checkbox-label {
            align-items: flex-start;
            width: fit-content;
          }
          #verified-care-utilization-checkbox {
            margin-bottom: 1.6rem;
          }
          .result-dropdown-container,
          .status-dropdown-container,
          .action-dropdown-container {
            margin-bottom: 1.6rem;
            .no-pointer-events {
              cursor: not-allowed;
              pointer-events: all !important;
              .select-box-down-arrow {
                cursor: not-allowed;
              }
            }
          }
          .text-area {
            font-family: "Mon-medium", sans-serif;
            resize: none;
            overflow: auto;
            &::placeholder {
              font-family: "Mon-medium", sans-serif;
              font-size: 1.2rem;
              color: #c1c1c1;
            }
          }
          .note-length {
            color: #767676;
            text-align: right;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
          }
        }

        .button-section {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 2.4rem 0 0 0;
          .cancel-button {
            background: #fff;
            color: #333;
            border-radius: 0.8rem;
            border: 0.1rem solid #acc0bb;
            margin-right: 1rem;
            cursor: pointer;
          }
          .cancel-button,
          .submit-button {
            width: 10rem;
            height: 3.5rem;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.6rem;
            & .button-loader-img {
              margin-left: 0;
            }
          }
          .submit-button {
            color: #fff;

            &:disabled {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
