.new-message {
  .modal-header {
    height: 12% !important;
    .close-icon-container {
      .close-icon {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }
  .two-liner-heading {
    display: flex;
    flex-direction: column;

    .participants {
      font-family: "Mon-bold", sans-serif;
      font-size: 18px;
      line-height: 2.9rem;
      color: #333333;
      flex: 1;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &-with-patient {
        text-align: start;
      }
    }
    .subject {
      color: #636d70;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
    }
    .patient-details {
      display: flex;
      flex-direction: column;
      color: #636d70;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.2rem;
      align-items: baseline;
      line-height: 2rem;
      label {
        color: #333;
        font-weight: bold;
      }
    }
  }
}
