.assessment-presentation-container {
  .assessment-question-container {
    .no-answer {
      font-family: "Mon-medium", sans-serif;
      margin-bottom: 20px;
    }
    .assessment-dropdown {
      flex-direction: column !important;
      .not-selected-error-message {
        margin-top: 0;
      }
    }
    .assessment-note {
      background: transparent;
      border: none;
      padding: 0rem;
      margin-top: 0rem;
      margin-bottom: 2.4rem;
      .reminder-text {
        font-family: "Mon-bold", sans-serif;
        font-size: 1.2rem;
        line-height: 15px;
        letter-spacing: 0em;
      }
    }

    .pill-checkbox-container {
      background: #ffffff;
      border: 0.1rem solid #bec3c2;
      border-radius: 0.4rem;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #7e7e7e;
      display: inline-flex;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0.5rem 1.7rem 0.5rem 1rem;
      transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
      cursor: pointer;

      &.disabled {
        background: rgba(255, 255, 255, 0.4);
        opacity: 0.4;
        cursor: not-allowed;
        color: #333;
      }

      &.checked {
        opacity: 1;
        background: rgba(198, 221, 217, 0.6);
        border: 0.1rem solid rgba(26, 169, 156, 0.2);
      }
      .pill-checkbox {
        appearance: none;
        height: 1.55rem;

        &:checked {
          opacity: 1;
          background-image: url("../../../../shared/assets/images/white-tick-with-green-background.svg");
          background-repeat: no-repeat;
          height: 1.55rem;
          width: 1.55rem;
          background-origin: border-box;
          background-position: center;
          flex-grow: 0;
          flex-shrink: 0;
          position: relative;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .nested-pill-questions {
      .pill-checkbox-container {
        display: inline-flex;
        cursor: pointer;
        .pill-checkbox {
          .pill-title {
            display: inline-block;
          }
        }
      }
      .checkbox-options-title,
      .pill-option-title {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        color: #333333;
        margin-right: 3.6rem;
        line-height: 100%;
        flex-shrink: 0;
      }
      .checkbox-extra-section {
        display: inline-flex;
        .pill-checkbox-container {
          margin: 0rem 0rem 1.3rem 0;
        }
      }
      .single-select-dropdown-container {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1rem;
      }
      .extra-section-1 {
        position: relative;
        flex-direction: column;
        .checkbox-options-title {
          margin-bottom: 1.2rem;
        }
        &.extra-section-pill {
          display: block;
          .pill-checkbox-container {
            &.disabled {
              cursor: not-allowed;
              color: #333;
            }
          }
        }
        margin-top: 2.5rem;
        display: flex;
        align-items: flex-start;
        .days-selector-container {
          align-items: flex-start;
          flex-direction: column;
          margin-bottom: 2rem;
        }
        .single-select-dropdown-container {
          margin-bottom: 1rem;
        }
        .not-selected-error-message {
          position: absolute;
          bottom: -2rem;
          margin-top: 0;
        }

        .text-area-container {
          width: -webkit-fill-available;
          flex-direction: column;
          textarea {
            width: -webkit-fill-available;
            min-height: 2rem;
          }
        }
        .text-area-container-max-length {
          justify-content: flex-end;
        }

        .checkbox-extra-section {
          display: block;
          .not-selected-error-message {
            position: relative;
            bottom: 1.2rem !important;
          }
          .single-select-dropdown-container {
            margin-bottom: 1rem;
          }
        }

        .pill-checkbox-container {
          margin: 1.3rem 0 1.3rem 0;
        }

        .checkbox-label {
          margin-bottom: 1rem;
        }

        &-checkbox {
          display: flex;
          .checkbox-heading {
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: #333333;
            margin: 0 4rem 0 0;
          }
          .checkbox-label {
            margin-bottom: 1.1rem;
          }
        }
      }
    }

    .checkbox-parent-container {
      .checkbox-label {
        margin-bottom: 1.1rem;
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          width: fit-content;
        }
      }
      .checkbox {
        &.disabled {
          cursor: not-allowed;
        }
      }
    }

    .text-area-container {
      width: -webkit-fill-available;
      flex-direction: column;
      textarea {
        width: -webkit-fill-available;
        min-height: 2rem;
        &::placeholder {
          font-family: "Mon-medium", sans-serif;
          color: #333;
          opacity: 0.4;
          font-size: 1.4rem;
        }
        &:disabled {
          border: 0.1rem solid #c1c9d2;
          background: #f0f0f0;
          color: #333;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          resize: none;
        }
      }
    }
    .text-area-container-max-length {
      justify-content: flex-end;
    }

    .text-area {
      background: #ffffff;
      mix-blend-mode: normal;
      opacity: 0.8;
      border: 0.1rem solid #c1c9d2;
      border-radius: 0.8rem;
      padding: 1rem;
      resize: vertical;
      min-height: 2rem;
      font-family: "Mon-medium", sans-serif;
    }

    .single-select-dropdown-container {
      display: flex;
      align-items: center;
      min-width: 30rem;
      max-width: 34rem;
      margin-bottom: 1rem;
      .dropdown-title {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 100%;
        color: #333333;
        margin-right: 3.6rem;
        flex-shrink: 0;
      }
    }

    .text-area-container {
      display: flex;
      column-gap: 1.6rem;
      .text-area-title {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin-bottom: 1rem;
        color: #333;
      }
    }

    .days-selector-container {
      display: flex;
      align-items: center;
      .react-datepicker__day-names {
        display: flex;
        justify-content: space-around;
      }
      .days-selector-label {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        color: #333333;
        margin-left: 0.8rem;
        line-height: 100%;
      }
      .days-selector {
        width: 4.5rem;
        height: 4rem;
        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.8;
        border: 0.1rem solid #c1c9d2;
        border-radius: 0.8rem;
        padding: 0.2rem 2rem;
      }
    }

    .age-selector-container {
      display: flex;
      align-items: center;
      .age-selector-label {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        color: #333333;
        margin-left: 0.8rem;
        line-height: 100%;
      }
      .age-selector {
        width: 4.5rem;
        height: 4rem;
        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.8;
        border: 0.1rem solid #c1c9d2;
        border-radius: 0.8rem;
        padding: 0.2rem 2rem;
        cursor: not-allowed;
      }
    }

    .text-selector-container {
      width: 36.5rem;
      .text-title {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        color: #333333;
        margin-bottom: 1rem;
        line-height: 100%;
      }
      .text-selector {
        width: inherit;
        height: 4rem;
        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.8;
        border: 0.1rem solid #c1c9d2;
        border-radius: 0.8rem;
        padding: 0.2rem 1rem;
        margin-bottom: 1rem;
        box-sizing: border-box;

        &:disabled {
          border: 0.1rem solid #c1c9d2;
          background: #f0f0f0;
          color: #333;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          line-height: 22px;
          cursor: not-allowed;
        }

        &::placeholder {
          font-family: "Mon-medium", sans-serif;
          color: #333;
          opacity: 0.6;
          font-size: 1.4rem;
        }
      }
      .text-container-max-length {
        display: flex;
        column-gap: 1.6rem;
        justify-content: flex-end;

        .text-length {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: #767676;
          margin-bottom: 1rem;
        }
      }
    }

    .text-area-main-container {
      display: grid !important;
      .not-selected-error-message {
        margin-top: 0 !important;
      }
    }

    .text-area-container-max-length {
      display: flex;
      column-gap: 1.6rem;
      margin-left: 7rem;
      .text-area-length {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #767676;
        margin-bottom: 1rem;
      }
    }

    .section-question-heading {
      font-family: "Mon-bold", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 3.2rem;
      color: #00887c;
      margin-bottom: 1rem;
    }

    .checkbox-parent-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.4rem;
    }

    .number-input-container {
      input[type="number"] {
        display: flex;
        width: 10.4rem;
        height: 4rem;
        align-items: center;
        border-radius: 0.8rem;
        border: 0.1rem solid #c1c9d2;
        opacity: 0.8;
        background: #fff;
        padding: 0 1rem 0 1rem;
        &:disabled {
          border: 0.1rem solid #c1c9d2;
          background: #f0f0f0;
          color: #333;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
        }
      }
      .not-selected-error-message {
        margin-top: 1.45rem !important;
      }
      .number-title {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #333333;
        margin-bottom: 1rem;
      }
    }
    .disabled-date-selector {
      .react-datepicker__input-container {
        .date-picker-input {
          opacity: 1 !important;
          border: 0.1rem solid #c1c9d2 !important;
          background: #f0f0f0 !important;
          color: #333;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.2rem;
          line-height: 22px;
        }
      }
      .react-datepicker__close-icon {
        display: none;
      }
    }
  }
}
