#post-task-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .message-container {
    position: relative;
    display: inline-flex;
    width: 320px;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 8px 8px 0px 0px;
    background: #fff;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.05);
    .checkmark-container {
      width: 83px;
      height: 83px;
    }
    .message-text {
      color: #333;
      text-align: center;
      font-family: "Mon-bold", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22.4px; /* 140% */
    }
    .loader-container {
      width: 400px;
      position: absolute;
      left: 0;
      bottom: 0;
      progress {
        border-radius: 0px;
        width: 400px;
        height: 4px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
      }
      progress::-webkit-progress-bar {
        background-color: #ececec;
        border-radius: 0px;
      }
      progress::-webkit-progress-value {
        background-color: #1aa99c;
        border-radius: 0px;
      }
      progress::-moz-progress-bar {
        /* style rules */
      }
    }
  }
}
