.row-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .transcription-icon-audio {
    background: white;
    padding: 0.95rem 0.8rem;
    border-radius: 50%;
    margin-right: 1.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
  .transcription-icon {
    background: white;
    padding: 0.95rem 0.8rem;
    border-radius: 50%;
    margin-right: 1.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 1rem;
      width: 1rem;
    }
  }
  .call-icon {
    background: white;
    padding: 0.8rem 0.8rem;
    border-radius: 50%;
    margin-right: 1.4rem;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
    }
    svg {
      width: 1.3rem;
      height: 1.7rem;
      color: black;
    }
  }
}
