.footer-button-container {
  position: fixed;
  height: 7rem;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  background: #ffffff;
  box-shadow: 0 -0.7rem 1.2rem rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
  .back-button {
    width: 13.5rem;
    height: 4rem;
    background: #ffffff;
    border: 0.1rem solid #acc0bb;
    border-radius: 0.8rem;
    cursor: pointer;
    font-family: "Mon-semi-bold", sans-serif;
  }
  .questions-footer-button-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    .footer-buttons-div-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      margin: 0 1rem;
    }
    .clear-button {
      height: 4rem;
      cursor: pointer;
      font-family: "Mon-medium", sans-serif;
      position: absolute;
      right: 0;
      margin-right: 6rem;
      font-size: 1.6rem;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .unattempted-questions {
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      line-height: 2rem;
      color: #ff5757;
      position: absolute;
      left: 11rem;
    }
    .delay-button {
      width: 10.5rem;
      height: 4rem;
      background: #ffffff;
      border: 0.1rem solid #acc0bb;
      border-radius: 0.8rem;
      order: 0;
      cursor: pointer;
      font-family: "Mon-semi-bold", sans-serif;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .save-close-button {
      width: 13.5rem;
      height: 4rem;
      background: #ffffff;
      border: 0.1rem solid #acc0bb;
      border-radius: 0.8rem;
      order: -1;
      cursor: pointer;
      font-family: "Mon-semi-bold", sans-serif;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .next-button {
      width: 12.179rem;
      height: 4rem;
      background: #1aa99c;
      border-radius: 0.8rem;
      border: none;
      color: white;
      font-family: "Mon-semi-bold", sans-serif;
      cursor: pointer;
      &:disabled {
        background: #a3ddd7;
        cursor: not-allowed;
      }
    }
    .manager-approval-button {
      border-radius: 0.8rem;
      border: 0.1rem solid #acc0bb;
      background: #fff;
      padding: 1rem 1.6rem;
      outline: none;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .previous-button {
      width: 12.179rem;
      height: 4rem;
      font-family: "Mon-semi-bold", sans-serif;
      background: #1aa99c;
      border-radius: 0.8rem;
      border: none;
      color: white;
      cursor: pointer;
      &:disabled {
        background: #a3ddd7;
        cursor: not-allowed;
      }
    }
  }
}
