@import "shared/assets/scss/variables.scss";

.navigator-episodes-container-table {
  tr:nth-child(1) {
    td:nth-child(1) {
      table {
        margin-top: 0;
        tr {
          font-weight: bold;
          color: $grey-text-color;
          background: rgba(241, 250, 249, 0.6);
          border-radius: 0.8rem 0.8rem 0 0;
          box-shadow: 0 0 0 0.1rem $primary-border-color;
          th {
            font-size: 1.4rem;
            padding: 1.7rem 0 1.8rem 0;
            text-align: left;
            font-family: "Mon-bold", sans-serif;
          }
          th:nth-child(1) {
            padding-left: 2.4rem;
            width: 18%;
          }
          th:nth-child(2) {
            width: 15%;
            padding-right: 4rem;
          }
          th:nth-child(3) {
            width: 26%;
            padding-right: 4rem;
          }
          th:last-child {
            width: 30%;
          }
        }
      }
    }
  }
  .navigator-episodes-table-container {
    height: calc(100vh - 15rem);
    overflow-y: auto;
    .navigator-episodes-table {
      margin-top: -0.1rem;
      .navigator-episodes-table-body {
        tr {
          &.night-border {
            border: 0.2rem solid $night-border;
          }
          td {
            font-size: 1.4rem;
            line-height: 1.6rem;
            padding: 1.7rem 0 1.8rem 0;
            text-align: left;
            border: 0.1rem solid $grey-border-color;
            border-style: solid none solid none;
            font-family: "Mon-medium", sans-serif;
            &.night-border {
              border-bottom: 0.2rem solid $night-border;
            }
            a {
              color: $primary-text-color;
              cursor: pointer;
            }
          }
          td:nth-child(1) {
            border-style: solid none solid solid;
            padding-right: 0;
            width: 18%;
            padding-left: 2.4rem;
          }
          td:nth-child(2) {
            width: 15%;
            padding-right: 4rem;
          }
          td:nth-child(3) {
            width: 26%;
            padding-right: 4rem;
          }
          td:nth-child(4) {
            width: 30%;
          }
        }
        tr:last-child {
          border-radius: 0 0 0.8rem 0.8rem;
          box-shadow: 0 0 0 0.1rem $grey-border-color;
          border-style: unset;
        }
        .hovered {
          background: #eeeeee;
        }
      }
    }
  }
}
