@import "../../shared/assets/scss/variables.scss";

.phone-floating-modal {
  position: absolute !important;
  right: inherit !important;
  bottom: inherit !important;
  background: #ffffff !important;

  &-content {
    background: #ffffff;
    border-radius: 0.8rem;
    padding: 3.4rem 4.1rem 4.6rem 4.1rem;
    height: 31.5rem;
    width: 22.5rem;
    cursor: move;
    border: 0.2rem solid rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8.15485rem);
    display: flex;
    flex-direction: column;

    .modal-body {
      flex: 1;
    }

    .modal-header {
      margin-bottom: 4.5rem;
      font-family: "Mon-semi-bold";
      font-size: 2.2rem;
      line-height: 2.7rem;
      text-align: center;
      color: #333333;
      .close-icon {
        display: none !important;
      }
    }

    &-container {
      height: 100%;
      display: flex;
      flex-direction: column !important;
      position: relative;
      .call-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--text {
          align-items: center;
          width: 100%;
          text-align: center;
          margin: 0 auto;
          font-family: "Mon-medium", sans-serif;
          line-height: 1.5rem;
          font-size: 3rem;
          color: #85878d;
        }
      }

      .particiapants-title {
        align-items: center;
        width: 100%;
        top: 0;
        text-align: center;
        font-family: "Mon-medium", sans-serif;
        line-height: 2.8rem;
        font-size: 1.8rem;
        color: #85878d;
      }

      .timer {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: center;
        letter-spacing: 0.3rem;
        color: #85878d;
        margin-bottom: 2.95rem;
      }

      .dialer-input-textbox-container {
        margin-bottom: 3.65rem;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .dialer-input {
          font-family: "Mon-bold", sans-serif;
          font-size: 2rem;
          margin: auto;
          color: #85878d;
          height: 4.1rem;
          max-width: 15rem;
          overflow: hidden;
          line-height: 4.1rem;
        }
        .delete-icon {
          position: absolute;
          right: 0;

          &.disabled {
            opacity: 0.2;
          }
        }
      }

      .dialer-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        column-gap: 4rem;
        row-gap: 1.5rem;
        margin-bottom: 2.4rem;
        .number {
          width: 5.5rem;
          height: 5.5rem;
          border: 0.1rem solid #85878d;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin: auto;
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 2rem;
          color: #85878d;
          background-color: white;
          cursor: pointer;
          &:hover {
            background-color: #1aa99c;
            transition: background-color 0.3s ease-in-out;
            color: white;
            border: 0.1rem solid #1aa99c;
          }
        }
      }
      .resize {
        // border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .minimize {
        cursor: pointer;
      }

      .buttons {
        display: flex;
        flex: 1;
        width: 100%;
        bottom: 0;

        .video-call-container {
          opacity: 0;
        }
        .maximize {
          padding: 1.2rem;
          border: 1px solid gray;
          border-radius: 100%;
        }
        .cut-call,
        .video-call,
        .mute {
          cursor: pointer;
        }
        .swap-position-icon-container {
          width: 4.8rem;
          height: 4.8rem;
          border: 0.1rem solid #85878d;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .swap-position-icon {
            cursor: pointer;
          }
        }
        .buttons-desktop {
          display: flex;
          flex: 1;
          align-items: center;
          justify-self: flex-end;
          justify-content: space-between;
          width: 100%;
        }
        .buttons-mobile {
          display: flex;
          flex: 1;
          align-items: end;
          justify-content: space-between;
          margin: 2rem 4rem 4rem;
          .video-call,
          .mute {
            margin-bottom: 0.8rem;
          }
        }
      }
    }
  }
}
.calling-container {
  .Toastify__toast-container {
    height: 65rem;
    width: 30.9rem;
    z-index: 10 !important;
    .Toastify__toast {
      height: 100%;

      .Toastify__close-button {
        display: none !important;
      }

      .Toastify__toast-body {
        margin: 1rem 2rem;

        .Toastify__toast-icon {
          display: none !important;
        }

        > div {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    transition: all 0.15s ease;
    padding: 1rem;
    color: white;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.1rem silver;
    font-size: 1.2rem;
    text-align: center;
  }

  [data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: #1aa99c;
    margin-top: -4rem;
    margin-left: -3rem;
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
  }
}

.mobileView {
  .phone-floating-modal {
    &-content {
      &-container {
        .call-title {
          font-size: 2.2rem;
          line-height: 2.7rem;
          color: $grey-text-color;
          margin-top: 4rem;
        }

        .particiapants-title {
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 2.2rem;
          line-height: 2.7rem;
          color: $grey-text-color;
        }

        .timer {
          font-family: "Mon-light", sans-serif;
          font-size: 5.4rem;
          line-height: 6.6rem;
          color: $grey-text-color;
          margin-top: 2.4rem;
          @media (max-width: $smMobile) {
            margin-top: 1rem;
            margin-bottom: 1rem;
          }
        }

        .dialer-input-textbox-container {
          margin-bottom: 2rem;
          margin-right: 4rem;
          width: auto;
          @media (max-width: $smMobile) {
            margin-right: 2rem;
          }
        }

        .buttons {
          .buttons-mobile {
            @media (max-width: $smMobile) {
              margin: 1.5rem 2rem 4rem;
            }
          }
        }
      }
    }
  }
  &.calling-container {
    .Toastify__toast-container {
      height: 100%;
      width: 100%;
      max-width: 100%;
      padding: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10 !important;

      .Toastify__toast {
        margin: 0;
        padding: 0;
        box-shadow: none;
        max-height: 100%;
        .Toastify__toast-body {
          margin: 0;
          padding: 0;
          overflow-y: auto;
        }
      }
    }

    [data-tooltip]:last-of-type:before {
      right: 1.5rem;
    }
  }
}
