@import "shared/assets/scss/variables.scss";

#header {
  margin-bottom: 2.1rem;
  margin-left: 1rem;
}

.mobile-menu-trigger {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar-container {
    margin-bottom: 0;
    width: 4rem;
    height: 4rem;
    box-sizing: border-box;
  }

  &.hidden {
    display: none;
  }
}
