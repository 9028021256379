@import "../../shared/assets/scss/variables.scss";

.chat-footer {
  width: 100%;
  background: #ffffff;
  border: 0.1rem solid #e6e6e6;
  box-shadow: 0 0 0.6rem rgba(193, 199, 204, 0.3);
  border-radius: 0.2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input[type="file"] {
    display: none;
  }
  .chat-text-container {
    position: relative;
    flex: 6;
    background: #dbe7e5;
    border-radius: 0.4rem;
    .attachment {
      position: absolute;
      right: 1rem;
      top: 1.8rem;
      opacity: 0.5;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
      }
    }
    .chat-text-box {
      background: #dbe7e5;
      border: 0.1rem solid #e5ede7;
      border-radius: 0.4rem;
      height: 4.2rem;
      width: 89.5%;
      outline: none;
      padding: 0.8rem 0 0 0.8rem;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.4rem;
      max-height: 6rem;
      overflow-x: hidden;
      overflow-y: auto;
      resize: none;
      cursor: auto;
      &::placeholder {
        color: rgba($grey-text-color, 0.4);
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
  .send {
    flex: 1;
    display: flex;
    justify-content: center;

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.mobileView {
  .chat-footer {
    padding: 0.9rem 1.5rem 0.9rem 2rem;
    box-sizing: border-box;
    .chat-text-container {
      .attachment {
        top: 1.2rem;
      }
      .chat-text-box {
        font-size: 1.6rem;
        padding: 1.2rem 1rem 1.2rem 1.8rem;
        box-sizing: border-box;
        border: none;
        &::-webkit-scrollbar-thumb {
          background: transparent;
        }

        &::placeholder {
          font-size: 1.4rem;
        }
      }
    }
    .send {
      flex: 0;
      margin-left: 1.5rem;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
