#check-on-patient-container {
  height: 100%;
  width: 100%;
  & > .task-not-found {
    height: calc(100vh - 24rem);
  }
  .check-on-patient-component {
    height: calc(100vh - 23rem);
    overflow-x: hidden;
    width: 100%;
    overflow-y: auto;
    padding-right: 0.5rem;
    &.no-answer-delay-note {
      height: calc(100vh - 32rem);
    }
    .check-on-patient-form-field {
      width: 100%;
      font-family: "Mon-medium", sans-serif;
      margin-bottom: 1rem;
      .field-label {
        font-family: "Mon-bold", sans-serif;
        font-size: 1.4rem;
        line-height: 17.07px;
        margin-bottom: 0.5rem;
        display: block;
        &.label-multiple-fields {
          margin-bottom: 1.5rem;
        }
        span.red-color {
          color: red;
          font-family: "Mon-medium", sans-serif;
        }
        &.field-sub-label {
          font-family: "Mon-medium", sans-serif;
        }
      }
    }
    .error-and-text-length-container {
      -webkit-column-gap: 1.6rem;
      column-gap: 1.6rem;
      display: flex;
      height: 1.7rem;
      justify-content: space-between;
      margin-top: 1rem;
      .not-selected-error-message {
        align-items: center;
        color: #ff5757;
        -webkit-column-gap: 0.65rem;
        column-gap: 0.65rem;
        display: flex;
        font-family: Mon-medium, sans-serif;
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
      .text-length {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #767676;
      }
    }
    .check-on-patient-form-row {
      display: flex;
      gap: 1rem;

      .checkbox-label.disabled {
        cursor: not-allowed !important;
        input:not(:checked) {
          color: #333333;
          background: #f0f0f0;
        }
      }

      &.discharge-service-checkbox{
        margin-bottom: 1.5rem;
      }

      &.form-row-direction {
        flex-direction: column;
        margin: 2rem 0 0 2.5rem;
      }
      &.days-visit {
        gap: 2rem;
      }
      .check-on-patient-form-column {
        .check-on-patient-text-field {
          margin-bottom: 0;
          &.disabled {
            opacity: 1;
            & .field-wrap {
              background: #f0f0f0;
              .input-field {
                background: #f0f0f0;
                opacity: 1;
              }
            }
          }
        }

        :not(.checkbox-label) {
          .disabled,
          input:disabled {
            color: #333333;
            opacity: 1;
            background: #f0f0f0;
            cursor: not-allowed;
          }
        }
        &.service-type,
        &.provider,
        &.reason {
          width: 39rem;
        }

        &.check-on-patient-dates {
          font-family: "Mon-medium", sans-serif;
          width: 18rem;
          .calender-icon {
            display: block !important;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            &:hover {
              cursor: pointer;
            }
          }
          .date-picker-mask-input.date-picker-input {
            width: 18rem;
            box-sizing: border-box;
            font-family: "Mon-medium", sans-serif;
          }
        }

        &.send-method-type {
          width: 10rem;
        }
        &.send-method-value {
          width: 27.8rem;
        }
        &.preferred-send-date {
          width: 100%;
        }
        &.provider {
          .facility-dropdown-container {
            width: 39rem;
          }
        }
        &.discharge-service-checkbox {
          display: flex;
          margin-left: 1.5rem;
        }

        .no-pointer-events {
          opacity: 1;
          cursor: not-allowed;
          pointer-events: unset;
          background: #f0f0f0;
        }

        .text-area {
          background: #ffffff;
          mix-blend-mode: normal;
          opacity: 1;
          border: 0.1rem solid #c1c9d2cc;
          border-radius: 0.8rem;
          padding: 1rem;
          resize: vertical;
          min-height: 4rem;
          font-family: "Mon-medium", sans-serif;
          width: 100%;
          box-sizing: border-box;
          &::placeholder {
            color: #00000066;
          }
          &:disabled {
            border: 0.1rem solid #c1c9d2;
            background: #f0f0f0;
            color: #333333;
            font-size: 1.4rem;
            resize: none;

            cursor: not-allowed;
          }
        }
        &.details,
        &.notes {
          width: 100%;
        }

        &.planned,
        &.actual,
        &.completed {
          .check-on-patient-text-field {
            width: 10.8rem;
          }
        }

        .text-input-field-container {
          margin-bottom: 0;
          &.los {
            .field-wrap {
              width: 9.6rem;
            }
          }
          .field-title {
            line-height: 0;
            margin: 0;
          }
          .field-wrap {
            height: 4rem;
            width: 100%;
            .input-field {
              border: none;
            }
          }
        }
      }
    }
  }
}
