.footer-button-container {
  position: fixed;
  height: 10vh;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  background: #ffffff;
  box-shadow: 0 -.7rem 1.2rem rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
  .footer-button-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    input {
      margin: 0 1rem;
    }
    .close-button {
      width: 12.2rem;
      height: 4rem;
      background: #ffffff;
      border: .1rem solid #acc0bb;
      border-radius: .8rem;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      cursor: pointer;
    }
    .next-button {
      width: 19.2rem;
      height: 4rem;
      background: #1aa99c;
      border-radius: .8rem;
      border: none;
      color: white;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      cursor: pointer;
      &:disabled {
        background: #a3ddd7;
      }
    }
    .show-task-button {
      width: 24rem;
      height: 4rem;
      background: #ffffff;
      border-radius: .8rem;
      border: .1rem solid #acc0bb;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #333333;
      cursor: pointer;
      &:disabled {
        background: #a3ddd7;
      }
    }
  }
}
