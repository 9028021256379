@import "../../shared/assets/scss/variables.scss";

#navigator-detail-container {
  height: 100%;
  .table-container {
    height: 62%;
    padding-top: 0.1rem;
    position: relative;
    margin-top: 1rem;
    box-sizing: border-box;
    & .navigator-manager-patient-call-table,
    .NavigatorMessageTable {
      .infinite-scroll-component__outerdiv {
        height: calc(100vh - 35rem) !important;
        .navigator-dashboard-table-body {
          .loading-container {
            height: calc(100vh - 38rem) !important;
          }
        }
        .navigator-message-table-body {
          .loading-container {
            height: calc(100vh - 38rem) !important;
          }
          & > tr > td:nth-child(2) {
            padding: 1.5rem 1.5rem 1.5rem 1.8rem;
          }
        }
      }
    }
  }
  & .navigator-manager-patient-call-table {
    .inbound-navigator-dashboard-table-header {
      position: static;
      &.scrollbar-is-visible {
        th:last-child {
          width: 16.4em;
        }
      }
      th {
        box-sizing: border-box;
        font-size: 1.2rem;
      }
      th:nth-child(1) {
        width: 5em;
      }
      th:nth-child(2) {
        width: 15em;
      }
      th:nth-child(3) {
        text-align: left;
        width: 13em;
      }
      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(6),
      th:nth-child(7),
      th:nth-child(8) {
        width: 10em;
        padding-left: 1.5rem;
      }
      th:last-child {
        width: 15em;
      }
    }
    .dashboard-no-border-in-mobile .inbound-navigator-dashboard-table-body {
      tr {
        position: relative;
      }
      td:nth-child(9) {
        width: 0em;
        padding: 0;
        padding-right: 1rem;
      }
      td:nth-child(1) {
        width: 5em;
      }
      td {
        box-sizing: border-box;
        line-height: 2.2rem;
      }
      td:nth-child(2) {
        width: 15em;
        white-space: pre-wrap;
        cursor: pointer;
        .participants-section {
          flex-direction: column;
          align-items: start;
          .participant-type-info-container {
            display: flex;
            align-items: center;
            margin-top: 0.8rem;
            margin-left: 0;
            .participant-type {
              margin-left: 0;
            }
          }
        }
      }
      td:nth-child(3) {
        width: 13em;
      }
      td:nth-child(5) {
        width: 10em;
      }
      td:nth-child(6),
      td:nth-child(7),
      td:nth-child(8) {
        width: 10em !important;
      }
      td:nth-child(9) {
        width: 15em;
      }
      td.rows-icon-visible {
        width: 0em;
        padding: 0rem;
        .action-col .row-icon-container .row-icons > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .navigator-detail-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .header-title {
      font-family: "Mon-bold", sans-serif;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: $grey-header-color;
    }
  }
  .statistic-box-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 3rem;
  }
  .all-filters-section {
    margin-top: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.4rem;
    .right-section {
      display: flex;
      align-items: center;
      .search-icon-container {
        margin: 0 1.6rem 0 3.2rem;
      }
      .search-icon-container,
      .filter-icon-container {
        width: 4rem;
        height: 4rem;
        background: #ffffff;
        border: 0.1rem solid rgba(51, 51, 51, 0.14);
        border-radius: 0.8rem;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .make-a-call-button {
        height: 4rem;
        width: 14.6rem;
        font-family: "Mon-medium", sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-left: 1.6rem;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        &.enabled {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }
}

#modal {
  .chat-content {
    position: relative;
    height: 94%;
    .chat-messages {
      display: flex;
      flex-direction: column;
      height: 90%;
      justify-content: flex-end;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .chat-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #ffffff;
      border: 0.1rem solid #e6e6e6;
      box-shadow: 0 0 0.6rem rgba(193, 199, 204, 0.3);
      border-radius: 0.2rem;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .chat-text-container {
        position: relative;
        width: 92%;
        .attachment {
          position: absolute;
          right: 1rem;
          top: 1.2rem;
          opacity: 0.5;
        }
        .chat-text-box {
          background: #dbe7e5;
          border: 0.1rem solid #e5ede7;
          border-radius: 0.4rem;
          height: 4.2rem;
          width: 100%;
          outline: none;
          padding-left: 1rem;
        }
      }
      .send {
        width: 8%;
        align-self: center;
        margin-right: 1.9rem;
      }
    }
    &.mobileView {
      .chat-footer {
        padding: 1rem 1.5rem 1rem 2rem;
        box-sizing: border-box;
      }
    }
  }
}

#modal {
  .filter-side-popup {
    .filter-content-container {
      // width: 20%;
      .modal-header {
        margin-bottom: 4.6rem;
      }
      .modal-body {
        padding: 1.6rem;

        .filter-content {
          margin-top: 4rem;
          height: calc(100% - 4rem);
          .status {
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: $grey-sub-header-color;
            margin-bottom: 2.1rem;
          }
          .filter-buttons {
            display: flex;
            flex-direction: column;
            margin-bottom: 2.4rem;

            .apply-button {
              margin-bottom: 1.6rem;
            }

            .apply-button,
            .clear-button {
              width: 100%;
              height: 4rem;
              font-family: "Mon-medium", sans-serif;
              font-size: 1.6rem;
              line-height: 2rem;
            }
          }
          .checkbox-container {
            height: 60%;
          }
          .checkboxes {
            margin-bottom: 1.4rem;
          }
        }
        .filter-content-mobile {
          height: calc(100% - 12rem);
        }
      }
    }
    &.mobileView {
      background: rgba(0, 0, 0, 0.6);
    }
  }
}

.mobileView {
  #navigator-detail-container {
    .navigator-detail-header {
      .header-title {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
