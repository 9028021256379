@import "shared/assets/scss/variables.scss";
.phone-numbers-container {
  position: absolute;
  width: 340px;
  background-color: white;
  border-radius: 1.2rem;
  border: 1px solid #0000001a;
  padding: 0rem 0rem;
  max-height: 32rem;
  overflow-y: auto;
  right: 0px;
  z-index: 3;

  &.up {
    top: -85px;
  }

  &.down {
    top: 35px;
  }

  .phone-number-details,
  .no-results {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1.6rem;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
      background: #f1faf9;
      cursor: pointer;
    }

    &:last-child {
      border-bottom: none;
    }
    .name {
      word-break: break-all;
      .name-text {
        color: #333;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
      }
    }
    .phone-number {
      margin-top: 6px;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      color: #333333;
      opacity: 0.6;
      white-space: pre-wrap;
    }
    .label-right {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      align-items: end;
    }
    .invalid-number-label {
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      color: #f76659;
    }
    .label {
      color: #333333;
      opacity: 0.4;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
    }
  }

  .no-results {
    height: 37.5px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    &:hover {
      background-color: #fff;
      cursor: default;
    }
  }
}

#modal {
  .number-select-modal {
    .modal-header {
      margin-bottom: 2.4rem;
      text-align: center;
      header {
        font-size: 1.6rem;
      }
    }
    .modal-main {
      border-radius: 0.8rem;
      padding: 1.6rem;
      min-width: 70%;
    }
    .phone-numbers-container {
      position: unset;
      width: unset;
      background-color: unset;
      border-radius: unset;
      max-height: unset;
      overflow-y: unset;
      right: unset;
      z-index: unset;
      .phone-number-details {
        border: 0.1rem solid #acc0bb;
        border-radius: 0.8rem;
        text-align: center;
        display: block;
        padding: 1.4rem;
        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }
        .name {
          color: #333333;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.6rem;
          line-height: 2rem;
          width: unset;
        }
        .phone-number {
          font-size: unset;
          opacity: unset;
          color: unset;
          margin-top: unset;
        }
        .label {
          font-size: 1.2rem;
          line-height: 1.5rem;
          color: rgba($grey-text-color, 0.6);
          font-family: "Mon-medium", sans-serif;
          margin-top: 0.2rem;
          opacity: unset;
        }
        .name-text {
          display: none;
        }
        &.highlighted {
          background-color: $primary-bg-color;
          .name {
            color: $white;
          }
          .label {
            color: rgba($white, 0.8);
          }
        }
      }
    }
  }
}
