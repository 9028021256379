@import "../../shared/assets/scss/variables.scss";

.tag-search-dropdown-container {
  position: relative;
  width: 100%;
  .no-error {
    border: 0.1rem solid $primary-bg-color;
  }
  .error {
    border: 0.1rem solid $red-notification-color;
  }
  .error-color {
    color: $red-notification-color;
    margin-top: 0.6rem;
    font-size: 1.2rem;
    font-family: "Mon-medium";
  }
  .select-span {
    height: auto;
    border: 0.1rem solid #c1c9d2;
    border-radius: 0.8rem;
    box-sizing: border-box;
    padding: 0.675rem;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name-container {
      display: flex;
      width: inherit;
      overflow: unset;
      flex-wrap: wrap;
      max-height: 164px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .selected-value {
      font-family: "Mon-medium";
      color: $grey-text-color;
      font-size: 1.4rem;
      line-height: 1.7rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .placeholder {
      color: #999999;
    }

    .select-box-down-arrow-rotate {
      transform: rotate(180deg);
      transition: transform 0.3s linear;
    }
    .select-box-down-arrow-unrotate {
      transform: rotate(0);
      transition: transform 0.3s linear;
    }
    .icon-container {
      background: $grey-bg-color;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .select-box-down-arrow {
        cursor: pointer;
        padding: 0.7rem;
      }
    }
  }
  .red-border {
    border: 0.1rem solid #ff5757;
  }
  .error-wrap {
    color: #ff5757;
    font-family: "Mon-medium";
    font-size: 1.4rem;
    margin: 0.6rem 0;
  }
  .options-box {
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .option {
        font-family: "Mon-medium", sans-serif;
        line-height: 100%;
        &.option-right {
          color: #636d70;
          font-size: 1.2rem;
          width: 100%;
          text-align: right;
          word-break: break-word;
        }

        &.option-left {
          color: #111;
          font-size: 1.4rem;
          width: 100%;
          word-break: break-word;
          .bold-text {
            font-weight: bold;
            color: #1aa99c;
          }
        }
      }
      &.disabled {
        cursor: not-allowed;
        .option {
          color: #636d70;
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
          line-height: 100%;
        }
        p {
          span {
            color: #636d70;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4rem;
            line-height: 100%;
          }
        }
      }
    }
  }

  .option-box-wrapper {
    left: 0;
    border: 0.1rem solid #c1c9d2;
    border-radius: 0.8rem;
    position: absolute;
    top: 100%;
    padding-top: 0rem;
    width: 29.8rem;
    background-color: white;
    z-index: 3;
    animation: openDrop 0.2s forwards;
    max-height: 24rem;
    overflow-y: scroll;

    @keyframes openDrop {
      from {
        opacity: 0.2;
      }
      to {
        opacity: 1;
      }
    }
    ul {
      overflow: auto;

      li {
        cursor: pointer;
        padding: 1.6rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        &:hover {
          background-color: #f1faf9;
        }

        .selected {
          color: $primary-bg-color;
        }
        .option {
          font-family: "Mon-medium", sans-serif;
          font-size: 1.4rem;
        }
      }
      .no-result-found {
        font-family: "Mon-medium", sans-serif;
        font-size: 1.4rem;
        padding: 1rem;
      }
    }
  }
}

.selected-option-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  margin: 0.4rem;

  height: 22px;
  background: #f1faf9;

  border-radius: 48px;
  border: 1px solid rgba(148, 212, 180, 1);

  .text {
    font-family: "Mon-medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
    margin-right: 0.8rem;
  }
  .icon-content {
    display: flex;
    cursor: pointer;
  }
}

.search-box-wrapper {
  display: flex;
  align-items: center;
  height: 4rem;
  min-width: 15rem;
  .search-box {
    border: none;
    margin-right: 0;
    width: 0;
    min-width: 15rem;
  }
}

.mobileView {
  .tag-search-dropdown-container {
    .option-box-wrapper {
      width: 100%;
    }
  }
  .search-box-wrapper {
    width: auto;
    .search-box {
      min-width: 24rem;
    }
  }
}
